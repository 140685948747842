<div class="position-relative overflow-hidden pt-3 pl-md-3 pr-md-3 text-center" *ngIf="property">
    <mdb-card cascade="true" wider="true" reverse="true" style="z-index:0">
        <div class="view view-cascade overlay waves-light" mdbWavesEffect>
            <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'" *ngIf="imagesI">
                <mdb-carousel-item *ngFor="let item of imagesI">
                    <div class="view w-100">
                        <img style="height:400px; z-index:0; background-repeat: no-repeat; background-position: center center" class="d-block w-100" src="../../../../assets/uploads/property/galleries/{{item.img}}" alt="{{item.description}}">
                        <div class="mask rgba-blue-grey-slight waves-light" mdbWavesEffect></div>
                    </div>
                    <div class="carousel-caption" *ngIf="property">
                        <div class="col-md-12 p-lg-5 mx-auto my-5 text-white">
                            <h1 class="display-5 font-weight-normal" style="font-size:5em" *ngIf="property">{{property.name}}</h1>
                        </div>
                    </div>
                </mdb-carousel-item>
            </mdb-carousel>
            <a><div class="mask rgba-white-slight"></div></a>
        </div>

        <mdb-card-body cascade="true" class="text-center" *ngIf="property" style="z-index:0">
            <h2><a class="font-weight-bold">{{property.name}}, {{property.market}}</a></h2>
            <span class="colorP ">{{'propertyDetail.year' | translate}} <span class="font-weight-bold">{{property.year}}</span></span>
        </mdb-card-body>
    </mdb-card>

    <div class="excerpt mt-4"></div>
</div>

<div class="d-md-flex flex-md-equal w-100 pl-md-3" *ngIf="property">
    <div class="bg-dark mr-md-4 pt-4 px-5 pt-md-4 px-md-5 text-center text-white overflow-hidden" style="width:100%">
        <p *ngIf="lngI == 'es'" style="text-align:justify; font-size:18px">
            {{description}}<br>
        </p>
        <p *ngIf="lngI == 'en'" style="text-align:justify; font-size:18px">
            {{description_eng}}<br>
        </p>
    </div>

    <div class="bg-light mr-md-3 pt-4 px-5 pt-md-4 px-md-5 text-center text-white overflow-hidden" style="width:100%">
        <div *ngIf="images_1">
            <mdb-image-modal [modalImages]="images_1"></mdb-image-modal>
        </div>
    </div>
</div>

<div class="d-md-flex flex-md-equal pt-4 w-100">
    <div class="col-12">
        <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3'" [contentClass]="'card'">
            <mdb-tab heading="{{'propertyDetail.features' | translate}}" class="z-index text-white">
                <div class="row px-md-4 pt-md-4 z-index">
                    <div class="col-lg-12" *ngIf="property">
                        <div class="row">
                            <div class="col-md-4 col-sm-6">
                                <p>
                                    <span class="colorP"><mdb-icon fas icon="home" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.property' | translate}}</span><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{property.name}}</span>
                                </p>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <p>
                                    <span class="colorP"><mdb-icon fas icon="user-alt" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.lessee' | translate}}</span><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{property.lessee}}</span>
                                </p>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <p>
                                    <span class="colorP"><mdb-icon fas icon="map-marker-alt" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.address' | translate}}</span><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{property.address}}</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 col-sm-6">
                                <p>
                                    <span class="colorP"><mdb-icon fas icon="chart-area" class="animated pulse infinite"></mdb-icon>&nbsp;M<sup>2</sup>&nbsp; {{'propertyDetail.landArea' | translate}}</span><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{property.m2}} m<sup>2</sup></span>
                                </p>
                            </div>

                            <div class="col-md-8 col-sm-6">
                                <p *ngIf="lngI == 'es'">
                                    <span class="colorP"><mdb-icon fas icon="calendar-alt" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.averageTerm' | translate}}</span><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{averege_term}}</span>
                                </p>
                                <p *ngIf="lngI == 'en'">
                                    <span class="colorP"><mdb-icon fas icon="calendar-alt" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.averageTerm' | translate}}</span><br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{averege_term_eng}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-tab>
        </mdb-tabset>
    </div>
</div>

<div class="row">
    <div class="row pt-4 pl-md-3 pr-md-3 pt-1">
        <div class="col-md-12" *ngIf="images_2">
            <mdb-image-modal id="galleryS" [modalImages]="images_2"></mdb-image-modal>
        </div>
    </div>
</div>

<div class="fixed-action-btn smooth-scroll" style="width:50px">
    <a mdbBtn [routerLink]="['/investments', idp]" class="btn-detail waves-effect waves-light rgba-grey-strong z-depth-2" mdbWavesEffect>
        <mdb-icon fas icon="angle-left white-text" size="3x" class="animated pulse infinite"></mdb-icon>
    </a>
</div>
