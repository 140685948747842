import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { InvestmentInterface } from '@app/models/investment.interface';
import { PropertyInterface } from '@app/models/property.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class PropertyUpdateComponent implements OnInit {

    property: FormGroup;
    selectedProperty: PropertyInterface;
    optionsSelectInvestments: InvestmentInterface;
    name_file: string;

    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    url_base: string;

    constructor(private route: Router, private auth: AuthService, private activatedRoute: ActivatedRoute, private messageService: MessageService, private translate: TranslateService, private dataApiService: DataApiService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.getSelectInvestment(1);
        this.selectedProperty = this.utilityService.selectedProperty;
        this.property = this.utilityService.propertyUpdate;

        this.getProperty(this.activatedRoute.snapshot.params['id']);

        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = this.humanizeBytes;
        this.url_base = environment.urlApiRestExpress;
    }

    get formValue() {
        return this.property.controls;
    }

    getProperty(id: number) {
        this.dataApiService.getProperty(id).subscribe(
            data => {
                this.property.controls['id'].setValue(data[0]['id']);
                this.property.controls['investment_id'].setValue(data[0]['investment_id']);
                this.property.controls['name'].setValue(data[0]['name']);
                this.property.controls['description'].setValue(data[0]['description']);
                this.property.controls['description_eng'].setValue(data[0]['description_eng']);
                this.property.controls['address'].setValue(data[0]['address']);
                this.property.controls['lessee'].setValue(data[0]['lessee']);
                this.property.controls['m2'].setValue(data[0]['m2']);
                this.property.controls['averege_term'].setValue(data[0]['averege_term']);
                this.property.controls['averege_term_eng'].setValue(data[0]['averege_term_eng']);
                this.property.controls['market'].setValue(data[0]['market']);
                this.property.controls['year'].setValue(data[0]['year']);
                this.property.controls['latitude'].setValue(data[0]['latitude']);
                this.property.controls['longitude'].setValue(data[0]['longitude']);
                this.property.controls['sold'].setValue(data[0]['sold']);
                this.name_file = data[0]['name_file'];
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    onUpdate(form: FormGroup) {

        if (form.valid) {
            if (this.formValue.name_file.value) {
                this.startUpload();
                this.name_file = this.formValue.name.value + '.jpg';
                this.name_file = this.name_file.replace(/ /gi, '_');
            }

            this.selectedProperty = {
                id: this.formValue.id.value,
                investment_id: this.formValue.investment_id.value,
                name: this.formValue.name.value,
                description: this.formValue.description.value,
                description_eng: this.formValue.description_eng.value,
                address: this.formValue.address.value,
                lessee: this.formValue.lessee.value,
                m2: this.formValue.m2.value,
                averege_term: this.formValue.averege_term.value,
                averege_term_eng: this.formValue.averege_term_eng.value,
                market: this.formValue.market.value,
                year: this.formValue.year.value,
                latitude: this.formValue.latitude.value,
                longitude: this.formValue.longitude.value,
                sold: this.formValue.sold.value,
                name_file: this.name_file,
                status: 1
            };

            return this.dataApiService.updateProperty(this.selectedProperty).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                                this.route.navigate(['/admin/property/list']);
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(id, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments });
    }

    startUpload(): void {
        let url = environment.urlUploadPropertyImagenPrincipal;
        url = url.replace(/\//gi, '_');

        let name_file = this.formValue.name.value + '.jpg';
        name_file = name_file.replace(/ /gi, '_');

        const event: UploadInput = {
            type: 'uploadAll',
            url: `${this.url_base}/uploadPropertyImagePrincipal/${url}/${name_file}`,
            method: 'POST',
            data: { sampleFile: 'bar' },
        };
        this.files = [];
        this.uploadInput.emit(event);
    }

    showFiles() {
        let files = '';
        for (let i = 0; i < this.files.length; i ++) {
            files += this.files[i].name;
            if (!(this.files.length - 1 === i))
                files += ',';
        }

        files = files.replace(/ /gi, '_');
        this.property.controls['name_file'].setValue(files);

        return files;
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    onUploadOutput(output: UploadOutput | any): void {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
        }
        else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
          this.dragOver = false;
        }

        this.showFiles();
    }
}
