import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { InvestmentInterface } from 'src/app/models/investment.interface';
import { EntityInterface } from './../../../../models/entity.interface';

import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../../../message/message.service';
import { DataApiService } from '../../../../services/data-api.service';
import { UtilityService } from '../../../../services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class EntityUpdateComponent implements OnInit {

    entity: FormGroup;
    selectedEntity: EntityInterface;
    optionsSelectInvestments: InvestmentInterface;

    title = "Actualizar entidad";
    action = "Actualizar";
    back  = "Regresar";

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.selectedEntity = this.utilityService.selectedEntity;
        this.entity = this.utilityService.entityUpdate;

        this.getEntity(this.activatedRoute.snapshot.params['id']);
    }

    get formValue() {
        return this.entity.controls;
    }

    getEntity(id: number) {
        this.dataApiService.getEntity(id).subscribe(
            data => {
                this.entity.controls['id'].setValue(data[0]['id']);
                this.entity.controls['investment_id'].setValue(data[0]['investment_id']);
                this.entity.controls['name'].setValue(data[0]['name']);

                this.getSelectInvestment(0);
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    onUpdate(form: FormGroup) {
        if (form.valid) {
            this.selectedEntity = {
                id: this.formValue.id.value,
                investment_id: this.formValue.investment_id.value,
                name: this.formValue.name.value,
                status: 1
            };

            return this.dataApiService.updateEntity(this.selectedEntity).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                            this.route.navigate(['/admin/entity/list']);
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(id, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments });
    }
}
