<div class="headerContainer">
    <h2 class="headerTitle">{{'navbar.about' | translate}}</h2>
</div>

<section id="about">
    <div class="container">
        <div class="row mt-5 mb-2" data-aos="flip-left" data-aos-duration="800">
            <div class="col-lg-6 col-md-12 mb-2 mr-auto">
                <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
                    <mdb-carousel-item>
                        <div class="view w-100">
                            <img class="d-block w-100" src="../.././../assets/images/about_1.jpg" alt="First slide">
                            <div class="mask rgba-pink-slight waves-light" mdbWavesEffect></div>
                        </div>
                        <div class="carousel-caption">
                            <h3 class="h3-responsive mdb-color white-text" style="font-size:16px">{{'about.imagen_1' | translate}}</h3>
                        </div>
                    </mdb-carousel-item>
                    <mdb-carousel-item>
                        <div class="view w-100">
                            <img class="d-block w-100" src="../.././../assets/images/about_2.jpg" alt="Second slide">
                            <div class="mask rgba-teal-light waves-light" mdbWavesEffect></div>
                        </div>
                        <div class="carousel-caption">
                            <h3 class="h3-responsive mdb-color white-text" style="font-size:16px">{{'about.imagen_2' | translate}}</h3>
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>

            <div class="col-lg-6 col-md-12 mb-4 wow fadeIn" data-wow-delay=".2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
                <h3 class="mb-5 colorA title font-weight-bold wow fadeIn text-center" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
                    <strong>{{'about.title' | translate}}</strong>
                </h3>
                <p align="justify" class="colorA flipInX" data-wow-delay="0.3s">
                    {{'about.description_1' | translate}}<br><br>
                    {{'about.description_2' | translate}}
                </p>
            </div>
        </div>
    </div>
</section>

<section id="culture">
    <div class="container">
        <section id="about" class="about mt-1 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
        <div class="row pt-2 mt-5">
            <div class="col-lg-6 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.4s;">
                <h3 class="mb-5 colorA title font-weight-bold wow fadeIn text-center" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
                    <strong>{{'all.culture.title' | translate}}</strong>
                </h3>

                <p align="justify" class="colorA" data-aos="zoom-in" data-aos-duration="1000">
                    <b>{{'all.culture_1.title' | translate}}</b><br>
                    {{'all.culture_1' | translate}}
                </p>

                <p align="justify" class="colorA" data-aos="zoom-in" data-aos-duration="1000">
                    <b>{{'all.culture_2.title' | translate}}</b><br>
                    {{'all.culture_2' | translate}}
                </p>

                <p align="justify" class="colorA" data-aos="zoom-in" data-aos-duration="1000">
                    <b>{{'all.culture_3.title' | translate}}</b><br>
                    {{'all.culture_3' | translate}}
                </p>
            </div>

            <div class="col-lg-6 col-md-12 mb-3 wow fadeIn p-4" data-wow-delay="0.4s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.4s;">
                <div class="view overlay zoom" data-aos="flip-down" data-aos-duration="1000">
                    <img src="../../../assets/images/culture.jpg" class="img-fluid z-depth-1 rounded img-thumbnail" alt="Cultura" mdbWavesEffect>
                    <a>
                        <div class="mask waves-effect rgba-white-slight" mdbWavesEffect></div>
                    </a>
                </div>
            </div>
        </div>
        </section>
    </div>
</section>
