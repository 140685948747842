// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const dir_client = '../assets/uploads/'; //Assets route
//const dir_server = 'C:/xampp/htdocs/aztecfunds/client/src/assets/uploads/'; //Assets route absolute
const dir_client = '/assets/uploads/'; //Assets route
const dir_server = '/var/www/html/assets/uploads/'; //Assets route absolute

export const environment = {
    //production: false,
    production: true,
    //urlApiRest: 'http://localhost:3000/api', //Api rest route
    //urlApiRestExpress: 'http://localhost:3000', //Api rest route
    urlApiRest: 'https://clientes.aztecfunds.com:3000/api', //Api rest route
    urlApiRestExpress: 'https://clientes.aztecfunds.com:3000', //Api rest route
    urlInvestmentDocuments: dir_client + 'investment/documents',
    urlPropertyDocuments: dir_client + 'property/documents',
    urlPropertyGalleries: dir_client + 'property/galleries',
    urlPropertyImagenPrincipal: dir_client + 'property/principal',
    urlAccountStatus: dir_client + 'accountStatus/documents',
    urlNew: dir_client + 'new/principal',
    urlUploadInvestmentDocuments: dir_server + 'investment/documents',
    urlUploadPropertyDocuments: dir_server + 'property/documents',
    urlUploadPropertyGalleries: dir_server + 'property/galleries',
    urlUploadPropertyImagenPrincipal: dir_server + 'property/principal',
    urlUploadAccountStatus: dir_server + 'accountStatus/documents',
    urlUploadNew: dir_server + 'new/principal',
    from: 'katrielunet@gmail.com',
    activeLang: 'es', //Language default
    page: 20, //Number page default
    investment_id: '0' //Number typeInvestment default
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
