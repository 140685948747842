<section id="login"  class="spaceComponent">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 mx-auto">
                <div class="class_login">
                    <mdb-card>
                        <mdb-card-header class="colorBPP white-text text-center py-4">
                            <h5>
                                <strong>{{'login.title' | translate}}</strong>
                            </h5>
                        </mdb-card-header>

                        <mdb-card-body class="px-lg-5 pt-0">
                            <form autocomplete="off" [formGroup]="loginUser" (ngSubmit)="onLogin(loginUser)" class="text-center">
                                <div class="md-form">
                                    <input id="loginFormEmail"  formControlName="email" type="email" class="form-control" mdbInput placeholder="" required autofocus autocomplete="off">
                                    <label for="loginFormEmail">{{'login.email' | translate}}</label>
                                    <div *ngIf="formValue.email.invalid && (formValue.email.dirty || formValue.email.touched)"
                                        class="error">
                                        <div *ngIf="formValue.email.errors.required">
                                            {{'login.email' | translate}} {{'all.required_fields' | translate}}
                                        </div>
                                        <div
                                            *ngIf="formValue.email.invalid && formValue.email.errors.pattern && (formValue.email.dirty || formValue.email.touched)">
                                            {{'login.email' | translate}} {{'all.not_valid' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <div class="md-form">
                                    <input id="loginFormPassword"  formControlName="password" type="password" class="form-control" mdbInput placeholder="" required autofocus autocomplete="off">
                                    <label for="loginFormPassword">{{'login.password' | translate}}</label>
                                    <div *ngIf="formValue.password.invalid && (formValue.password.dirty || formValue.password.touched)"
                                        class="error">
                                        <div *ngIf="formValue.password.errors.required">
                                            {{'login.password' | translate}} {{'all.required_fields' | translate}}
                                        </div>
                                        <div
                                            *ngIf="formValue.password.invalid && formValue.password.errors.pattern && (formValue.password.dirty || formValue.password.touched)">
                                            {{'login.password' | translate}} {{'all.not_validc' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <button [disabled]="!loginUser.valid" mdbBtn outline="true" rounded="true" block="true" class="my-4 waves-effect z-depth-0 text-white colorBPP" mdbWavesEffect
                                type="submit">{{'login.button' | translate}}</button>

                                <br><br>

                                <!--
                                <p class="cs_c2">Síguenos:</p>
                                <a type="button" mdbBtn floating="true" color="fb" size="sm"><mdb-icon fab icon="facebook"></mdb-icon></a>
                                <a type="button" mdbBtn floating="true" color="tw" size="sm"><mdb-icon fab icon="twitter"></mdb-icon></a>
                                <a type="button" mdbBtn floating="true" color="li" size="sm"><mdb-icon fab icon="linkedin"></mdb-icon></a>
                                <a type="button" mdbBtn floating="true" color="git" size="sm"><mdb-icon fab icon="github"></mdb-icon></a>
                                -->
                            </form>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </div>
    </div>
</section>