import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { PropertyInterface } from '@app/models/property.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { InvestmentInterface } from '@app/models/investment.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class PropertyListComponent implements OnInit {

    properties: PropertyInterface;
    optionsSelectInvestments: InvestmentInterface;
    search: FormGroup;
    page: number = environment.page;
    investment_id: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
    elements: any = [];
    previous: any = [];
    headElements = ['id', 'name', 'investment_id'];
    tableData: any = [];
    sorted = false;

    title = "Propiedades";
    action = "Crear Propiedad";
    back  = "Inicio";

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.search = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['name'].setValue('');
        this.search.controls['investment_id'].setValue('');

        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getProperties().subscribe(properties => {
            this.setData(properties); 
            this.getSelectInvestment(1);
            this.spinnerService.hide();
        });
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(id, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments });
    }

    onDelete(id: number): void {
        this.dataApiService.getProperty(id).subscribe(
            data => {
                this.properties = {
                    id: data[0]['id'],
                    investment_id: data[0]['investment_id'],
                    name: data[0]['name'],
                    description: data[0]['description'],
                    description_eng: data[0]['description_eng'],
                    address: data[0]['address'],
                    lessee: data[0]['lessee'],
                    m2: data[0]['m2'],
                    averege_term: data[0]['averege_term'],
                    averege_term_eng: data[0]['averege_term_eng'],
                    market: data[0]['market'],
                    year: data[0]['year'],
                    name_file: data[0]['name_file'],
                    latitude: data[0]['latitude'],
                    longitude: data[0]['longitude'],
                    sold: data[0]['sold'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteProperty(this.properties).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_id = this.formValue.investment_id.value;
        this.search.controls['investment_id'].setValue(this.investment_id);
        
        this.dataApiService.getPropertiesExpressFilter(event.target.id, event.target.value, this.investment_id).subscribe(properties => { this.setData(properties) });
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlPropertyImagenPrincipal}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlPropertyImagenPrincipal}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    setData(properties: any) {
        this.tableData = properties;

        this.mdbTable.setDataSource(properties);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
