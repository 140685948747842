<section class="spaceContainerAdmin">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="form-header colorBPP">
                            <h3>
                            <i class="fas fa-briefcase"></i> {{'investment.title' | translate}}</h3>
                        </div>

                        <form autocomplete="off" [formGroup]="investment" (ngSubmit)="onCreate(investment)" class="text-center">
                            <p>* {{'all.required_fields' | translate}}</p>

                            <div class="row">
                                <div class="col-12">
                                    <div class="md-form">
                                        <input id="name"  formControlName="name" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                        <label for="name">* {{'investment.name' | translate}}</label>
                                        <div *ngIf="formValue.name.invalid && (formValue.name.dirty || formValue.name.touched)" class="error">
                                            <div *ngIf="formValue.name.errors.required">
                                                {{'investment.name' | translate}} {{'all.required_fields' | translate}}
                                            </div>
                                            <div
                                                *ngIf="formValue.name.invalid && formValue.name.errors.pattern && (formValue.name.dirty || formValue.name.touched)">
                                                {{'investment.name' | translate}} {{'all.not_valid' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="md-form">
                                        <input id="description"  formControlName="description" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                        <label for="description">* {{'investment.description' | translate}}</label>
                                        <div *ngIf="formValue.description.invalid && (formValue.description.dirty || formValue.description.touched)" class="error">
                                            <div *ngIf="formValue.description.errors.required">
                                                {{'investment.description' | translate}} {{'all.required_fields' | translate}}
                                            </div>
                                            <div
                                                *ngIf="formValue.description.invalid && formValue.description.errors.pattern && (formValue.description.dirty || formValue.description.touched)">
                                                {{'investment.description' | translate}} {{'all.not_valid' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button [routerLink]="['/admin/investment/list']" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                            <button [disabled]="!investment.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.create' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
