<div #modalLogin class="modal fade" id="modalLogin" tabindex="-1" role="dialog"
    aria-labelledby="modalLoginLabel" aria-hidden="true">
    <div class="modal-dialog modal-frame modal-bottom" role="document">
        <div class="modal-content py-2">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <p *ngIf="!_isError" class="success-text pt-3 pr-2">
                            {{_isMessage}}
                        </p>
                        <p *ngIf="_isError" class="red-text pt-3 pr-2">
                            <b>ERROR:</b> {{_isMessage}}
                        </p>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-lg btn-block'" [ngClass]="getClass()" data-dismiss="modal"
                            aria-label="Close"> Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>