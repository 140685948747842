import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent implements OnInit {

    map = { latitude: 19.3879901, longitude: -99.2533758, zoom: 2 };
    properties: any = [];
    description: string;
    id: number;

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, public dataApiService: DataApiService, private translate: TranslateService) { }

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());

        this.activatedRoute.params.subscribe(data => {
            this.id = this.activatedRoute.snapshot.params['id'];
            this.getProperties(this.id);
        });
    }

    getProperties(id: number) {
        this.properties = [];
        this.dataApiService.getPropertiesFilterInvestment(id).subscribe(properties => {
            this.properties = properties; 
            this.map = { latitude: 19.3879901, longitude: -99.2533758, zoom: 2 };
            this.translate.setDefaultLang(this.auth.getLanguage());
        });
    }

    selectMarker(event) {
        this.map = { latitude: Number(event.latitude), longitude: Number(event.longitude), zoom: 13 };
    }
}
