import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { EntityInterface } from '@app/models/entity.interface';
import { InvestmentInterface } from 'src/app/models/investment.interface';
import { RoleInterface } from './../../../../models/role.interface';
import { EntityUserProfileInterface } from '@app/models/entityUserProfile.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class EntityUserUpdateComponent implements OnInit {

    entityUser: FormGroup;
    entityUsers: EntityUserProfileInterface;
    optionsSelectRoles: RoleInterface;
    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    id: string;
    ide: string;
    investment_id: number;
    role_id: number;
    entity: Boolean = false;

    title = "Actualizar entity";
    action = "Actualizar";
    back  = "Regresar";

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.id = this.activatedRoute.snapshot.params['id'];
        this.ide = this.activatedRoute.snapshot.params['ide'];
        
        this.getEntityUser(this.ide);

        this.entityUsers = this.utilityService.selectedEntityUser;
        this.entityUser = this.utilityService.entityUser;
        this.entityUser.reset();

        this.entityUser.controls['id'].setValue(this.ide);
        this.entityUser.controls['user_id'].setValue(this.id);
    }

    get formValue() {
        return this.entityUser.controls;
    }

    getEntityUser(id: string) {
        this.dataApiService.getEntityUserProfile(id).subscribe(
            data => {
                this.entityUser.controls['entity_id'].setValue(data['entity_id']);
                this.getUser(this.id);
                this.getEntity(data['entity_id']);
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    getUser(id: string) {
        this.dataApiService.getUser(id).subscribe(
            user => {
                this.role_id = user[0]['role_id'];
            }
        );
    }

    onUpdate(form: FormGroup) {
        if (form.valid) {
            this.entityUsers = {
                id: Number(this.formValue.id.value),
                user_id: this.formValue.user_id.value,
                entity_id: this.formValue.entity_id.value,
                status: 1
            };

            return this.dataApiService.updateEntityUserProfiles(this.entityUsers).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                            this.route.navigate(['/admin/entityUser/list/' + this.id]);
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    getEntity(id: number) {
        this.dataApiService.getEntity(id).subscribe(investments => {
            this.investment_id = investments[0]['investment_id'];
            this.entityUser.controls['investment_id'].setValue(this.investment_id);
            this.getSelectInvestment(this.investment_id);
        });
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelectInvestment(id, 0).subscribe(investments => {
            this.optionsSelectInvestments = investments; 
            this.viewEntity();
        });
    }

    getSelectEntity(id: string) {
        this.dataApiService.getEntitiesExpressSelect(id).subscribe(entities => { this.optionsSelectEntities = entities });
    }

    viewEntity() {
        if(this.role_id == 2) {
            this.entity = true;
            this.getSelectEntity(this.formValue.investment_id.value);
        }
        else {
            this.entity = false;
            this.getSelectEntity('0');

            this.entityUser.controls['entity_id'].setValue('0');
        }
    }
}
