import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { AccountStatusInterface } from '@app/models/accountStatus.interface';
import { UserInterface } from '@app/models/user.interface';
import { InvestmentInterface } from '@app/models/investment.interface';
import { EntityInterface } from '@app/models/entity.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AccountStatusListComponent implements OnInit {

    accountStatus: AccountStatusInterface;
    optionsSelectUsers: UserInterface;
    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    search: FormGroup;
    page: number = environment.page;
    investment_id: string;
    entity_id: string = '';
    url_base: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
    elements: any = [];
    previous: any = [];
    headElements = ['id', 'user_id', 'name', 'investment_id', 'entity_id'];
    tableData: any = [];
    sorted = false;

    constructor(private _httpClient: HttpClient, private route: Router, public _location: Location, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.search = this.utilityService.search;
        this.url_base = environment.urlAccountStatus;
        this.investment_id = environment.investment_id;
        
        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.investment_id = environment.investment_id;

        this.search.controls['id'].setValue('');
        this.search.controls['user_id'].setValue('');
        this.search.controls['name'].setValue('');
        this.search.controls['investment_id'].setValue(this.investment_id);
        this.search.controls['entity_id'].setValue(this.entity_id);

        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getAccountStatusExpress('0', this.investment_id).subscribe(accountStatus => { 
            this.setData(accountStatus); 
            this.getAllUsers();
            this.getSelectInvestment();
            this.getSelectEntity();
            this.spinnerService.hide(); 
        });
    }

    getAllUsers() {
        this.dataApiService.getUsersExpressSelect().subscribe(users => { this.optionsSelectUsers = users; });
    }

    getSelectInvestment() {
        this.dataApiService.getInvestmentaExpressSelect(0, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments; });
    }

    getSelectEntity() {
        this.dataApiService.getEntitiesExpressSelect('').subscribe(entities => { this.optionsSelectEntities = entities });
    }

    onDelete(id: number): void {
        this.dataApiService.getAccountStatus(id).subscribe(
            data => {
                this.accountStatus = {
                    id: data['id'],
                    user_id: data['user_id'],
                    investment_id: data['investment_id'],
                    entity_id: data['entity_id'],
                    name: data['name'],
                    name_file: data['name_file'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteAccountStatus(this.accountStatus).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlAccountStatus}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlAccountStatus}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_id = this.formValue.investment_id.value;
        this.search.controls['investment_id'].setValue(this.investment_id);

        this.entity_id = this.formValue.entity_id.value;
        this.search.controls['entity_id'].setValue(this.entity_id);

        this.dataApiService.getAccountStatusExpressFilter('', this.investment_id, this.entity_id, event.target.id, event.target.value).subscribe(accountStatus => {
            this.setData(accountStatus);
            if(event.target.id == 'investment_id' || event.target.id == 'entity_id') {
                this.search.controls['id'].setValue('');
                this.search.controls['user_id'].setValue('');
                this.search.controls['name'].setValue('');
            }
        });
    }

    setData(accountStatus: any) {
        this.tableData = accountStatus;
        this.mdbTable.setDataSource(accountStatus);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
