import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { InvestmentDocumentInterface } from '@app/models/investmentDocument.interface';
import { InvestmentTypeInterface } from '@app/models/investmentType.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class InvestmentDocumentListComponent implements OnInit {

    investmentDocument: InvestmentDocumentInterface;
    optionsSelectInvestmentTypes: InvestmentTypeInterface;
    search: FormGroup;
    page: number = environment.page;
    investment_type_id: string;
    id: number;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
    elements: any = [];
    previous: any = [];
    headElements = ['id', 'name', 'investment_type_id', 'order'];
    tableData: any = [];
    sorted = false;

    constructor(private route: Router, public _location: Location, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.activatedRoute.params.subscribe(data => {
            this.translate.setDefaultLang(this.auth.getLanguage());
            this.id = this.activatedRoute.snapshot.params['id'];
            this.search = this.utilityService.search;
            
            this.cleanFilter();
        });
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['name'].setValue('');
        this.search.controls['investment_type_id'].setValue('');
        this.search.controls['order'].setValue('');

        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getInvestmentDocumentExpress(this.id).subscribe(investmentDocument => {
            this.setData(investmentDocument);
            this.getSelectInvestmentType();
            this.spinnerService.hide();
        });
    }

    getSelectInvestmentType() {
        this.dataApiService.getInvestmentTypesSelectExpress().subscribe(investmentTypes => { this.optionsSelectInvestmentTypes = investmentTypes });
    }

    onDelete(id: number): void {
        this.dataApiService.getInvestmentDocument(id).subscribe(
            data => {
                this.investmentDocument = {
                    id: data['id'],
                    investment_id: data['investment_id'],
                    investment_type_id: data['investment_type_id'],
                    name: data['name'],
                    name_file: data['name_file'],
                    order: data['order'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteInvestmentDocument(this.investmentDocument).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlInvestmentDocuments}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlInvestmentDocuments}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_type_id = this.formValue.investment_type_id.value;
        this.search.controls['investment_type_id'].setValue(this.investment_type_id);

         this.dataApiService.getInvestmentDocumentsExpressFilter(event.target.id, event.target.value, this.id, this.investment_type_id).subscribe(investmentDocument => { this.setData(investmentDocument) });
    }

    setData(investmentDocument: any) {
        this.tableData = investmentDocument;

        this.mdbTable.setDataSource(investmentDocument);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
