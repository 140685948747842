import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private route: Router) { }

    canActivate() {
        if (this.authService.getCurrentUser())
            return true;
        else {
            this.route.navigate(['/user/login']);
            return false;
        }
    }
}
