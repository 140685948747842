<section id="homePage">
    <div class="view intro jarallax imageHome" style="height:700px">
        <div class="full-bg-img mask rgba-white-light">
            <div class="container">
                <div class="d-flex align-items-center d-flex justify-content-center" style="height:700px">
                    <div class="row mt-5">
                        <div class="col-md-12 wow fadeIn mb-3">
                            <div class="intro-info-content text-center">
                                <h2 class="h1 display-1 mb-2 wow fadeInDown" data-wow-delay="0.3s"><span class="colorPP">Aztec Funds</span></h2>
                                <!--<h3 class="font-up mb-3 mt-1 font-bold wow colorPP fadeIn" data-wow-delay="0.4s"><strong>{{'all.slogan' | translate}}</strong></h3>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="text">
    <div class="streak streak-photo streak-md" style="background-image: url(../../../../../assets/images/background_about.jpg); background-position: center center">
        <div class="flex-center mask rgba-blue-light">
            <div class="text-center white-text wow fadeIn" data-wow-delay="0.5s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
                <h2 class="h2-responsive mb-5 textImage">
                    {{'all.text' | translate}}
                </h2>
            </div>
        </div>
    </div>
</section>
