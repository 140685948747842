<mdb-navbar id="{{navbar_color}}" SideClass="navbar navbar-expand-lg navbar-dark ie-nav fixed-top scrolling-navbar" [containerInside]="false">
    <mdb-navbar-brand class="brand">
        <a [routerLink]="['/home']">
            <div id="{{idLogo}}" class="logo"></div>
        </a>
    </mdb-navbar-brand>

    <links>
        <ul class="navbar-nav mr-auto" *ngIf="!isLogged">
            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/home']" routerLinkActive="router-link-active">{{'navbar.home' | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/about']" routerLinkActive="router-link-active">{{'navbar.about' | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/news']" routerLinkActive="router-link-active">{{'navbar.news' | translate}}</a>
            </li>


            <li class="nav-item dropdown btn-group" dropdown>
                <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" routerLinkActive="router-link-active" (click)="onChangeLink('/investments')" [ngClass]="{'router-link-active':classActive === '/investments' && active === false}" mdbWavesEffect>
                    {{'home.investment.title' | translate}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" role="menu">
                    <a *ngFor="let investment of investments" class="dropdown-item" [routerLink]="['/investments', investment.value]">{{investment.label}}</a>
                </div>
            </li>
        </ul>

        <ul class="navbar-nav mr-auto" *ngIf="isLogged && role_id == '1'">
            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/admin/home']" routerLinkActive="router-link-active">{{'navbar.home' | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="['/admin/entity/list']" routerLinkActive="router-link-active">{{'navbar.entities' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityCU && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkEntity]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkEntity}">{{entityT | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userP && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && !entityUFI && newAct">
                <a class="nav-link navColor" [routerLink]="['/admin/user/list']" routerLinkActive="router-link-active">{{'navbar.users' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="userCU && entityAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkUser]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkUser}">{{userT | translate}}</a>
            </li>

            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && entityUL && newAct">
                <a class="nav-link navColor" [routerLink]="[linkEntityUL]" routerLinkActive="router-link-active">{{'entityUser.title' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && entityUD && newAct">
                <a class="nav-link navColor" [routerLink]="[linkEntityU]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkEntityU}">{{entityUT | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && accountAct && propertyFI && !investmentDFI && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="['/admin/investment/list']" routerLinkActive="router-link-active">{{'navbar.investment_funds' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="investmentCU && entityAct && userAct && entityUAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkInvestment]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkInvestment}">{{investmentT | translate}}</a>
            </li>

            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDL && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkInvestmentDL]" routerLinkActive="router-link-active">{{'investment.douments' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDD && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkInvestmentD]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkInvestmentD}">{{investmentDT | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && accountAct && propertyP && investmentDAct && !propertyDFI && !propertyIFI && newAct">
                <a class="nav-link navColor" [routerLink]="['admin/property/list']" routerLinkActive="router-link-active">{{'navbar.properties' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="propertyCU && entityAct && userAct && entityUAct && investmentAct && accountAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkProperty]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkProperty}">{{propertyT | translate}}</a>
            </li>

            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDL && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkPropertyDL]" routerLinkActive="router-link-active">{{'property.documents' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDD && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkPropertyD]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkPropertyD}">{{propertyDT | translate}}</a>
            </li>

            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIL && newAct">
                <a class="nav-link navColor" [routerLink]="[linkPropertyIL]" routerLinkActive="router-link-active">{{'property.images' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyII && newAct">
                <a class="nav-link navColor" [routerLink]="[linkPropertyI]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkPropertyI}">{{propertyIT | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="['/admin/accountStatus/list']" routerLinkActive="router-link-active">{{'navbar.accountStatus' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="accountCU && entityAct && userAct && entityUAct && investmentAct && propertyAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="[linkAccount]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkAccount}">{{accountT | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct">
                <a class="nav-link navColor" [routerLink]="['/admin/new/list']" routerLinkActive="router-link-active">{{'navbar.news' | translate}}</a>
            </li>
            <li class="nav-item waves-light" mdbWavesEffect *ngIf="newU && entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct">
                <a class="nav-link navColor" [routerLink]="[linkNew]" routerLinkActive="router-link-active" [ngClass]="{'router-link-active':classActive === linkNew}">{{newT | translate}}</a>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect *ngIf="entityAct && userAct && entityUAct && investmentAct && propertyAct && accountAct && investmentDAct && propertyDAct && propertyIAct && newAct">
                <a class="nav-link navColor" [routerLink]="['/admin/profile', id]" routerLinkActive="router-link-active">{{'navbar.profile' | translate}}</a>
            </li>
        </ul>

        <ul class="navbar-nav mr-auto" *ngIf="isLogged && role_id == '2'">
            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/user/accountStatus']" routerLinkActive="router-link-active" (click)="onChangeLink('/user/accountStatus')" [ngClass]="{'router-link-active':classActive === '/user/accountStatus' && active === true}">{{'navbar.accountStatus' | translate}}</a>
            </li>


            <li class="nav-item dropdown btn-group" dropdown>
                <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" routerLinkActive="router-link-active" (click)="onChangeLink('/user/investment')" [ngClass]="{'router-link-active':classActive === '/user/investment' && active === false}" mdbWavesEffect>
                    {{'home.investment.title' | translate}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" role="menu">
                    <a *ngFor="let investment of investments" class="dropdown-item" [routerLink]="['/user/investment', investment.value]">{{investment.label}}</a>
                </div>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/admin/profile', id]" routerLinkActive="router-link-active" (click)="onChangeLink('/admin/profile')" [ngClass]="{'router-link-active':classActive === '/admin/profile' && active === true}">{{'navbar.profile' | translate}}</a>
            </li>
        </ul>

        <ul class="navbar-nav mr-auto" *ngIf="isLogged && role_id == '3'">
            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/user/accountStatusAll']" routerLinkActive="router-link-active" (click)="onChangeLink('/user/accountStatusAll')" [ngClass]="{'router-link-active':classActive === '/user/accountStatusAll' && active === true}">{{'navbar.accountStatus' | translate}}</a>
            </li>


            <li class="nav-item dropdown btn-group" dropdown>
                <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" routerLinkActive="router-link-active" (click)="onChangeLink('/user/investment')" [ngClass]="{'router-link-active':classActive === '/user/investment' && active === false}" mdbWavesEffect>
                    {{'home.investment.title' | translate}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" role="menu">
                    <a *ngFor="let investment of investments" class="dropdown-item" [routerLink]="['/user/investment', investment.value]">{{investment.label}}</a>
                </div>
            </li>


            <li class="nav-item waves-light" mdbWavesEffect>
                <a class="nav-link navColor" [routerLink]="['/admin/profile', id]" routerLinkActive="router-link-active" (click)="onChangeLink('/admin/profile')" [ngClass]="{'router-link-active':classActive === '/admin/profile' && active === true}">{{'navbar.profile' | translate}}</a>
            </li>
        </ul>

        <ul class="navbar-nav nav-flex-icons">
            <li class="nav-item waves-light dropdown" mdbWavesEffect>
                <a class="nav-link navColor" routerLinkActive="router-link-active" [routerLink]="['/contact']" (click)="onChangeLink('/contact')" [ngClass]="{'router-link-active':classActive === '/contact' && active === true}">
                    <mdb-icon fas icon="envelope"></mdb-icon> {{'navbar.contact' | translate}}
                </a>
            </li>


            <li id="negro" class="nav-item dropdown btn-group" dropdown>
                <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" (click)="onChangeLink('/language')" [ngClass]="{'router-link-active':classActive === '/language' && active === false}" mdbWavesEffect>
                    <mdb-icon fas icon="globe-americas"></mdb-icon> {{'navbar.language' | translate}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" role="menu">
                    <a class="dropdown-item" (click)="onChangeLanguage('es')"><img src="assets/images/es.gif" class="img-fluid" width="25" height="25" alt="Aztec"> es</a>
                    <a class="dropdown-item" (click)="onChangeLanguage('en')"><img src="assets/images/en.gif" class="img-fluid" width="25" height="25" alt="Aztec"> en</a>
                </div>
            </li>


            <li class="nav-item dropdownwaves-light" mdbWavesEffect *ngIf="!isLogged">
                <a class="nav-link navColor" routerLinkActive="router-link-active" [routerLink]="['/login']" (click)="onChangeLink('/login')" [ngClass]="{'router-link-active':classActive === '/login' && active === true}">
                    <mdb-icon fas icon="user"></mdb-icon> {{'navbar.access' | translate}}
                </a>
            </li>


            <li class="nav-item dropdownwaves-light" mdbWavesEffect *ngIf="isLogged">
                <a class="nav-link navColor" routerLinkActive="router-link-active" (click)="onLogout()">
                    <mdb-icon fas icon="sign-out-alt"></mdb-icon> <b>{{'navbar.logout' | translate}}</b>
                </a>
            </li>
        </ul>
    </links>
</mdb-navbar>

<div *ngIf="space" class="spaceContainer"></div>

<app-investments-detail *ngIf="on"></app-investments-detail>
