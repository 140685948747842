import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { InvestmentInterface } from '@app/models/investment.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class InvestmentUpdateComponent implements OnInit {

    investment: FormGroup;
    selectedInvestment: InvestmentInterface;
    user_id: number;

    title = "Actualizar fondo de inversión";
    action = "Actualizar";
    back  = "Regresar";

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.selectedInvestment = this.utilityService.selectedInvestment;
        this.investment = this.utilityService.investmentUpdate;

        this.getInvestment(this.activatedRoute.snapshot.params['id']);

        this.user_id = this.auth.getCurrentUser().id;
        this.investment.controls['user_id'].setValue(this.user_id);
    }

    get formValue() {
        return this.investment.controls;
    }

    getInvestment(id: number) {
        this.dataApiService.getInvestment(id).subscribe(
            data => {
                this.investment.controls['id'].setValue(data[0]['id']);
                this.investment.controls['user_id'].setValue(data[0]['user_id']);
                this.investment.controls['name'].setValue(data[0]['name']);
                this.investment.controls['description'].setValue(data[0]['description']);
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    onUpdate(form: FormGroup) {
        if (form.valid) {
            this.selectedInvestment = {
                id: this.formValue.id.value,
                user_id: this.user_id,
                name: this.formValue.name.value,
                description: this.formValue.description.value,
                status: 1
            };

            return this.dataApiService.updateInvestment(this.selectedInvestment).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                                this.route.navigate(['/admin/investment/list']);
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }
}
