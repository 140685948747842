import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import 'jarallax';
declare var jarallax: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {

    constructor(private cd: ChangeDetectorRef, private auth: AuthService, private translate: TranslateService) { }

    ngAfterViewInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());

        jarallax(document.querySelectorAll('.jarallax'), { speed: 0.2 });

        this.cd.detectChanges();
    }
}
