<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><mdb-icon fas icon="id-badge"></mdb-icon> {{'entity.create' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="entity" (ngSubmit)="onCreate(entity)" class="text-center">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <input id="name" formControlName="name" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="name">* {{'entity.name' | translate}}</label>
                                <div *ngIf="formValue.name.invalid && (formValue.name.dirty || formValue.name.touched)" class="error">
                                    <div *ngIf="formValue.name.errors.required">
                                        {{'entity.name' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.name.invalid && formValue.name.errors.pattern && (formValue.name.dirty || formValue.name.touched)">
                                        {{'user.name' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="investment_id" formControlName="investment_id" [options]="optionsSelectInvestments" placeholder="{{'entity.select_investment' | translate}}" required autocomplete="off"></mdb-select>
                                <label for="investment_id">* {{'entity.investment' | translate}}</label>
                                <div *ngIf="formValue.investment_id.invalid && (formValue.investment_id.dirty || formValue.investment_id.touched)" class="error">
                                    <div *ngIf="formValue.investment_id.errors.required">
                                        {{'entity.investment' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.investment_id.invalid && formValue.investment_id.errors.pattern && (formValue.investment_id.dirty || formValue.investment_id.touched)">
                                        {{'entity.investment' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button [routerLink]="['/admin/entity/list']" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!entity.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.create' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
