import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MessageModule } from './message/message.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { AboutComponent } from './component/about/about.component';
import { NewsComponent } from './component/news/news.component';
import { NewsDetailComponent } from './component/news-detail/news-detail.component';
import { InvestmentsComponent } from './component/investments/investments.component';
import { PropertyDetailComponent } from './component/property-detail/property-detail.component';
import { ContactComponent } from './component/contact/contact.component';
import { LoginComponent } from './component/login/login.component';
import { NoticePrivacyComponent } from './component/notice-privacy/notice-privacy.component';
import { TermsComponent } from './component/terms/terms.component';

import { HomeAdminComponent } from './component/admin/home/home.component';
import { ProfileComponent } from './component/admin/profile/profile.component';
import { UserListComponent } from './component/admin/user/list/list.component';
import { UserCreateComponent } from './component/admin/user/create/create.component';
import { UserUpdateComponent } from './component/admin/user/update/update.component';
import { EntityListComponent } from './component/admin/entity/list/list.component';
import { EntityCreateComponent } from './component/admin/entity/create/create.component';
import { EntityUpdateComponent } from './component/admin/entity/update/update.component';
import { EntityUserListComponent } from './component/admin/entityUser/list/list.component';
import { EntityUserCreateComponent } from './component/admin/entityUser/create/create.component';
import { EntityUserUpdateComponent } from './component/admin/entityUser/update/update.component';
import { InvestmentListComponent } from './component/admin/investment/list/list.component';
import { InvestmentCreateComponent } from './component/admin/investment/create/create.component';
import { InvestmentUpdateComponent } from './component/admin/investment/update/update.component';
import { InvestmentDocumentListComponent } from './component/admin/investmentDocument/list/list.component';
import { InvestmentDocumentCreateComponent } from './component/admin/investmentDocument/create/create.component';
import { InvestmentDocumentUpdateComponent } from './component/admin/investmentDocument/update/update.component';
import { PropertyListComponent } from './component/admin/property/list/list.component';
import { PropertyCreateComponent } from './component/admin/property/create/create.component';
import { PropertyUpdateComponent } from './component/admin/property/update/update.component';
import { PropertyDocumentListComponent } from './component/admin/propertyDocument/list/list.component';
import { PropertyDocumentCreateComponent } from './component/admin/propertyDocument/create/create.component';
import { PropertyDocumentUpdateComponent } from './component/admin/propertyDocument/update/update.component';
import { PropertyGalleryListComponent } from './component/admin/propertyGallery/list/list.component';
import { PropertyGalleryCreateComponent } from './component/admin/propertyGallery/create/create.component';
import { PropertyGalleryUpdateComponent } from './component/admin/propertyGallery/update/update.component';
import { AccountStatusListComponent } from './component/admin/accountStatus/list/list.component';
import { AccountStatusCreateComponent } from './component/admin/accountStatus/create/create.component';
import { AccountStatusUpdateComponent } from './component/admin/accountStatus/update/update.component';
import { NewListComponent } from './component/admin/new/list/list.component';
import { NewCreateComponent } from './component/admin/new/create/create.component';
import { NewUpdateComponent } from './component/admin/new/update/update.component';

import { AccountStatusComponent } from './component/user/account-status/account-status.component';
import { AccountStatusAllComponent } from './component/user/account-status-all/account-status-all.component';
import { InvestmentUserComponent } from './component/user/investments/investments.component';
import { InvestmentTechnicalComponent } from './component/user/investemntDocuments/technical/technical.component';
import { InvestmentReportsComponent } from './component/user/investemntDocuments/reports/reports.component';
import { EscrowComponent } from './component/user/investemntDocuments/escrow/escrow.component';
import { AssemblyComponent } from './component/user/investemntDocuments/assembly/assembly.component';
import { PropertyDetailUserComponent } from './component/user/property-detail/property-detail.component';
import { LegalComponent } from './component/user/documents/legal/legal.component';
import { TechnicalComponent } from './component/user/documents/technical/technical.component';
import { RentRollComponent } from './component/user/documents/rent-roll/rent-roll.component';
import { ReportsComponent } from './component/user/documents/reports/reports.component';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    AboutComponent,
    NewsComponent,
    NewsDetailComponent,
    EntityListComponent,
    EntityCreateComponent,
    EntityUpdateComponent,
    EntityUserListComponent,
    EntityUserCreateComponent,
    EntityUserUpdateComponent,
    InvestmentsComponent,
    PropertyDetailComponent,
    ContactComponent,
    LoginComponent,
    NoticePrivacyComponent,
    TermsComponent,
    HomeAdminComponent,
    ProfileComponent,
    UserListComponent,
    UserCreateComponent,
    UserUpdateComponent,
    InvestmentListComponent,
    InvestmentCreateComponent,
    InvestmentUpdateComponent,
    InvestmentDocumentListComponent,
    InvestmentDocumentCreateComponent,
    InvestmentDocumentUpdateComponent,
    PropertyListComponent,
    PropertyCreateComponent,
    PropertyUpdateComponent,
    PropertyDocumentListComponent,
    PropertyDocumentCreateComponent,
    PropertyDocumentUpdateComponent,
    PropertyGalleryListComponent,
    PropertyGalleryCreateComponent,
    PropertyGalleryUpdateComponent,
    AccountStatusListComponent,
    AccountStatusCreateComponent,
    AccountStatusUpdateComponent,
    NewListComponent,
    NewCreateComponent,
    NewUpdateComponent,
    AccountStatusComponent,
    AccountStatusAllComponent,
    InvestmentUserComponent,
    InvestmentTechnicalComponent,
    InvestmentReportsComponent,
    EscrowComponent,
    AssemblyComponent,
    PropertyDetailUserComponent,
    LegalComponent,
    TechnicalComponent,
    RentRollComponent,
    ReportsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCZcn-6TgZ3pV3DmzL7H6NdF5Cgyssi0RM',
      libraries: ['places']
    }),
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    AppRoutingModule,
    MessageModule,
    TranslateModule.forRoot({
        defaultLanguage: 'es',
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient]
        }
    }),
    FileSaverModule,
    NgxCaptchaModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent]
})
export class AppModule { }
