import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginUser: FormGroup;
    emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(private formBuilder: FormBuilder, private auth: AuthService, private route: Router, private messageService: MessageService, private translate: TranslateService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        localStorage.removeItem("accessToken");
        localStorage.removeItem("currentUser");

        this.loginUser = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            password: ['', [Validators.required, Validators.minLength(5)]],
        });
    }

    get formValue() {
        return this.loginUser.controls;
    }

    onLogin(formLogin: FormGroup) {
        if (formLogin.valid) {
            return this.auth.loginUser(this.formValue).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['userId']) {
                            this.translate.get('all.welcome').subscribe((welcome:string) => {
                                this.translate.get('all.welcome_admin').subscribe((welcome_admin:string) => {
                                    this.translate.get('all.welcome_user').subscribe((welcome_user:string) => {
                                        if(data['user'].role_id == 1) {
                                            this.messageService.add(`${welcome} ${data.user.email} ${welcome_admin}`);
                                            this.route.navigate(['/admin/home']);
                                        }
                                        else
                                            this.messageService.add(`${welcome} ${data.user.email} ${welcome_user}`);

                                        if(data['user'].role_id == 2)
                                            this.route.navigate(['/user/accountStatus']);

                                        if(data['user'].role_id == 3)
                                            this.route.navigate(['/user/accountStatusAll']);
                                    });
                                });
                            });
                        }
                    }
                }
            );
        }
    }
}
