<div class="headerContainer">
    <h2 class="headerTitle">{{'allt.title.principal' | translate}}</h2>
</div>

<br>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 pt-3">
                <p class="textTitle">{{'allt.title_0' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.title_1' | translate}}</span> 
                    <span align="justify"> {{'allt.text_1' | translate}}</span> 
                </p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.title_2' | translate}}</span> 
                    <span align="justify"> {{'allt.text_2' | translate}}</span> 
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_1' | translate}}</span> 
                        <span align="justify"> {{'allt.text_3' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_2' | translate}}</span> 
                        <span align="justify"> {{'allt.text_4' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_3' | translate}}</span> 
                        <span align="justify"> {{'allt.text_5' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_4' | translate}}</span> 
                        <span align="justify"> {{'allt.text_6' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_5' | translate}}</span> 
                        <span align="justify"> {{'allt.text_7' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_6' | translate}}</span> 
                        <span align="justify"> {{'allt.text_8' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_7' | translate}}</span> 
                        <span align="justify"> {{'allt.text_9' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_8' | translate}}</span> 
                        <span align="justify"> {{'allt.text_10' | translate}}</span> 
                    </li>
                </p>
                <p class="textLegal2">
                    <li>
                        <span class="textTitle2">{{'allt.subTitle_9' | translate}}</span> 
                        <span align="justify"> {{'allt.text_11' | translate}}</span> 
                    </li>
                </p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.title_3' | translate}}</span> 
                    <span align="justify"> {{'allt.text_12' | translate}}</span> 
                </p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.title_4' | translate}}</span> 
                    <span align="justify"> {{'allt.text_13' | translate}}</span> 
                </p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.title_5' | translate}}</span> 
                    <span align="justify"> {{'allt.text_14' | translate}}</span> 
                </p>

                <p class="textLegal">{{'allt.text_15' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.title_6' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_10' | translate}}</span> 
                    <span align="justify"> {{'allt.text_16' | translate}}</span> 
                </p>
                <p class="textLegal">{{'allt.text_17' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.title_7' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_11' | translate}}</span> 
                    <span align="justify"> {{'allt.text_18' | translate}}</span> 
                </p>

                <p class="textLegal">{{'allt.text_19' | translate}}</p>
                <p class="textLegal">{{'allt.text_20' | translate}}</p>
                <p class="textLegal">{{'allt.text_21' | translate}}</p>
                <p class="textLegal">{{'allt.text_22' | translate}}</p>
                <p class="textLegal">{{'allt.text_23' | translate}}</p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_12' | translate}}</span> 
                    <span align="justify"> {{'allt.text_24' | translate}}</span> 
                </p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_13' | translate}}</span> 
                    <span align="justify"> {{'allt.text_25' | translate}}</span> 
                </p>

                <p class="textLegal">{{'allt.text_26' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.title_8' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_14' | translate}}</span> 
                    <span align="justify"> {{'allt.text_27' | translate}}</span> 
                </p>
                <ol>
                    <p class="textLegal2">{{'allt.text_28' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_29' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_30' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_31' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_32' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_33' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_34' | translate}}</p>
                </ol>

                <p class="textLegal">{{'allt.text_35' | translate}}</p>
                <p class="textLegal">{{'allt.text_36' | translate}}</p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_15' | translate}}</span> 
                    <span align="justify"> {{'allt.text_37' | translate}}</span> 
                </p>
                <ol>
                    <p class="textLegal2">{{'allt.text_38' | translate}}</p>
                    <p class="textLegal2">{{'allt.text_39' | translate}}</p>
                </ol>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_16' | translate}}</span> 
                    <span align="justify"> {{'allt.text_40' | translate}}</span> 
                </p>
                <p class="textLegal">{{'allt.text_41' | translate}}</p>
                <p class="textLegal">{{'allt.text_42' | translate}}</p>
                <p class="textLegal">{{'allt.text_43' | translate}}</p>
                <p class="textLegal">{{'allt.text_44' | translate}}</p>


                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_17' | translate}}</span> 
                    <span align="justify"> {{'allt.text_45' | translate}}</span> 
                </p>
                <p class="textLegal">{{'allt.text_46' | translate}}</p>
                <p class="textLegal">{{'allt.text_47' | translate}}</p>
                <p class="textLegal">{{'allt.text_48' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.title_9' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_18' | translate}}</span> 
                    <span align="justify"> {{'allt.text_48' | translate}}</span> 
                </p>

                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_19' | translate}}</span> 
                    <span align="justify"> {{'allt.text_49' | translate}}</span> 
                </p>

                <br>

                <p class="textTitle">{{'allt.title_10' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_20' | translate}}</span> 
                    <span align="justify"> {{'allt.text_50' | translate}}</span> 
                </p>
                <p class="textLegal">{{'allt.text_51' | translate}}</p>
                <p class="textLegal">{{'allt.text_52' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.subTitle_21' | translate}}</p>
                <p class="textLegal">{{'allt.text_53' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.subTitle_22' | translate}}</p>
                <p class="textLegal">{{'allt.text_54' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.subTitle_23' | translate}}</p>
                <p class="textLegal">{{'allt.text_55' | translate}}</p>
                <p class="textLegal">{{'allt.text_56' | translate}}</p>
                <p class="textLegal">{{'allt.text_57' | translate}}</p>

                <br>

                <p class="textTitle">{{'allt.title_11' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_24' | translate}}</span> 
                    <span align="justify"> {{'allt.text_58' | translate}}</span> 
                </p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_25' | translate}}</span> 
                    <span align="justify"> {{'allt.text_59' | translate}}</span> 
                </p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_26' | translate}}</span> 
                    <span align="justify"> {{'allt.text_60' | translate}}</span> 
                </p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_27' | translate}}</span> 
                    <span align="justify"> {{'allt.text_61' | translate}}</span> 
                </p>
                <p class="textLegal">
                    <span class="textTitle2">{{'allt.subTitle_28' | translate}}</span> 
                    <span align="justify"> {{'allt.text_62' | translate}}</span> 
                </p>
            </div>
        </div>
    </div>
</section>

