import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { PropertyGalleryInterface } from '@app/models/propertyGallery.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class PropertyGalleryListComponent implements OnInit {

    public propertyGalleries: PropertyGalleryInterface;
    public search: FormGroup;
    public page: number = environment.page;
    public id: number;
    public url_base: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
    elements: any = [];
    previous: any = [];
    headElements = ['id', 'name', 'name_file'];
    tableData: any = [];
    sorted = false;

    constructor(private http: HttpClient, private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.url_base = environment.urlPropertyGalleries;
        this.search = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['name'].setValue('');

        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.id = this.activatedRoute.snapshot.params['id'];
        this.dataApiService.getPropertyGalleriesExpress(this.id).subscribe(propertyGalleries => { this.setData(propertyGalleries, 0); this.spinnerService.hide(); });
    }

    onDelete(id: number): void {
        this.dataApiService.getPropertyGallery(id).subscribe(
            data => {
                this.propertyGalleries = {
                    id: data['id'],
                    property_id: data['property_id'],
                    name: data['name'],
                    name_file: data['name_file'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deletePropertyGallery(this.propertyGalleries).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlPropertyGalleries}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlPropertyGalleries}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.dataApiService.getPropertyGalleriesFilter(event.target.id, event.target.value).subscribe(propertyGalleries => { this.setData(propertyGalleries, 0) });
    }

    setData(propertyGalleries: any, option: number) {
        this.propertyGalleries = propertyGalleries;

        if(option == 0) //0: Todos los usuarios - 1: Filtra por usario
            this.tableData = propertyGalleries;
        else
            this.tableData = [propertyGalleries];

        this.mdbTable.setDataSource(propertyGalleries);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
