import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NewInterface } from '@app/models/new.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

    news: NewInterface;

    constructor(private route: Router, private auth: AuthService, private activatedRoute: ActivatedRoute, private dataApiService: DataApiService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.getNew(this.activatedRoute.snapshot.params['id']);
    }

    getNew(id: number) {
        this.dataApiService.getNew(id).subscribe(data => { this.news = data[0]; });
    }
}
