import { Router } from '@angular/router';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthService } from './auth.service';

import { AccountStatusInterface } from '@app/models/accountStatus.interface';
import { EntityInterface } from '@app/models/entity.interface';
import { EntityUserProfileInterface } from '@app/models/entityUserProfile.interface';
import { InvestmentDocumentInterface } from '@app/models/investmentDocument.interface';
import { InvestmentInterface } from '@app/models/investment.interface';
import { NewInterface } from '@app/models/new.interface';
import { PropertyDocumentInterface } from '@app/models/propertyDocument.interface';
import { PropertyGalleryInterface } from '@app/models/propertyGallery.interface';
import { PropertyInterface } from '@app/models/property.interface';
import { UserInterface } from '@app/models/user.interface';

@Injectable({
    providedIn: 'root'
})
export class DataApiService {

    @Output() change: EventEmitter<string> = new EventEmitter();

    url_base: string;
    url_base_express: string;
    from: string;
    token: string;
    headers: HttpHeaders;

    constructor(private route: Router, private http: HttpClient, private authService: AuthService) {
        this.url_base = environment.urlApiRest;
        this.url_base_express = environment.urlApiRestExpress;
        this.from = environment.from;
    }

    getHeader() {
        this.token = this.authService.getToken();

        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `${this.token}`
        });

        return {
            "token": this.token,
            "headers": this.headers,
        }
    }

    /*************************************************************************************************ACCOUNT-STATUS*/
    createAccountStatus(accountSatus: AccountStatusInterface) {
        const url_api = `${this.url_base}/AccountStatus?access_token=${this.getHeader().token}`;
        return this.http.post<AccountStatusInterface>(url_api, accountSatus, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteAccountStatus(accountSatus: AccountStatusInterface) {
        const url_api = `${this.url_base}/AccountStatus/${accountSatus.id}?access_token=${this.getHeader().token}`;
        return this.http.put<AccountStatusInterface>(url_api, accountSatus, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getAccountStatus(id: number) {
        const url_api = `${this.url_base}/AccountStatus/${id}?access_token=${this.getHeader().token}`;
        return this.http.get(url_api);
    }

    getAccountStatusExpress(user_id: string, investment_id: string) {
        const url_api = `${this.url_base_express}/AccountStatus/${user_id}/${investment_id}`;
        return this.http.get(url_api);
    }

    getAccountStatusExpressFilter(user_id: string, investment_id: string, entity_id: string, id: number, value: string) {
        if (!value)
            value = 'empty';

        if (!user_id)
            user_id = 'empty';

        if (!investment_id)
            investment_id = 'empty';

        if (!entity_id)
            entity_id = 'empty';

        const url_api = `${this.url_base_express}/AccountStatus_Filter/${user_id}/${investment_id}/${entity_id}/${id}/${value}`;
        return this.http.get(url_api);
    }

    updateAccountStatus(accountSatus: AccountStatusInterface) {
        const url_api = `${this.url_base}/AccountStatus/${accountSatus.id}?access_token=${this.getHeader().token}`;
        return this.http.put<AccountStatusInterface>(url_api, accountSatus, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    validateAccountStatusExpress(accountSatus: AccountStatusInterface) {
        const url_api = `${this.url_base}/AccountStatus?access_token=${this.getHeader().token}&filter[where][name]=${accountSatus.name}`;
        return this.http.get(url_api);
    }

    /**********************************************************************************************************ENTITY*/
    createEntity(user: EntityInterface) {
        const url_api = `${this.url_base}/Entities?access_token=${this.getHeader().token}`;
        return this.http.post<EntityInterface>(url_api, user, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteEntity(user: EntityInterface) {
        const url_api = `${this.url_base}/Entities/${user.id}?access_token=${this.getHeader().token}`;
        return this.http.put<EntityInterface>(url_api, user, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getEntity(id: number) {
        const url_api = `${this.url_base}/Entities?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][id]=${id}`;
        return this.http.get(url_api).pipe(map(data => data));
    }

    getEntitiesExpress() {
        const url_api = `${this.url_base_express}/Entities`;
        return this.http.get(url_api);
    }

    getEntityExpressFilter(id: string, value: string, investment_id: string) {
        if (!value)
            value = 'empty';

        if (!investment_id || investment_id == '0')
            investment_id = 'empty';

        const url_api = `${this.url_base_express}/Entities_Filter/${id}/${value}/${investment_id}`;
        return this.http.get(url_api);
    }

    getEntitiesExpressSelect(id: string) {
        if (!id && id != '0')
            id = 'empty';

        const url_api = `${this.url_base_express}/Entities_Select/${id}`;
        return this.http.get(url_api);
    }

    getEntitiesExpressSelectFilters(user_id: string, investment_id: string) {
        if (!investment_id && investment_id != '0')
            investment_id = 'empty';

        const url_api = `${this.url_base_express}/Entities_Select_Filters/${user_id}/${investment_id}`;
        return this.http.get(url_api);
    }

    getEntitiesExpressSelectFiltersUser(user_id: string) {
        const url_api = `${this.url_base_express}/Entities_Select_Filters_User/${user_id}`;
        return this.http.get(url_api);
    }

    updateEntity(user: EntityInterface) {
        const url_api = `${this.url_base}/Entities/${user.id}?access_token=${this.getHeader().token}`;
        return this.http.put<EntityInterface>(url_api, user, { headers: this.getHeader().headers });
    }

    /********************************************************************************************ENTITY-USERPROFILE*/
    createEntityUserProfiles(entityUserProfile: EntityUserProfileInterface) {
        const url_api = `${this.url_base}/EntityUserProfiles?access_token=${this.getHeader().token}`;
        return this.http.post<EntityUserProfileInterface>(url_api, entityUserProfile, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteEntityUserProfiles(entityUserProfile: EntityUserProfileInterface) {
        const url_api = `${this.url_base}/EntityUserProfiles/${entityUserProfile.id}?access_token=${this.getHeader().token}`;
        return this.http.put<EntityUserProfileInterface>(url_api, entityUserProfile, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getEntityUserProfile(id: string) {
        const url_api = `${this.url_base}/EntityUserProfiles/${id}?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][id]=${id}`;
        return this.http.get(url_api);
    }

    getEntityUserProfilesExpress(user_id: string) {
        const url_api = `${this.url_base_express}/EntityUserProfiles/${user_id}`;
        return this.http.get(url_api);
    }

    getEntityUsersProfileExpressFilter(user_id: string, investment_id: string, entity_id: string) {
        if (!investment_id)
            investment_id = 'empty';

        if (!entity_id)
            entity_id = 'empty';

        const url_api = `${this.url_base_express}/EntityUserProfiles_Filter/${user_id}/${investment_id}/${entity_id}`;
        return this.http.get(url_api);
    }

    getEntityUserProfilesExpressSelect(user_id: string) {
        const url_api = `${this.url_base_express}/EntityUserProfiles_Select/${user_id}`;
        return this.http.get(url_api);
    }

    updateEntityUserProfiles(entityUserProfile: EntityUserProfileInterface) {
        const url_api = `${this.url_base}/EntityUserProfiles/${entityUserProfile.id}?access_token=${this.getHeader().token}`;
        return this.http.put<EntityUserProfileInterface>(url_api, entityUserProfile, { headers: this.getHeader().headers }).pipe(map(data => data));
    }

    /******************************************************************************************************INVESTMENT*/
    createInvestment(investment: InvestmentInterface) {
        const url_api = `${this.url_base}/Investments?access_token=${this.getHeader().token}`;
        return this.http.post<InvestmentInterface>(url_api, investment, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteInvestment(investment: InvestmentInterface) {
        const url_api = `${this.url_base}/Investments/${investment.id}?access_token=${this.getHeader().token}`;
        return this.http.put<InvestmentInterface>(url_api, investment, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getInvestment(id: number) {
        const url_api = `${this.url_base}/Investments?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][id]=${id}`;
        return this.http.get(url_api).pipe(map(data => data));
    }

    getInvestments() {
        const url_api = `${this.url_base}/Investments?access_token=${this.getHeader().token}&filter[where][status]=1`;
        return this.http.get(url_api);
    }

    getInvestmentsExpress(investment_id: string) {
        const url_api = `${this.url_base_express}/Investments/${investment_id}`;
        return this.http.get(url_api);
    }

    getInvestmentaExpressSelect(id: number, lang: string) {
        const url_api = `${this.url_base_express}/Investments_Select/${id}/${lang}`;
        return this.http.get(url_api);
    }

    getInvestmentaExpressSelectInvestment(id: number, type: number) {
        const url_api = `${this.url_base_express}/Investments_Select_Investment/${id}/${type}`;
        return this.http.get(url_api);
    }

    getInvestmentaExpressSelectEntity(id: string) {
        const url_api = `${this.url_base_express}/Investments_Select_Entity/${id}`;
        return this.http.get(url_api);
    }

    getInvestmentsFilter(name: string, label: string) {
        const url_api = `${this.url_base}/Investments?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][${label}][like]=%${name}%&filter[order]=id DESC`;
        return this.http.get(url_api);
    }

    updateInvestment(investment: InvestmentInterface) {
        const url_api = `${this.url_base}/Investments/${investment.id}?access_token=${this.getHeader().token}`;
        return this.http.put<InvestmentInterface>(url_api, investment, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    /*********************************************************************************************INVESTMENT-DOCUMENT*/
    createInvestmentDocument(investmentDocument: InvestmentDocumentInterface) {
        const url_api = `${this.url_base}/InvestmentDocuments?access_token=${this.getHeader().token}`;
        return this.http.post<InvestmentDocumentInterface>(url_api, investmentDocument, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteInvestmentDocument(investmentDocument: InvestmentDocumentInterface) {
        const url_api = `${this.url_base}/InvestmentDocuments/${investmentDocument.id}?access_token=${this.getHeader().token}`;
        return this.http.put<InvestmentDocumentInterface>(url_api, investmentDocument, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getInvestmentDocument(id: number) {
        const url_api = `${this.url_base}/InvestmentDocuments/${id}?access_token=${this.getHeader().token}`;
        return this.http.get(url_api);
    }

    getInvestmentDocumentExpress(id: number) {
        const url_api = `${this.url_base_express}/InvestmentDocuments/${id}`;
        return this.http.get(url_api);
    }

    getInvestmentDocumentOrder(id: string) {
        const url_api = `${this.url_base_express}/InvestmentDocuments_Order/${id}`;
        return this.http.get(url_api);
    }

    getInvestmentDocumentsExpressFilter(id: string, value: string, investment_id: number, investment_type_id: string) {
        if (!value)
            value = 'empty';

        if (!investment_type_id)
            investment_type_id = 'empty';

        const url_api = `${this.url_base_express}/InvestmentDocuments_Filter/${id}/${value}/${investment_id}/${investment_type_id}`;
        return this.http.get(url_api);
    }

    getInvestmentDocumentsExpressFilterInvestmentType(id: number, investment_id: number) {
        const url_api = `${this.url_base_express}/InvestmentDocuments_Filter_InvestmentType/${id}/${investment_id}`;
        return this.http.get(url_api);
    }

    getInvestmentDocumentsExpressFilterReport(id: number, investment_type_id: number, report_type_id: string, ids: string, value: string) {
        if (!value)
            value = 'empty';

        if (!report_type_id)
            report_type_id = 'empty';

        const url_api = `${this.url_base_express}/InvestmentDocuments_Filter_Report/${id}/${investment_type_id}/${report_type_id}/${ids}/${value}`;
        return this.http.get(url_api);
    }

    updateInvestmentDocument(investmentDocument: InvestmentDocumentInterface) {
        const url_api = `${this.url_base}/InvestmentDocuments/${investmentDocument.id}?access_token=${this.getHeader().token}`;
        return this.http.put<InvestmentDocumentInterface>(url_api, investmentDocument, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    /************************************************************************************************INVESTMENT-TYPE*/
    getInvestmentTypesSelectExpress() {
        const url_api = `${this.url_base_express}/InvestmentTypes_Select`;
        return this.http.get(url_api);
    }

    /************************************************************************************************************NEW*/
    createNew(news: NewInterface) {
        const url_api = `${this.url_base}/News?access_token=${this.getHeader().token}`;
        return this.http.post<NewInterface>(url_api, news, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteNew(news: NewInterface) {
        const url_api = `${this.url_base}/News/${news.id}?access_token=${this.getHeader().token}`;
        return this.http.put<NewInterface>(url_api, news, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getNew(id: number) {
        const url_api = `${this.url_base}/News?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][id]=${id}`;
        return this.http.get(url_api);
    }

    getNews() {
        const url_api = `${this.url_base}/News?access_token=${this.getHeader().token}&filter[where][status]=1&filter[order]=id DESC`;
        return this.http.get(url_api);
    }

    getNewsFilter(id: string, value: string) {
        const url_api = `${this.url_base}/News?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][${id}][like]=%${value}%&filter[order]=id DESC`;
        return this.http.get(url_api);
    }

    updateNew(news: NewInterface) {
        const url_api = `${this.url_base}/News/${news.id}?access_token=${this.getHeader().token}`;
        return this.http.put<NewInterface>(url_api, news, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    /*******************************************************************************************************PROPERTY*/
    createProperty(property: PropertyInterface) {
        const url_api = `${this.url_base}/Properties?access_token=${this.getHeader().token}`;
        return this.http.post<PropertyInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteProperty(property: PropertyInterface) {
        const url_api = `${this.url_base}/Properties/${property.id}?access_token=${this.getHeader().token}`;
        return this.http.put<PropertyInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getProperty(id: number) {
        const url_api = `${this.url_base}/Properties?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][id]=${id}`;
        return this.http.get(url_api);
    }

    getPropertyOrder(id: string) {
        const url_api = `${this.url_base_express}/PropertyDocuments_Order/${id}`;
        return this.http.get(url_api);
    }

    getProperties() {
        const url_api = `${this.url_base}/Properties?access_token=${this.getHeader().token}&filter[where][status]=1&filter[order]=id DESC`;
        return this.http.get(url_api);
    }

    getPropertiesExpressFilter(id: string, value: string, investment_id: string) {
        if (!value)
            value = 'empty';

        if (!investment_id)
            investment_id = 'empty';

        const url_api = `${this.url_base_express}/Property_Filter/${id}/${value}/${investment_id}`;
        return this.http.get(url_api);
    }

    getPropertiesFilterInvestment(id: number) {
        const url_api = `${this.url_base}/Properties?access_token=${this.getHeader().token}&filter[where][investment_id]=${id}`;
        return this.http.get(url_api);
    }

    updateProperty(property: PropertyInterface) {
        const url_api = `${this.url_base}/Properties/${property.id}?access_token=${this.getHeader().token}`;
        return this.http.put<PropertyInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    /**********************************************************************************************PROPERTY-DOCUMENT*/
    createPropertytDocument(property: PropertyDocumentInterface) {
        const url_api = `${this.url_base}/PropertyDocuments?access_token=${this.getHeader().token}`;
        return this.http.post<PropertyDocumentInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deletePropertyDocument(property: PropertyDocumentInterface) {
        const url_api = `${this.url_base}/PropertyDocuments/${property.id}?access_token=${this.getHeader().token}`;
        return this.http.put<PropertyDocumentInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getPropertyDocument(id: number) {
        const url_api = `${this.url_base}/PropertyDocuments/${id}?access_token=${this.getHeader().token}`;
        return this.http.get(url_api);
    }

    getAllPropertyDocuments() {
        const url_api = `${this.url_base}/PropertyDocuments?access_token=${this.getHeader().token}&filter[where][status]=1`;
        return this.http.get(url_api);
    }

    getPropertyDocumentExpress(id: number) {
        const url_api = `${this.url_base_express}/PropertyDocuments/${id}`;
        return this.http.get(url_api);
    }

    getPropertyDocumentsExpressFilter(id: string, value: string, property_id: number, property_type_id: string) {
        if (!value)
            value = 'empty';

        if (!property_type_id)
            property_type_id = 'empty';

        const url_api = `${this.url_base_express}/PropertyDocuments_Filter/${id}/${value}/${property_id}/${property_type_id}`;
        return this.http.get(url_api);
    }

    getPropertyDocumentsExpressFilterPropertyType(id: number, property_type_id: number) {
        const url_api = `${this.url_base_express}/PropertyDocuments_Filter_PropertyType/${id}/${property_type_id}`;
        return this.http.get(url_api);
    }

    getPropertyDocumentsExpressFilterReport(id: number, property_type_id: number, property_section_id: string, ids: string, value: string) {
        if (!value)
            value = 'empty';

        if (!property_section_id)
            property_section_id = 'empty';

        const url_api = `${this.url_base_express}/PropertyDocuments_Filter_Report/${id}/${property_type_id}/${property_section_id}/${ids}/${value}`;
        return this.http.get(url_api);
    }

    updatePropertyDocument(property: PropertyDocumentInterface) {
        const url_api = `${this.url_base}/PropertyDocuments/${property.id}?access_token=${this.getHeader().token}`;
        return this.http.put<PropertyDocumentInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    /************************************************************************************************PROPERTY-GALLERY*/
    createPropertytGallery(property: PropertyGalleryInterface) {
        const url_api = `${this.url_base}/PropertyGalleries?access_token=${this.getHeader().token}`;
        return this.http.post<PropertyGalleryInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deletePropertyGallery(property: PropertyGalleryInterface) {
        const url_api = `${this.url_base}/PropertyGalleries/${property.id}?access_token=${this.getHeader().token}`;
        return this.http.put<PropertyGalleryInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getPropertyGalleries() {
        const url_api = `${this.url_base}/PropertyGalleries?access_token=${this.getHeader().token}&filter[where][status]=1`;
        return this.http.get(url_api);
    }

    getPropertyGalleriesExpress(id: number) {
        const url_api = `${this.url_base_express}/PropertyGalleries/${id}`;
        return this.http.get(url_api);
    }

    getPropertyGalleriesExpressFilter(id: number, type: string) {
        const url_api = `${this.url_base_express}/PropertyGalleries_Filter/${id}/${type}`;
        return this.http.get(url_api);
    }

    getPropertyGalleriesFilter(id: string, value: string) {
        const url_api = `${this.url_base}/PropertyGalleries?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][${id}][like]=%${value}%&filter[order]=id DESC`;
        return this.http.get(url_api);
    }

    getPropertyGallery(id: number) {
        const url_api = `${this.url_base}/PropertyGalleries/${id}?access_token=${this.getHeader().token}`;
        return this.http.get(url_api);
    }

    updatePropertyGallery(property: PropertyGalleryInterface) {
        const url_api = `${this.url_base}/PropertyGalleries/${property.id}?access_token=${this.getHeader().token}`;
        return this.http.put<PropertyGalleryInterface>(url_api, property, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    /************************************************************************************************PROPERTY-SECTION*/
    getPropertySectionsExpressSelect() {
        const url_api = `${this.url_base_express}/PropertySections_Select`;
        return this.http.get(url_api);
    }

    /***************************************************************************************************PROPERTY-TYPE*/
    getPropertyTypesExpressSelect() {
        const url_api = `${this.url_base_express}/PropertyTypes_Select`;
        return this.http.get(url_api);
    }

    /*****************************************************************************************************REPORT-TYPE*/
    getReportTypesExpressSelect() {
        const url_api = `${this.url_base_express}/ReportTypes_Select`;
        return this.http.get(url_api);
    }

    /*************************************************************************************************************ROL*/
    getRolesExpressSelect() {
        const url_api = `${this.url_base_express}/Roles_Select`;
        return this.http.get(url_api);
    }

    /***********************************************************************************************************USER*/
    createUser(user: UserInterface) {
        const url_api = `${this.url_base}/UserProfiles?access_token=${this.getHeader().token}`;
        return this.http.post<UserInterface>(url_api, user, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    deleteUser(user: UserInterface) {
        const url_api = `${this.url_base}/UserProfiles/${user.id}?access_token=${this.getHeader().token}`;
        return this.http.put<UserInterface>(url_api, user, { headers: this.getHeader().headers })
            .pipe(map(data => data));
    }

    getUser(id: string) {
        const url_api = `${this.url_base_express}/UserProfile/${id}`;
        return this.http.get(url_api);

        //const url_api = `${this.url_base}/UserProfiles/${id}?access_token=${this.getHeader().token}&filter[where][status]=1&filter[where][id]=${id}`;
        //return this.http.get(url_api);
    }

    getUsersExpress() {
        const url_api = `${this.url_base_express}/UserProfiles`;
        return this.http.get(url_api);
    }

    getUsersExpressFilter(id: string, value: string, investment_id: string, role_id: string, entity_id: string) {
        if (!value)
            value = 'empty';

        if (!investment_id)
            investment_id = 'empty';

        if (!role_id)
            role_id = 'empty';

        if (!entity_id)
            entity_id = 'empty';

        const url_api = `${this.url_base_express}/UserProfiles_Filter/${id}/${value}/${investment_id}/${role_id}/${entity_id}`;
        return this.http.get(url_api);
    }

    getUsersExpressSelect() {
        const url_api = `${this.url_base_express}/UserProfiles_Select`;
        return this.http.get(url_api);
    }

    updateUser(user: UserInterface) {
        const url_api = `${this.url_base}/UserProfiles/${user.id}?access_token=${this.getHeader().token}`;
        return this.http.put<UserInterface>(url_api, user, { headers: this.getHeader().headers });
    }

    /****************************************************************************************************SEND-MESSAGE*/
    ValidateDocument(user_id, investment_id, value) {
        if (!value)
            value = 'empty';

        const url_api = `${this.url_base_express}/Validate_Document/${user_id}/${investment_id}/${value}`;
        return this.http.get(url_api);
    }

    /****************************************************************************************************SEND-MESSAGE*/
    sendMessage(message) {
        const url_api = `${this.url_base_express}/sendmail/${this.from}`;
        return this.http.post(url_api, message, { headers: this.getHeader().headers }).pipe(map(data => data));
    }

    /*****************************************************************************************************DESCRIPTION*/
    changeDescription(lngI: string) {
        this.change.emit(lngI);
    }
}
