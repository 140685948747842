<section class="my-5">
    <div class="container" *ngIf="news">
        <div class="row">
            <div class="col-md-12">
                <mdb-card cascade="true" wider="true" reverse="true">
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                        <mdb-card-img src="../../../assets/uploads/new/principal/{{news.name_file}}" alt="Wide sample post image"></mdb-card-img>
                        <a><div class="mask rgba-white-slight"></div></a>
                    </div>
            
                    <mdb-card-body cascade="true" class="text-center">
                        <h2><a class="font-weight-bold">{{news.title}}</a></h2>
                        <span>{{news.source}}, {{news.date | date:'dd-MM-yyyy' }}</span>
                    </mdb-card-body>
                </mdb-card>
        
                <div class="excerpt mt-5">
                    <p align="justify" class="sangria">{{news.description}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="fixed-action-btn smooth-scroll" style="width:50px; float:left">
        <a mdbBtn [routerLink]="['/news']" class="btn-detail waves-effect waves-light rgba-grey-strong z-depth-2" mdbWavesEffect>
            <mdb-icon fas icon="angle-left white-text" size="3x" class="animated pulse infinite"></mdb-icon>
        </a>
    </div>
</section>
