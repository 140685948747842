import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from './../message.service';

declare var jQuery:any;

@Component({
    selector: 'message-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @ViewChild('modalLogin', { static: false }) modalLogin: ElementRef;

    public _isMessage: string;
    public _isError: boolean;

    constructor(private messageService: MessageService) { }

    ngOnInit() {
        this.onCheckMessage();
    }

    onCheckMessage(): void {
        this.messageService.isMessage.subscribe(value => {
            if (value) {
                this._isMessage = value;
                this._isError = this.messageService._isError;
                jQuery(this.modalLogin.nativeElement).modal('show');
                this.time();
            }
        });
    }

    getClass() {
        if(!this._isError)
            return 'colorCPP';
        else
            return 'btn-danger';
    }

    time() {
		var i = 0;
		var interval = setInterval(() => {
			if (++i === 5)
				clearInterval(interval);
			else
                jQuery(this.modalLogin.nativeElement).modal('hide');
		}, 4000);
	}
}
