import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { AccountStatusInterface } from '@app/models/accountStatus.interface';
import { UserInterface } from '@app/models/user.interface';
import { InvestmentInterface } from '@app/models/investment.interface';
import { EntityInterface } from '@app/models/entity.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class AccountStatusCreateComponent implements OnInit {

    accountStatus: FormGroup;
    selectedAccountStatus: AccountStatusInterface;
    optionsSelectUsers: UserInterface;
    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    entity: Boolean = false;
    investment: boolean = false;
    disabled: boolean = true;

    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    url_base: string;
    name_file: string;

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private authService: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());

        this.selectedAccountStatus = this.utilityService.selectedAccountStatus;
        this.accountStatus = this.utilityService.accountStatus;
        this.accountStatus.reset();

        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = this.humanizeBytes;
        this.url_base = environment.urlApiRestExpress;

        this.getSelectUser();
    }

    get formValue() {
        return this.accountStatus.controls;
    }

    getSelectUser() {
        this.dataApiService.getUsersExpressSelect().subscribe(users => { this.optionsSelectUsers = users });
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelectInvestment(id, 1).subscribe(investments => {
            this.optionsSelectInvestments = investments;
            this.investment = true;
        });
    }

    onCreate(form: FormGroup) {
        if (form.valid) {
            if (this.formValue.name_file.value) {
                this.startUpload();
                this.name_file = this.formValue.name.value;
            }

            this.selectedAccountStatus = {
                id: null,
                user_id: this.formValue.user_id.value,
                investment_id: this.formValue.investment_id.value,
                entity_id: this.formValue.entity_id.value,
                name: this.name_file.replace(/.pdf/gi, ''),
                name_file: this.name_file,
                status: 1
            };

            this.dataApiService.validateAccountStatusExpress(this.selectedAccountStatus).subscribe(dataV => {
                if(dataV[0]) {
                    this.selectedAccountStatus.id = dataV[0]['id'];

                    if(dataV[0]['status'] == 1) {
                        this.translate.get('accountStatus.error').subscribe((message:string) => {
                            this.messageService.add(message);
                        });
                    }
                    else {
                        this.dataApiService.updateAccountStatus(this.selectedAccountStatus).subscribe(
                            data => {
                                if (!data)
                                    console.log('The MYSQL API-REST is not enabled.');
                                else
                                {
                                    if (data['id']) {
                                        this.translate.get('accountStatus.exists').subscribe((message:string) => {
                                            this.messageService.add(message);
                                            this.route.navigate(['/admin/accountStatus/list']);
                                        });
                                    }
                                }
                            },
                            error => {
                                this.messageService.addError(error.error.error.message);
                            }
                        );
                    }
                }
                else {
                    this.dataApiService.createAccountStatus(this.selectedAccountStatus).subscribe(
                        data => {
                            if (!data)
                                console.log('The MYSQL API-REST is not enabled.');
                            else
                            {
                                if (data['id']) {
                                    this.translate.get('all.create_message').subscribe((message:string) => {
                                        this.messageService.add(message);
                                        this.route.navigate(['/admin/accountStatus/list']);
                                    });
                                }
                            }
                        },
                        error => {
                            this.messageService.addError(error.error.error.message);
                        }
                    );
                }
            });
        }
    }

    validateDocument(user_id: number, investment_id: number, name_file: string): void {
        if(user_id && name_file) {
            this.dataApiService.ValidateDocument(user_id, investment_id, name_file).subscribe(data => {
                if(data['result'])
                    this.showFiles();
                else {
                    this.files = [];
                    this.accountStatus.controls['name_file'].setValue('');

                    this.translate.get('all.validate').subscribe((message:string) => {
                        this.messageService.add(message);
                    });
                }
            });
        }
    }

    viewInvestment() {
        if(this.formValue.user_id.value) {
            this.dataApiService.getUser(this.formValue.user_id.value).subscribe(user => {
                this.getSelectInvestment(user[0]['investment_id']);
                this.accountStatus.controls['investment_id'].setValue('');
            });
        }
        else {
            this.investment = false;
            this.accountStatus.controls['investment_id'].setValue('');
        }

        this.entity = false;
        this.disabled = false;
        this.accountStatus.controls['entity_id'].setValue('');
    }

    getSelectEntity(user_id: string, investment_id: string) {
        this.dataApiService.getEntitiesExpressSelectFilters(user_id, investment_id).subscribe(entities => {
            this.optionsSelectEntities = entities;
            this.disabledButton();
        });
    }

    viewEntity(event: any) {
        if(this.formValue.user_id.value) {
            this.getSelectEntity(this.formValue.user_id.value, event.value);

            this.entity = true;
        }
        else {
            this.entity = false;
            this.disabled = false;
        }

        this.accountStatus.controls['entity_id'].setValue('');
    }

    disabledButton() {
        if(this.formValue.entity_id.value)
            this.disabled = false;
        else 
            this.disabled = true;
    }

    startUpload(): void {
        let url = environment.urlUploadAccountStatus;
        url = url.replace(/\//gi, '_');

        let name_file = this.formValue.name.value;

        const event: UploadInput = {
            type: 'uploadAll',
            url: `${this.url_base}/uploadAccountStatus/${url}/${name_file}`,
            method: 'POST',
            data: { sampleFile: 'bar' },
        };

        this.uploadInput.emit(event);
    }

    showFiles() {
        let files = '';
        for (let i = 0; i < this.files.length; i ++) {
            files += this.files[i].name;
            if (!(this.files.length - 1 === i))
                files += ',';
        }

        this.accountStatus.controls['name'].setValue(files);
        this.accountStatus.controls['name_file'].setValue(files);

        return files;
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    onUploadOutput(output: UploadOutput | any): void {
        if (output.type === 'allAddedToQueue') {
            let files = '';
            for (let i = 0; i < this.files.length; i ++) {
                files += this.files[i].name;
                if (!(this.files.length - 1 === i))
                    files += ',';
            }

            this.validateDocument(this.formValue.user_id.value, this.formValue.investment_id.value, files);
        }
        else if (output.type === 'addedToQueue') {
            output.file.name = output.file.name.replace('+¦', 'ó');
            output.file.name = output.file.name.replace('-¦', '°');

            this.files.push(output.file);
        }
        else if (output.type === 'uploading') {
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    }
}
