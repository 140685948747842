<div class="headerContainer">
    <h2 class="headerTitle">{{'navbar.news' | translate}}</h2>
</div>

<br>

<section class="magazine-section my-5">
    <div class="container">
        <div class="row">
            <div *ngFor="let row of news; let i = index" class="col-lg-4 col-md-6">
                <div class="single-news mb-4">
                    <div class="view overlay rounded z-depth-1-half mb-4" data-aos="flip-up" data-aos-duration="500">
                        <img src="../../../assets/uploads/new/principal/{{row.name_file}}" class="img-fluid">
                        <a [routerLink]="['/newsDetail', row.id]"><div class="mask rgba-white-slight waves-light" mdbWavesEffect></div></a>
                    </div>

                    <div class="news-data d-flex justify-content-between">
                        <a [routerLink]="['/newsDetail', row.id]" class="light-blue-text">
                            <h6 class="font-weight-bold"><mdb-icon far icon="newspaper"></mdb-icon> {{row.source}}</h6>
                        </a>
                    </div>

                    <div>
                        <p class="font-weight-bold dark-grey-text">
                            <mdb-icon far icon="clock" class="pr-1 animated pulse infinite"></mdb-icon>{{row.date | date:'dd-MM-yy' }}
                        </p>
                    </div>

                    <div>
                        <h3 class="font-weight-bold dark-grey-text">
                            <a [routerLink]="['/newsDetail', row.id]"><p style="font-size:16px">{{row.title}}</p></a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>