import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { InvestmentDocumentInterface } from '@app/models/investmentDocument.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { InvestmentTypeInterface } from '@app/models/investmentType.interface';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class InvestmentDocumentUpdateComponent implements OnInit {

    investmentDocument: FormGroup;
    selectedInvestmentDocument: InvestmentDocumentInterface;
    optionsSelectInvestmentTypes: InvestmentTypeInterface;
    optionsSelectInvestmentTypesReport = [];
    typesReport: boolean = false;
    report_type_id: string;

    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;

    idp: number;
    id: number;
    investment_id: number;
    name_file: string;
    url_base: string;

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private translate: TranslateService, private utilityService: UtilityService, private messageService: MessageService) { }

    ngOnInit() {
        this.idp = this.activatedRoute.snapshot.params['idp'];
        this.id = this.activatedRoute.snapshot.params['id'];
        this.translate.setDefaultLang(this.auth.getLanguage());

        this.optionsSelectInvestmentTypesReport = this.utilityService.optionsSelectInvestmentTypesReport;
        this.selectedInvestmentDocument = this.utilityService.selectedInvestmentDocument;
        this.investmentDocument = this.utilityService.investmentDocumentUpdate;
        this.investmentDocument.controls['investment_id'].setValue(this.id);

        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = this.humanizeBytes;
        this.url_base = environment.urlApiRestExpress;

        this.getInvestmentDocument(this.activatedRoute.snapshot.params['id']);
        this.getSelectInvestmentType();
    }

    get formValue() {
        return this.investmentDocument.controls;
    }

    getInvestmentDocument(id: number) {
        this.dataApiService.getInvestmentDocument(id).subscribe(
            data => {
                console.log(data);

                this.investmentDocument.controls['id'].setValue(data['id']);
                this.investment_id = data['investment_id'];
                this.investmentDocument.controls['investment_type_id'].setValue(data['investment_type_id']);
                this.investmentDocument.controls['report_type_id'].setValue(data['report_type_id']);
                if(data['investment_type_id'] == 2)
                    this.typesReport = true;
                this.investmentDocument.controls['name'].setValue(data['name']);
                this.name_file = data['name_file'];
                this.investmentDocument.controls['order'].setValue(data['order']);
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    getSelectInvestmentType() {
        this.dataApiService.getInvestmentTypesSelectExpress().subscribe(investmentTypes=> { this.optionsSelectInvestmentTypes = investmentTypes });
    }

    onUpdate(form: FormGroup) {

        if (form.valid) {
            if (this.formValue.name_file.value) {
                this.startUpload();
                this.name_file = this.formValue.name.value + '.pdf';
                this.name_file = this.name_file.replace(/ /gi, '_');
            }

            if(this.formValue.investment_type_id.value != 2)
                this.report_type_id = null;
            else
                this.report_type_id = this.formValue.report_type_id.value;

            this.selectedInvestmentDocument = {
                id: Number(this.id),
                investment_id: this.investment_id,
                investment_type_id: this.formValue.investment_type_id.value,
                report_type_id: this.formValue.report_type_id.value,
                name: this.formValue.name.value,
                name_file: this.name_file,
                order: this.formValue.order.value,
                status: 1
            };

            this.dataApiService.updateInvestmentDocument(this.selectedInvestmentDocument).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message);
                                this.route.navigate(['/admin/investmentDocument/list', this.idp]);
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    onActiveType(form: FormGroup) {
        if(this.formValue.investment_type_id.value == 2)
            this.typesReport = true;
        else {
            this.investmentDocument.controls['report_type_id'].setValue('report_type_id');
            this.typesReport = false;
        }
    }

    startUpload(): void {
        let url = environment.urlUploadInvestmentDocuments;
        url = url.replace(/\//gi, '_');

        let name_file = this.formValue.name.value + '.pdf';
        name_file = name_file.replace(/ /gi, '_');

        const event: UploadInput = {
            type: 'uploadAll',
            url: `${this.url_base}/uploadInvestmentDocuments/${url}/${name_file}`,
            method: 'POST',
            data: { sampleFile: 'bar' },
        };
        //this.files = [];
        this.uploadInput.emit(event);
    }

    showFiles() {
        let files = '';
        for (let i = 0; i < this.files.length; i ++) {
            files += this.files[i].name;
            if (!(this.files.length - 1 === i))
                files += ',';
        }

        files = files.replace(/ /gi, '_');
        this.investmentDocument.controls['name_file'].setValue(files);

        return files;
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    onUploadOutput(output: UploadOutput | any): void {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file);
        }
        else if (output.type === 'uploading') {
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
          this.dragOver = false;
        }

        this.showFiles();
    }
}
