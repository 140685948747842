import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { EntityInterface } from '@app/models/entity.interface';
import { RoleInterface } from '@app/models/role.interface';
import { UserInterface } from '@app/models/user.interface';

import { DataApiService } from '@app/services/data-api.service';
import { UtilityService } from '@app/services/utility.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/services/auth.service';
import { InvestmentInterface } from '@app/models/investment.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class UserListComponent implements OnInit {

    users: UserInterface;
    optionsSelectRoles: RoleInterface;
    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    search: FormGroup;
    page: number = environment.page;
    role_id: string;
    investment_id: string;
    entity_id: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
    headElements = ['id', 'name', 'email', 'role_id', 'investment_id', 'entity_id'];
    elements: any = [];
    previous: any = [];
    tableData: any = [];
    sorted = false;

    constructor(private route: Router, private translate: TranslateService, private auth: AuthService, private dataApiService: DataApiService, private utilityService: UtilityService, private messageService: MessageService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.search = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['name'].setValue('');
        this.search.controls['email'].setValue('');
        this.search.controls['role_id'].setValue('');
        this.search.controls['investment_id'].setValue('');
        this.search.controls['entity_id'].setValue('');

        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getUsersExpress().subscribe(users => {
            this.setData(users);
            this.getSelectRol();
            this.getSelectInvestment(0);
            this.getSelectEntity();
            this.spinnerService.hide();
        });
    }

    getSelectRol() {
        this.dataApiService.getRolesExpressSelect().subscribe(roles => { this.optionsSelectRoles = roles });
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(0, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments; });
    }

    getSelectEntity() {
        this.dataApiService.getEntitiesExpressSelect('').subscribe(entities => { this.optionsSelectEntities = entities });
    }

    onDelete(id: string): void {
        this.dataApiService.getUser(id).subscribe(
            data => {

                data = data[0];

                this.users = {
                    id: data['id'],
                    role_id: data['role_id'],
                    investment_id: data['investment_id'],
                    entity_id: data['entity_id'],
                    name: data['name'],
                    email: data['email'],
                    username: data['email'],
                    password: data['password'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text: string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteUser(this.users).subscribe(data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message: string) => {
                                this.messageService.add(message);
                            });
                        });
                    }
                });
            }
        );
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_id = this.formValue.investment_id.value;
        this.search.controls['investment_id'].setValue(this.investment_id);

        this.role_id = this.formValue.role_id.value;
        this.search.controls['role_id'].setValue(this.role_id);

        this.entity_id = this.formValue.entity_id.value;
        this.search.controls['entity_id'].setValue(this.entity_id);

        this.dataApiService.getUsersExpressFilter(event.target.id, event.target.value, this.investment_id, this.role_id, this.entity_id).subscribe(users => {
            this.setData(users);
            if (event.target.id == 'investment_id' || event.target.id == 'role_id' || event.target.id == 'entity_id') {
                this.search.controls['id'].setValue('');
                this.search.controls['name'].setValue('');
                this.search.controls['email'].setValue('');
            }
        });
    }

    setData(users: any) {
        this.tableData = users;
        this.mdbTable.setDataSource(users);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
