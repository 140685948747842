import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { InvestmentInterface } from '@app/models/investment.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class InvestmentCreateComponent implements OnInit {

    investment: FormGroup;
    selectedInvestment: InvestmentInterface;
    user_id: number;

    title = "Crear fondo de inversión";
    action = "Crear";
    back  = "Regresar";

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.selectedInvestment = this.utilityService.selectedInvestment;
        this.investment = this.utilityService.investment;
        this.investment.reset();
        this.user_id = this.auth.getCurrentUser().id;
        this.investment.controls['user_id'].setValue(this.user_id);
    }

    get formValue() {
        return this.investment.controls;
    }

    onCreate(form: FormGroup) {
        if (form.valid) {
            this.selectedInvestment = {
                id: null,
                user_id: this.user_id,
                name: this.formValue.name.value,
                description: this.formValue.description.value,
                status: 1
            };

            return this.dataApiService.createInvestment(this.selectedInvestment).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.create_message').subscribe((message:string) => {
                                this.messageService.add(message);
                                this.route.navigate(['/admin/investment/list']);
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }
}
