<div class="headerContainer">
    <h2 class="headerTitle">{{'all.title.principal' | translate}}</h2>
</div>

<br>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 pt-3">
                <p class="textTitle">{{'all.title_1' | translate}}</p>
                <p align="justify">{{'all.text_1' | translate}}</p>
                <p align="justify">{{'all.text_2' | translate}}</p>
                <p align="justify">{{'all.text_3' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_2' | translate}}</p>
                <p align="justify">{{'all.text_4' | translate}}</p>
                <p class="textLegal">{{'all.text_5' | translate}}</p>
                <p class="textLegal">{{'all.text_6' | translate}}</p>
                <p class="textLegal">{{'all.text_7' | translate}}</p>
                <p align="justify">{{'all.text_8' | translate}}</p>
                <p align="justify">{{'all.text_9' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_3' | translate}}</p>
                <p align="justify">{{'all.text_10' | translate}}</p>
                <p class="textLegal textTitle2">{{'all.subTitle_1' | translate}}</p>
                <p class="textLegal2">{{'all.text_11' | translate}}</p>
                <p class="textLegal2">{{'all.text_12' | translate}}</p>
                <p class="textLegal2">{{'all.text_13' | translate}}</p>
                <p class="textLegal">
                    <span class="textTitle2">{{'all.subTitle_2' | translate}}</span> 
                    <span align="justify"> {{'all.subTitle_3' | translate}}</span> 
                </p>
                <p align="justify">{{'all.text_14' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_4' | translate}}</p>
                <p align="justify">{{'all.text_15' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_5' | translate}}</p>
                <p align="justify">{{'all.text_16' | translate}}</p>
                <p class="textLegal">{{'all.text_17' | translate}}</p>
                <p class="textLegal">{{'all.text_18' | translate}}</p>
                <p class="textLegal">{{'all.text_19' | translate}}</p>
                <p align="justify">{{'all.text_20' | translate}}</p>
                <br>

                <p class="textTitle">{{'all.title_6' | translate}}</p>
                <p align="justify">{{'all.text_21' | translate}}</p>
                <p align="justify">{{'all.text_22' | translate}}</p>
                <p class="textLegal">{{'all.text_23' | translate}}</p>
                <p class="textLegal">{{'all.text_24' | translate}}</p>
                <p class="textLegal">{{'all.text_25' | translate}}</p>
                <p class="textLegal">{{'all.text_26' | translate}}</p>
                <p align="justify">{{'all.text_27' | translate}}</p>
                <p align="justify">{{'all.text_28' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_7' | translate}}</p>
                <p align="justify">{{'all.text_29' | translate}}</p>
                <p class="textLegal">{{'all.text_30' | translate}}</p>
                <p class="textLegal">{{'all.text_31' | translate}}</p>
                <p class="textLegal">{{'all.text_32' | translate}}</p>
                <p class="textLegal">{{'all.text_33' | translate}}</p>
                <p align="justify">{{'all.text_34' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_8' | translate}}</p>
                <p align="justify">{{'all.text_35' | translate}}</p>
                <p align="justify">{{'all.text_36' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_9' | translate}}</p>
                <p align="justify">{{'all.text_37' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_10' | translate}}</p>
                <p align="justify">{{'all.text_38' | translate}}</p>
                <p align="justify">{{'all.text_39' | translate}}</p>

                <br>

                <p class="textTitle">{{'all.title_11' | translate}}</p>
                <p align="justify">{{'all.text_40' | translate}}</p>
            </div>
        </div>
    </div>
</section>
