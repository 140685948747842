import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { PropertySectionInterface } from '@app/models/propertySection.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

    optionsSelectPropertySection: PropertySectionInterface;
    property_type_id: number = 1;
    searchL: FormGroup;
    page: number = environment.page;
    property_section_id: string;
    legal: any = [];

    id: number;
    url_base: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePaginationL: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

    headElements = ['name', 'type'];
    elements: any = [];
    previous: any = [];
    tableDataL: any = [];
    sorted = false;

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.url_base = environment.urlPropertyDocuments;
        this.page = environment.page;

        this.id = this.activatedRoute.snapshot.params['id'];
        this.searchL = this.utilityService.search;
        this.cleanFilter();
    }

    get formValue() {
        return this.searchL.controls;
    }

    cleanFilter(): void {
        this.searchL.controls['pageL'].setValue(this.page);
        this.searchL.controls['name'].setValue('');
        this.searchL.controls['property_section_id'].setValue('');

        this.getPropertyDocument();
    }

    getPropertyDocument() {
        this.dataApiService.getPropertyDocumentsExpressFilterPropertyType(this.id, this.property_type_id).subscribe(documents => {
            this.setData(documents);
            this. getSelectPropertySection();
        });
    }

    getSelectPropertySection() {
        this.dataApiService.getPropertySectionsExpressSelect().subscribe(propertySection => { this.optionsSelectPropertySection = propertySection });
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.property_section_id = this.formValue.property_section_id.value;
        this.searchL.controls['property_section_id'].setValue(this.property_section_id);

        this.dataApiService.getPropertyDocumentsExpressFilterReport(this.id, this.property_type_id, this.property_section_id, event.target.id, event.target.value).subscribe(documents => { this.setData(documents) });
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlPropertyDocuments}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlPropertyDocuments}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    setData(documents: any) {
        this.tableDataL = documents;
        this.mdbTable.setDataSource(documents);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
    }

    setPagination() {
        this.page = this.formValue.pageL.value;

        this.getPropertyDocument();
        this.ngAfterViewInit();

        this.searchL.controls['pageL'].setValue(this.page);
    }

    sortBy(by: string | any): void {
        this.tableDataL.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePaginationL.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePaginationL.calculateFirstItemIndex();
        this.mdbTablePaginationL.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
