import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { IMyOptions } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { NewInterface } from '@app/models/new.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-new',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class NewUpdateComponent implements OnInit {

    new: FormGroup;
    selectedNew: NewInterface;
    name_file: string;

    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    url_base: string;
    currentDate : any;
    newDate : any;

    myDatePickerOptions: IMyOptions = {
        dateFormat: 'dd-mm-yyyy',
        todayBtnTxt: "Hoy",
        clearBtnTxt: "Limpiar",
        closeBtnTxt: "Cerrar",
        dayLabels: {su: 'Lun', mo: 'Mar', tu: 'Mie', we: 'Jue', th: 'Vie', fr: 'Sab', sa: 'Dom'},
        dayLabelsFull: {su: "Lunes", mo: "Martes", tu: "Miércoles", we: "Jueves", th: "Viernes", fr: "Sabado", sa: "Domingo"},
        monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' },
        monthLabelsFull: { 1: "Enero", 2: "Febrero", 3: "Marzo", 4: "Abril", 5: "Mayo", 6: "Junio", 7: "Julio", 8: "Agosto", 9: "Septiembre", 10: "Octubre", 11: "Noviembre", 12: "Diciembre" }
    };

    constructor(private route: Router, private auth: AuthService, private activatedRoute: ActivatedRoute, private messageService: MessageService, private translate: TranslateService, private dataApiService: DataApiService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.selectedNew = this.utilityService.selectedNew;
        this.new = this.utilityService.newUpdate;

        this.getNew(this.activatedRoute.snapshot.params['id']);

        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = this.humanizeBytes;
        this.url_base = environment.urlApiRestExpress;
    }

    get formValue() {
        return this.new.controls;
    }

    getNew(id: number) {
        this.dataApiService.getNew(id).subscribe(
            data => {
                this.new.controls['id'].setValue(data[0]['id']);
                this.new.controls['title'].setValue(data[0]['title']);
                this.new.controls['description'].setValue(data[0]['description']);
                this.new.controls['source'].setValue(data[0]['source']);
                this.name_file = data[0]['name_file'];
                this.new.controls['date'].setValue(this.utilityService.dateFormat(data[0]['date'], 1));
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    onUpdate(form: FormGroup) {
        if (form.valid) {
            if(this.formValue.name_file.value) {
                this.name_file = this.formValue.name_file.value;
                this.name_file = this.name_file.replace(/ /gi, '_');
                this.startUpload();
            }

            this.selectedNew = {
                id: this.formValue.id.value,
                title: this.formValue.title.value,
                description: this.formValue.description.value,
                source: this.formValue.source.value,
                name_file: this.name_file,
                date: this.utilityService.dateFormat(this.formValue.date.value, 0),
                status: 1
            };

            return this.dataApiService.updateNew(this.selectedNew).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                                this.route.navigate(['/admin/new/list']);
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    startUpload(): void {
        let url = environment.urlUploadNew;
        url = url.replace(/\//gi, '_');

        let name_file = this.formValue.name_file.value;
        name_file = name_file.replace(/ /gi, '_');

        const event: UploadInput = {
            type: 'uploadAll',
            url: `${this.url_base}/UploadNew/${url}/${name_file}`,
            method: 'POST',
            data: { sampleFile: 'bar' },
        };
        this.files = [];
        this.uploadInput.emit(event);
    }

    showFiles() {
        let files = '';
        for (let i = 0; i < this.files.length; i ++) {
            files += this.files[i].name;
            if (!(this.files.length - 1 === i))
                files += ',';
        }

        files = files.replace(/ /gi, '_');
        this.new.controls['name_file'].setValue(files);

        return files;
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    onUploadOutput(output: UploadOutput | any): void {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file);
        }
        else if (output.type === 'uploading') {
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
          this.dragOver = false;
        }

        this.showFiles();
    }
}
