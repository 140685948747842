import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { HomeComponent } from './component/home/home.component';
import { AboutComponent } from './component/about/about.component';
import { NewsComponent } from './component/news/news.component';
import { NewsDetailComponent } from './component/news-detail/news-detail.component';
import { InvestmentsComponent } from './component/investments/investments.component';
import { PropertyDetailComponent } from './component/property-detail/property-detail.component';
import { ContactComponent } from './component/contact/contact.component';
import { PageNoFoundComponent } from './component/page-no-found/page-no-found.component';
import { LoginComponent } from './component/login/login.component';
import { NoticePrivacyComponent } from './component/notice-privacy/notice-privacy.component';
import { TermsComponent } from './component/terms/terms.component';

import { HomeAdminComponent } from './component/admin/home/home.component';
import { ProfileComponent } from './component/admin/profile/profile.component';
import { UserListComponent } from './component/admin/user/list/list.component';
import { UserCreateComponent } from './component/admin/user/create/create.component';
import { UserUpdateComponent } from './component/admin/user/update/update.component';
import { EntityListComponent } from './component/admin/entity/list/list.component';
import { EntityCreateComponent } from './component/admin/entity/create/create.component';
import { EntityUpdateComponent } from './component/admin/entity/update/update.component';
import { EntityUserListComponent } from './component/admin/entityUser/list/list.component';
import { EntityUserCreateComponent } from './component/admin/entityUser/create/create.component';
import { EntityUserUpdateComponent } from './component/admin/entityUser/update/update.component';
import { InvestmentListComponent } from './component/admin/investment/list/list.component';
import { InvestmentCreateComponent } from './component/admin/investment/create/create.component';
import { InvestmentUpdateComponent } from './component/admin/investment/update/update.component';
import { InvestmentDocumentListComponent } from './component/admin/investmentDocument/list/list.component';
import { InvestmentDocumentCreateComponent } from './component/admin/investmentDocument/create/create.component';
import { InvestmentDocumentUpdateComponent } from './component/admin/investmentDocument/update/update.component';
import { PropertyListComponent } from './component/admin/property/list/list.component';
import { PropertyCreateComponent } from './component/admin/property/create/create.component';
import { PropertyUpdateComponent } from './component/admin/property/update/update.component';
import { PropertyDocumentListComponent } from './component/admin/propertyDocument/list/list.component';
import { PropertyDocumentCreateComponent } from './component/admin/propertyDocument/create/create.component';
import { PropertyDocumentUpdateComponent } from './component/admin/propertyDocument/update/update.component';
import { PropertyGalleryListComponent } from './component/admin/propertyGallery/list/list.component';
import { PropertyGalleryCreateComponent } from './component/admin/propertyGallery/create/create.component';
import { PropertyGalleryUpdateComponent } from './component/admin/propertyGallery/update/update.component';
import { AccountStatusListComponent } from './component/admin/accountStatus/list/list.component';
import { AccountStatusCreateComponent } from './component/admin/accountStatus/create/create.component';
import { AccountStatusUpdateComponent } from './component/admin/accountStatus/update/update.component';
import { NewListComponent } from './component/admin/new/list/list.component';
import { NewCreateComponent } from './component/admin/new/create/create.component';
import { NewUpdateComponent } from './component/admin/new/update/update.component';

import { AccountStatusComponent } from './component/user/account-status/account-status.component';
import { AccountStatusAllComponent } from './component/user/account-status-all/account-status-all.component';
import { InvestmentUserComponent } from './component/user/investments/investments.component';
import { PropertyDetailUserComponent } from './component/user/property-detail/property-detail.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'news', component: NewsComponent },
    { path: 'newsDetail/:id', component: NewsDetailComponent },
    { path: 'investments/:id', component: InvestmentsComponent },
    { path: 'propertyDetail/:idp/:id', component: PropertyDetailComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'login', component: LoginComponent },
    { path: 'noticePrivacy', component: NoticePrivacyComponent },
    { path: 'terms', component: TermsComponent },

    { path: 'admin/home', component: HomeAdminComponent, canActivate: [AuthGuard] },
    { path: 'admin/profile/:id', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'admin/user/list', component: UserListComponent, canActivate: [AuthGuard] },
    { path: 'admin/user/create', component: UserCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/user/update/:id', component: UserUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/entity/list', component: EntityListComponent, canActivate: [AuthGuard] },
    { path: 'admin/entity/create', component: EntityCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/entity/update/:id', component: EntityUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/entityUser/list/:id', component: EntityUserListComponent, canActivate: [AuthGuard] },
    { path: 'admin/entityUser/create/:id', component: EntityUserCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/entityUser/update/:id/:ide', component: EntityUserUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/investment/list', component: InvestmentListComponent, canActivate: [AuthGuard] },
    { path: 'admin/investment/create', component: InvestmentCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/investment/update/:id', component: InvestmentUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/investmentDocument/list/:id', component: InvestmentDocumentListComponent, canActivate: [AuthGuard] },
    { path: 'admin/investmentDocument/create/:id', component: InvestmentDocumentCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/investmentDocument/update/:idp/:id', component: InvestmentDocumentUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/property/list', component: PropertyListComponent, canActivate: [AuthGuard] },
    { path: 'admin/property/create', component: PropertyCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/property/create/:id', component: PropertyCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/property/update/:id', component: PropertyUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/propertyDocument/list/:id', component: PropertyDocumentListComponent, canActivate: [AuthGuard] },
    { path: 'admin/propertyDocument/create/:id', component: PropertyDocumentCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/propertyDocument/update/:idp/:id', component: PropertyDocumentUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/propertyGallery/list/:id', component: PropertyGalleryListComponent, canActivate: [AuthGuard] },
    { path: 'admin/propertyGallery/create/:id', component: PropertyGalleryCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/propertyGallery/update/:idp/:id', component: PropertyGalleryUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/accountStatus/list', component: AccountStatusListComponent, canActivate: [AuthGuard] },
    { path: 'admin/accountStatus/create', component: AccountStatusCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/accountStatus/update/:id', component: AccountStatusUpdateComponent, canActivate: [AuthGuard] },
    { path: 'admin/new/list', component: NewListComponent, canActivate: [AuthGuard] },
    { path: 'admin/new/create', component: NewCreateComponent, canActivate: [AuthGuard] },
    { path: 'admin/new/update/:id', component: NewUpdateComponent, canActivate: [AuthGuard] },

    { path: 'user/accountStatus', component: AccountStatusComponent, canActivate: [AuthGuard] },
    { path: 'user/accountStatusAll', component: AccountStatusAllComponent, canActivate: [AuthGuard] },
    { path: 'user/investment/:id', component: InvestmentUserComponent, canActivate: [AuthGuard] },
    { path: 'user/propertyDetail/:idp/:id', component: PropertyDetailUserComponent, canActivate: [AuthGuard] },

    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', component: PageNoFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
