import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from './../../services/utility.service';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
    @ViewChild('langInput') langInput: ElementRef;
    
    message: FormGroup;
    map = {
        lat: 19.3879901,
        lng: -99.2533758,
    };
    siteKey: string = '6LdsXd4ZAAAAAP6r2KpeyBu5bsAPeyhwHrMPKOPB';
    type: string = 'Image';
    theme: string = 'Light';
    size: string = 'Normal';
    lang: string = 'es';

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) {}

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.message = this.utilityService.message;
    }

    get formValue() {
        return this.message.controls;
    }

    cleanForm() {
        this.message.reset();
    }

    sendMessage(form: FormGroup) {

        if (form.valid) {
            let messageI = {
                name: this.formValue.name.value,
                email: this.formValue.email.value,
                subject: this.formValue.subject.value,
                description: this.formValue.description.value
            };

            this.dataApiService.sendMessage(messageI).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data) {
                            this.translate.get('all.send_message').subscribe((message:string) => {
                                this.messageService.add(message);
                                this.cleanForm();
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    handleReset() {}

    handleExpire() {}
    
    handleLoad() {}

    handleSuccess(event) {
        console.log(event);
    }
}
