<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><i class="fas fa-briefcase"></i> {{'propertyGallery.update' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="propertyGallery" (ngSubmit)="onUpdate(propertyGallery)" class="text-center" encType="multipart/form-data">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="nombre"  formControlName="name" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="nombre">* {{'propertyGallery.name' | translate}}</label>
                                <div *ngIf="formValue.name.invalid && (formValue.name.dirty || formValue.name.touched)" class="error">
                                    <div *ngIf="formValue.name.errors.required">
                                        {{'propertyGallery.name' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.name.invalid && formValue.name.errors.pattern && (formValue.name.dirty || formValue.name.touched)">
                                        {{'propertyGallery.name' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="type" formControlName="type" [options]="optionsSelectTypeGallery" placeholder="{{'propertyGallery.type' | translate}}" required autocomplete="off"></mdb-select>
                                <label for="type">* {{'propertyGallery.typeTitle' | translate}}</label>
                                <div *ngIf="formValue.type.invalid && (formValue.type.dirty || formValue.type.touched)" class="error">
                                    <div *ngIf="formValue.type.errors.required">
                                        {{'propertyGallery.type' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.type.invalid && formValue.type.errors.pattern && (formValue.type.dirty || formValue.type.touched)">
                                        {{'propertyGallery.type' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="file-field md-form md-form-margin">
                                <div mdbBtn floating="true" size="md" color="amber" mdbWavesEffect>
                                    <mdb-icon fas icon="cloud-upload-alt" aria-hidden="true"></mdb-icon>
                                    <input name="sampleFile" type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                                </div>
                                <div class="file-path-wrapper mt-3">
                                    <input id="name_file" formControlName="name_file" class="file-path" type="text" placeholder="{{'all.upload' | translate}}" [value]="showFiles()" readonly="readonly">
                                    <div *ngIf="formValue.name_file.invalid && (formValue.name_file.dirty || formValue.name_file.touched)" class="error">
                                        <div *ngIf="formValue.name_file.errors.required">
                                            JPG {{'all.required_fields' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p id="pPassword">{{'propertyGallery.upload_text' | translate}}</p><br>
                        </div>
                    </div>

                    <button [routerLink]="['/admin/propertyGallery/list', idp]" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!propertyGallery.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.update' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
