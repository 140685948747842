<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><i class="fas fa-briefcase"></i> {{'propertyDocument.create' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="propertyDocument" (ngSubmit)="onCreate(propertyDocument)" class="text-center" encType="multipart/form-data">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="name"  formControlName="name" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="name">* {{'propertyDocument.name' | translate}}</label>
                                <div *ngIf="formValue.name.invalid && (formValue.name.dirty || formValue.name.touched)" class="error">
                                    <div *ngIf="formValue.name.errors.required">
                                        {{'propertyDocument.name' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.name.invalid && formValue.name.errors.pattern && (formValue.name.dirty || formValue.name.touched)">
                                        {{'propertyDocument.name' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <mdb-select id="property_type_id" formControlName="property_type_id" [options]="optionsSelectPropertyTypes" placeholder="{{'all.select' | translate}}" (ngModelChange)="onChangePropertyType()" required autocomplete="off"></mdb-select>
                                <label for="property_type_id">* {{'propertyDocument.property_type_id' | translate}}</label>
                                <div *ngIf="formValue.property_type_id.invalid && (formValue.property_type_id.dirty || formValue.property_type_id.touched)" class="error">
                                    <div *ngIf="formValue.property_type_id.errors.required">
                                        {{'propertyDocument.property_type_id' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.property_type_id.invalid && formValue.property_type_id.errors.pattern && (formValue.property_type_id.dirty || formValue.property_type_id.touched)">
                                        {{'propertyDocument.property_type_id' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div *ngIf="!type_legal">
                            <input id="property_section_id"  formControlName="property_section_id" type="hidden">
                        </div>
                        
                        <div *ngIf="type_legal" class="col-12">
                            <div class="md-form">
                                <mdb-select id="property_section_id" formControlName="property_section_id" [options]="optionsSelectPropertySections" placeholder="{{'all.select' | translate}}" required autocomplete="off"></mdb-select>
                                <label for="property_section_id">* {{'propertyDocument.section' | translate}}</label>
                                <div *ngIf="formValue.property_section_id.invalid && (formValue.property_section_id.dirty || formValue.property_section_id.touched)" class="error">
                                    <div *ngIf="formValue.property_section_id.errors.required">
                                        {{'propertyDocument.section' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.property_section_id.invalid && formValue.property_section_id.errors.pattern && (formValue.property_section_id.dirty || formValue.property_section_id.touched)">
                                        {{'propertyDocument.section' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="order"  formControlName="order" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="order">* {{'propertyDocument.order' | translate}}</label>
                                <div *ngIf="formValue.order.invalid && (formValue.order.dirty || formValue.order.touched)" class="error">
                                    <div *ngIf="formValue.order.errors.required">
                                        {{'propertyDocument.order' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.order.invalid && formValue.order.errors.pattern && (formValue.order.dirty || formValue.order.touched)">
                                        {{'propertyDocument.order' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="file-field md-form md-form-margin">
                                <div mdbBtn floating="true" size="md" color="amber" mdbWavesEffect>
                                    <mdb-icon fas icon="cloud-upload-alt" aria-hidden="true"></mdb-icon>
                                    <input name="sampleFile" type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                                </div>
                                <div class="file-path-wrapper mt-3">
                                    <input id="name_file" formControlName="name_file" class="file-path" type="text" placeholder="{{'all.upload' | translate}}" [value]="showFiles()" readonly="readonly">
                                    <div *ngIf="formValue.name_file.invalid && (formValue.name_file.dirty || formValue.name_file.touched)" class="error">
                                        <div *ngIf="formValue.name_file.errors.required">
                                            PDF {{'all.required_fields' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button [routerLink]="['/admin/propertyDocument/list', id]" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!propertyDocument.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.create' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
