import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { InvestmentInterface } from '@app/models/investment.interface';
import { RoleInterface } from '@app/models/role.interface';
import { EntityInterface } from '@app/models/entity.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class EntityCreateComponent implements OnInit {

    entity: FormGroup;
    entities: EntityInterface;
    optionsSelectInvestments: InvestmentInterface;

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.getSelectInvestment(0);

        this.entities = this.utilityService.selectedEntity;
        this.entity = this.utilityService.entity;
        this.entity.reset();
    }

    get formValue() {
        return this.entity.controls;
    }

    onCreate(form: FormGroup) {
        if (form.valid) {
            this.entities = {
                id: null,
                investment_id: this.formValue.investment_id.value,
                name: this.formValue.name.value,
                status: 1
            };

            return this.dataApiService.createEntity(this.entities).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.create_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                            this.route.navigate(['/admin/entity/list']);
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(id, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments });
    }
}
