<div class="headerContainer">
    <h2 class="headerTitle">{{'contact.title' | translate}}</h2>
</div>

<form autocomplete="off" [formGroup]="message" (ngSubmit)="sendMessage(message)">
    <div class="container spaceContainer">
        <section id="contact">
            <div class="row">
                <div class="col-lg-5 my-4">
                    <mdb-card>
                        <mdb-card-body>
                            <div class="form-header colorBPP accent-1">
                                <h3><mdb-icon fas icon="envelope"></mdb-icon> {{'contact.subTitle' | translate}}: </h3>
                            </div>

                            <p style="margin-bottom: 0rem;">{{'contact.msj' | translate}}</p><br>
                            <p class="black-text-2" style="font-size:12px;">* {{'all.required_fields' | translate}}</p>

                            <div class="md-form">
                                <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
                                <input mdbInput type="text" formControlName="name" id="name" class="form-control" required>
                                <label for="name">* {{'contact.name' | translate}}</label>
                            </div>

                            <div class="md-form">
                                <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
                                <input mdbInput type="text" formControlName="email" id="email" class="form-control" required>
                                <label for="email">* {{'contact.email' | translate}}</label>
                            </div>

                            <div class="md-form">
                                <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
                                <input mdbInput type="text" formControlName="subject" id="subject" class="form-control" required>
                                <label for="subject">* {{'contact.subject' | translate}}</label>
                            </div>

                            <div class="md-form">
                                <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                                <textarea mdbInput type="text" formControlName="description" id="description" class="md-textarea" required></textarea>
                                <label for="description">* {{'contact.description' | translate}}</label>
                            </div>

                            <div class="md-form" align="center">
                                <ngx-recaptcha2 #captchaElem
                                    [siteKey]="siteKey"
                                    (reset)="handleReset()"
                                    (expire)="handleExpire()"
                                    (load)="handleLoad()"
                                    (success)="handleSuccess($event)"
                                    [useGlobalDomain]="false"
                                    [size]="size"
                                    [hl]="lang"
                                    [theme]="theme"
                                    [type]="type"
                                    formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </div>

                            <div class="text-center">
                                <button [disabled]="!message.valid" mdbBtn class="waves-light text-white colorBPP" mdbWavesEffect>{{'contact.send' | translate}}</button>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </div>

                <div class="col-lg-7">
                    <agm-map class="z-depth-1-half map-container" style="height: 400px;" [latitude]="map.lat" [longitude]="map.lng">
                        <agm-marker (mouseOver)="window.open()" (mouseOut)="window.close()" [latitude]="map.lat" [longitude]="map.lng">
                            <agm-info-window #window>
                                <h6>The Aztec Funds</h6>
                            </agm-info-window>
                        </agm-marker>
                    </agm-map>

                    <br>

                    <div class="row text-center">
                        <div class="col-md-8">
                            <a mdbBtn floating="true" color="secondary-color-dark" class="accent-1 waves-light" mdbWavesEffect>
                                <mdb-icon fas icon="map-marked" class="prefix grey-text animated pulse infinite"></mdb-icon>
                            </a>
                            <p>México - Ciudad de México, C.P. 05120</p>
                            <p>{{'contact.hour' | translate}}</p>
                            <!--
                            <p>Paseo de los Tamarindos #90</p>
                            <p>Col. Bosques de las Lomas, Del. Cuajimalpa</p>
                            -->
                        </div>

                        <div class="col-md-4">
                            <a mdbBtn floating="true" color="secondary-color-dark" class="accent-1 waves-light" mdbWavesEffect>
                                <mdb-icon fas icon="phone" class="prefix grey-text animated pulse infinite"></mdb-icon>
                            </a>
                            <p>(5255) 4163 6363</p>
                            <p class="font-size:2px">09:00am/06:00pm</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</form>