<section id="home">
    <div class="row">
        <div class="col-lg-3 col-md-3 spaceH">
            <mdb-card cascade="true" wider="true">
                <div class="view view-cascade gradient-card-header colorBPP zindex">
                    <h2 class="card-header-title mb-2">{{'home.user.title' | translate}}</h2>
                </div>

                <mdb-card-body cascade="true" class="text-center zindex">
                    <mdb-card-text>
                        <p>{{'home.user.subTitle' | translate}}</p><br>
                        <p style="text-align: left">
                            - {{'home.user.content_1' | translate}}<br>
                            - {{'home.user.content_2' | translate}}<br>
                            - {{'home.user.content_3' | translate}}
                        </p>
                    </mdb-card-text>

                    <a mdbBtn rounded="true" class="waves-light text-white colorBPP" [routerLink]="['/admin/user/list']" mdbWavesEffect><mdb-icon far icon="clone" class="left"></mdb-icon>{{'home.setting' | translate}}</a>
                </mdb-card-body>
            </mdb-card>
        </div>

        <div class="col-lg-3 col-md-3 spaceH">
            <mdb-card cascade="true" wider="true">
                <div class="view view-cascade gradient-card-header colorBPP zindex">
                    <h2 class="card-header-title mb-2">{{'home.investment.title' | translate}}</h2>
                </div>

                <mdb-card-body cascade="true" class="text-center zindex">
                    <mdb-card-text>
                        <p>{{'home.investment.subTitle' | translate}}</p><br>
                        <p style="text-align: left">
                            - {{'home.investment.content_1' | translate}}<br>
                            - {{'home.investment.content_2' | translate}}<br>
                            - {{'home.investment.content_3' | translate}}
                        </p>
                    </mdb-card-text>

                    <a mdbBtn rounded="true" class="waves-light text-white colorBPP" [routerLink]="['/admin/investment/list']" mdbWavesEffect><mdb-icon far icon="clone" class="left"></mdb-icon>{{'home.setting' | translate}}</a>
                </mdb-card-body>
            </mdb-card>
        </div>

        <div class="col-lg-3 col-md-3 spaceH">
            <mdb-card cascade="true" wider="true">
                <div class="view view-cascade gradient-card-header colorBPP zindex">
                    <h2 class="card-header-title mb-2">{{'home.property.title' | translate}}</h2>
                </div>

                <mdb-card-body cascade="true" class="text-center zindex">
                    <mdb-card-text>
                        <p>{{'home.property.subTitle' | translate}}</p><br>
                        <p style="text-align: left">
                            - {{'home.property.content_1' | translate}}<br>
                            - {{'home.property.content_2' | translate}}<br>
                            - {{'home.property.content_3' | translate}}
                        </p>
                    </mdb-card-text>

                    <a mdbBtn rounded="true" class="waves-light text-white colorBPP" [routerLink]="['/admin/property/list']" mdbWavesEffect><mdb-icon far icon="clone" class="left"></mdb-icon>{{'home.setting' | translate}}</a>
                </mdb-card-body>
            </mdb-card>
        </div>

        <div class="col-lg-3 col-md-3 spaceH">
            <mdb-card cascade="true" wider="true">
                <div class="view view-cascade gradient-card-header colorBPP zindex">
                    <h2 class="card-header-title mb-2">{{'home.accountStatus.title' | translate}}</h2>
                </div>

                <mdb-card-body cascade="true" class="text-center zindex">
                    <mdb-card-text>
                        <p>{{'home.accountStatus.subTitle' | translate}}</p><br>
                        <p style="text-align: left">
                            - {{'home.accountStatus.content_1' | translate}}<br>
                            - {{'home.accountStatus.content_2' | translate}}<br>
                            <br>
                        </p>
                    </mdb-card-text>

                    <a mdbBtn rounded="true" class="waves-light text-white colorBPP" [routerLink]="['/admin/accountStatus/list']" mdbWavesEffect><mdb-icon far icon="clone" class="left"></mdb-icon>{{'home.setting' | translate}}</a>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</section>
