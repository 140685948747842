import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InvestmentInterface } from '@app/models/investment.interface';

import { InvestmentTechnicalComponent } from '../investemntDocuments/technical/technical.component';
import { InvestmentReportsComponent } from '../investemntDocuments/reports/reports.component';
import { EscrowComponent } from '../investemntDocuments/escrow/escrow.component';
import { AssemblyComponent } from '../investemntDocuments/assembly/assembly.component';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentUserComponent implements OnInit {

    @ViewChild(InvestmentTechnicalComponent) it: InvestmentTechnicalComponent;
    @ViewChild(InvestmentReportsComponent) ir: InvestmentReportsComponent;
    @ViewChild(EscrowComponent) ie: EscrowComponent;
    @ViewChild(AssemblyComponent) ia: AssemblyComponent;

    investment: InvestmentInterface;
    properties: any = [];
    i: number = 0;
    id: number;

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, public dataApiService: DataApiService, private translate: TranslateService, private utility: UtilityService, private spinnerService: NgxSpinnerService) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe(data => {
            this.translate.setDefaultLang(this.auth.getLanguage());
            this.id = this.activatedRoute.snapshot.params['id'];
            this.getInvestment(this.id);
        });
    }

    getInvestment(id: number) {
        this.spinnerService.show();
        this.dataApiService.getInvestment(id).subscribe(investment => {
            this.investment = investment;
            this.getProperties(this.id);
            this.spinnerService.hide();
        });
    }

    getProperties(id: number) {
        this.properties = [];
        this.dataApiService.getPropertiesFilterInvestment(id).subscribe(properties => {
            this.properties = properties;
            this.it.id = id; this.it.getInvestmentDocument();
            this.ir.id = id; this.ir.getInvestmentDocument();
            this.ie.id = id; this.ie.getInvestmentDocument();
            this.ia.id = id; this.ia.getInvestmentDocument();
        });
    }
}
