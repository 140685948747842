import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-escrow',
  templateUrl: './escrow.component.html',
  styleUrls: ['./escrow.component.scss']
})
export class EscrowComponent implements OnInit {

    investment_type_id: number = 3;
    searchE: FormGroup;
    page: number = environment.page;
    url_base: string;
    id: number;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePaginationE: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

    headElements = ['name'];
    elements: any = [];
    previous: any = [];
    tableDataE: any = [];
    sorted = false;

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.url_base = environment.urlPropertyDocuments;
        this.page = environment.page;

        this.id = this.activatedRoute.snapshot.params['id'];
        this.searchE = this.utilityService.search;
        this.cleanFilter();
    }

    get formValue() {
        return this.searchE.controls;
    }

    getInvestmentDocument() {
        this.dataApiService.getInvestmentDocumentsExpressFilterInvestmentType(this.id, this.investment_type_id).subscribe(documents => { this.setData(documents) });
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.dataApiService.getInvestmentDocumentsExpressFilterReport(this.id, this.investment_type_id, '', event.target.id, event.target.value).subscribe(documents => { this.setData(documents) });
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlInvestmentDocuments}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlInvestmentDocuments}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    cleanFilter(): void {
        this.searchE.controls['pageE'].setValue(this.page);
        this.searchE.controls['name'].setValue('');
        this.getInvestmentDocument();
    }

    setData(documents: any) {
        this.tableDataE = documents;
        this.mdbTable.setDataSource(documents);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
    }

    setPagination() {
        this.page = this.formValue.pageE.value;

        this.getInvestmentDocument();
        this.ngAfterViewInit();

        this.searchE.controls['pageE'].setValue(this.page);
    }

    sortBy(by: string | any): void {
        this.tableDataE.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePaginationE.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePaginationE.calculateFirstItemIndex();
        this.mdbTablePaginationE.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
