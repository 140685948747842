<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><i class="fas fa-user"></i> {{'user.update' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="user" (ngSubmit)="onUpdate(user)" class="text-center">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <input id="id" formControlName="id" type="hidden">

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6">
                            <div class="md-form">
                                <input id="name" formControlName="name" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="name">* {{'user.name' | translate}}</label>
                                <div *ngIf="formValue.name.invalid && (formValue.name.dirty || formValue.name.touched)" class="error">
                                    <div *ngIf="formValue.name.errors.required">
                                        {{'user.name' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.name.invalid && formValue.name.errors.pattern && (formValue.name.dirty || formValue.name.touched)">
                                        {{'user.name' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6">
                            <div class="md-form">
                                <input id="email" formControlName="email" type="email" class="form-control" mdbInput required autocomplete="off">
                                <label for="email">* {{'user.email' | translate}}</label>
                                <div *ngIf="formValue.email.invalid && (formValue.email.dirty || formValue.email.touched)" class="error">
                                    <div *ngIf="formValue.email.errors.required">
                                        {{'user.email' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.email.invalid && formValue.email.errors.pattern && (formValue.email.dirty || formValue.email.touched)">
                                        {{'user.email' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6">
                            <div class="md-form">
                                <input id="password" formControlName="password" type="password" class="form-control" mdbInput autocomplete="off">
                                <label for="password">* {{'user.password' | translate}}</label>
                                <div *ngIf="formValue.password.invalid && (formValue.password.dirty || formValue.password.touched)" class="error">
                                    <div *ngIf="formValue.password.errors.required">
                                        {{'user.password' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div *ngIf="formValue.password.invalid && formValue.password.errors.pattern && (formValue.password.dirty || formValue.password.touched)" style="text-align:left">
                                        <b>{{'user.password' | translate}} {{'all.not_validc' | translate}}:</b><br>
                                        - {{'user.valid_password_1' | translate}}<br>
                                        - {{'user.valid_password_2' | translate}}<br>
                                        - {{'user.valid_password_4' | translate}}<br>
                                        &nbsp;&nbsp;{{'user.valid_password_5' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6">
                            <div class="md-form">
                                <input id="repeatPassword" formControlName="repeatPassword" type="password" class="form-control" mdbInput autocomplete="off">
                                <label for="repeatPassword">* {{'user.repeatPassword' | translate}}</label>
                                <div *ngIf="formValue.repeatPassword.invalid && (formValue.repeatPassword.dirty || formValue.repeatPassword.touched)" class="error">
                                    <div *ngIf="formValue.repeatPassword.errors.required">
                                        {{'user.password' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div *ngIf="formValue.repeatPassword.invalid && formValue.repeatPassword.errors.pattern && (formValue.repeatPassword.dirty || formValue.repeatPassword.touched)" style="text-align:left">
                                        <b>{{'user.password' | translate}} {{'all.not_validc' | translate}}:</b><br>
                                        - {{'user.valid_password_1' | translate}}<br>
                                        - {{'user.valid_password_2' | translate}}<br>
                                        - {{'user.valid_password_4' | translate}}<br>
                                        &nbsp;&nbsp;{{'user.valid_password_5' | translate}}
                                    </div>
                                    <div *ngIf="formValue.repeatPassword.errors?.mustMatch">
                                        {{'user.password' | translate}}s {{'user.not_match' | translate}}
                                    </div>
                                </div>`
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="role_id" formControlName="role_id" [options]="optionsSelectRoles" placeholder="{{'user.select_role' | translate}}" (selected)="viewInvestment()" required autocomplete="off"></mdb-select>
                                <label for="password">* {{'user.role' | translate}}</label>
                                <div *ngIf="formValue.role_id.invalid && (formValue.role_id.dirty || formValue.role_id.touched)" class="error">
                                    <div *ngIf="formValue.role_id.errors.required">
                                        {{'user.role' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.role_id.invalid && formValue.role_id.errors.pattern && (formValue.role_id.dirty || formValue.role_id.touched)">
                                        {{'user.role' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="investment_id" formControlName="investment_id" [options]="optionsSelectInvestments" placeholder="{{'user.select_investment' | translate}}" (selected)="viewEntity($event)" required autocomplete="off"></mdb-select>
                                <label for="investment_id">* {{'user.investment' | translate}}</label>
                                <div *ngIf="formValue.investment_id.invalid && (formValue.investment_id.dirty || formValue.investment_id.touched)" class="error">
                                    <div *ngIf="formValue.investment_id.errors.required">
                                        {{'user.investment' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.investment_id.invalid && formValue.investment_id.errors.pattern && (formValue.investment_id.dirty || formValue.investment_id.touched)">
                                        {{'user.investment' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="entity && optionsSelectEntities">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="entity_id" formControlName="entity_id" [options]="optionsSelectEntities" placeholder="{{'user.select_entity' | translate}}" required autocomplete="off"></mdb-select>
                                <label for="entity_id">* {{'user.entity' | translate}}</label>
                                <div *ngIf="formValue.entity_id.invalid && (formValue.entity_id.dirty || formValue.entity_id.touched)" class="error">
                                    <div *ngIf="formValue.entity_id.errors.required">
                                        {{'user.entity' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.entity_id.invalid && formValue.entity_id.errors.pattern && (formValue.entity_id.dirty || formValue.entity_id.touched)">
                                        {{'user.entity' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!entity">
                        <input id="entity_id" formControlName="entity_id" type="hidden">
                    </div>

                    <button [routerLink]="['/admin/user/list']" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!user.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.update' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
