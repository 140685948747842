<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><mdb-icon fas icon="id-badge"></mdb-icon> {{'entityUser.update' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="entityUser" (ngSubmit)="onUpdate(entityUser)" class="text-center">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <input id="id" formControlName="id" type="hidden">
                    <input id="user_id" formControlName="user_id" type="hidden">

                    <div class="row"  *ngIf="optionsSelectInvestments">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="investment_id" formControlName="investment_id" [options]="optionsSelectInvestments" placeholder="{{'entityUser.select_investment' | translate}}" (selected)="viewEntity()" required autocomplete="off"></mdb-select>
                                <label for="investment_id">* {{'entityUser.investment' | translate}}</label>
                                <div *ngIf="formValue.investment_id.invalid && (formValue.investment_id.dirty || formValue.investment_id.touched)" class="error">
                                    <div *ngIf="formValue.investment_id.errors.required">
                                        {{'entityUser.investment' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.investment_id.invalid && formValue.investment_id.errors.pattern && (formValue.investment_id.dirty || formValue.investment_id.touched)">
                                        {{'entityUser.investment' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="entity && optionsSelectEntities">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="entity_id" formControlName="entity_id" [options]="optionsSelectEntities" placeholder="{{'entityUser.select_entity' | translate}}" required autocomplete="off"></mdb-select>
                                <label for="entity_id">* {{'entityUser.entity' | translate}}</label>
                                <div *ngIf="formValue.entity_id.invalid && (formValue.entity_id.dirty || formValue.entity_id.touched)" class="error">
                                    <div *ngIf="formValue.entity_id.errors.required">
                                        {{'entityUser.entity' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.entity_id.invalid && formValue.entity_id.errors.pattern && (formValue.entity_id.dirty || formValue.entity_id.touched)">
                                        {{'entityUser.entity' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!entity">
                        <input id="entity_id" formControlName="entity_id" type="hidden">
                    </div>

                    <button [routerLink]="['/admin/entityUser/list', id]" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!entityUser.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.update' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
