import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { AccountStatusInterface } from '@app/models/accountStatus.interface';
import { EntityInterface } from '@app/models/entity.interface';
import { InvestmentInterface } from '@app/models/investment.interface';
import { InvestmentDocumentInterface } from '@app/models/investmentDocument.interface';
import { PropertyDocumentInterface } from '@app/models/propertyDocument.interface';
import { PropertyGalleryInterface } from '@app/models/propertyGallery.interface';
import { PropertyInterface } from '@app/models/property.interface';
import { UserInterface } from '@app/models/user.interface';
import { NewInterface } from '@app/models/new.interface';
import { EntityUserProfileInterface } from '@app/models/entityUserProfile.interface';

export const passwordEqual: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const password = control.get('password');
    const repeatPassword = control.get('repeatPassword');

    if (repeatPassword.errors && !repeatPassword.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
    }

    // set error on matchingControl if validation fails
    if (password.value !== repeatPassword.value) {
        repeatPassword.setErrors({ mustMatch: true });
    } else {
        repeatPassword.setErrors(null);
    }

    //return password.value === repeatPassword.value ?  null : { 'noEqual':  true };
};

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //passwordPattern: any = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{5,30}$/;
    passwordPattern: any = /^(?=\D*\d)(?=[^a-z]*[a-z]).{5,30}$/;

    message = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        subject: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        recaptcha: new FormControl('', [Validators.required])
    });

    optionsSelectTypeGallery = [
        { value: "Galeria_1", label: "Galería 1" },
        { value: "Galeria_2", label: "Galería 2" }
    ];

    optionsSelectInvestmentTypesReport = [
        { value: "Anual", label: "Anual" },
        { value: "Auditoría", label: "Auditoría" },
        { value: "Trimestral", label: "Trimestral" }
    ];

    /************************************************************************************************************USER*/
    selectedUser: UserInterface = {
        id: null,
        role_id: null,
        investment_id: null,
        entity_id: null,
        name: '',
        email: '',
        password: '',
        status: 1
    };

    user = new FormGroup({
        id: new FormControl(this.selectedUser.id),
        role_id: new FormControl(this.selectedUser.role_id, Validators.required),
        investment_id: new FormControl(this.selectedUser.investment_id, Validators.required),
        entity_id: new FormControl(this.selectedUser.entity_id, Validators.required),
        name: new FormControl(this.selectedUser.name, [Validators.required, Validators.minLength(2)]),
        email: new FormControl(this.selectedUser.email, [Validators.required, Validators.pattern(this.emailPattern)]),
        password: new FormControl(this.selectedUser.password, [Validators.required, Validators.pattern(this.passwordPattern)]),
        repeatPassword: new FormControl(this.selectedUser.password, [Validators.required, Validators.pattern(this.passwordPattern)]),
        status: new FormControl(this.selectedUser.status)
    },
        { validators: passwordEqual });

    userUpdate = new FormGroup({
        id: new FormControl(this.selectedUser.id),
        role_id: new FormControl(this.selectedUser.role_id, Validators.required),
        investment_id: new FormControl(this.selectedUser.investment_id, Validators.required),
        entity_id: new FormControl(this.selectedUser.entity_id),
        name: new FormControl(this.selectedUser.name, [Validators.required, Validators.minLength(2)]),
        email: new FormControl(this.selectedUser.email, [Validators.required, Validators.pattern(this.emailPattern)]),
        password: new FormControl('', [Validators.pattern(this.passwordPattern)]),
        repeatPassword: new FormControl('', [Validators.pattern(this.passwordPattern)]),
        status: new FormControl(this.selectedUser.status)
    },
        { validators: passwordEqual });

    userUpdateUser = new FormGroup({
        id: new FormControl(this.selectedUser.id),
        role_id: new FormControl(this.selectedUser.role_id),
        investment_id: new FormControl(this.selectedUser.investment_id),
        entity_id: new FormControl(this.selectedUser.entity_id),
        name: new FormControl(this.selectedUser.name, [Validators.required, Validators.minLength(2)]),
        email: new FormControl(this.selectedUser.email, [Validators.required, Validators.pattern(this.emailPattern)]),
        password: new FormControl('', [Validators.pattern(this.passwordPattern)]),
        repeatPassword: new FormControl('', [Validators.pattern(this.passwordPattern)]),
        status: new FormControl(this.selectedUser.status)
    },
        { validators: passwordEqual });

    /**********************************************************************************************************ENTITY*/
    selectedEntity: EntityInterface = {
        id: null,
        investment_id: null,
        name: '',
        status: 1
    };

    entity = new FormGroup({
        id: new FormControl(this.selectedEntity.id),
        investment_id: new FormControl(this.selectedEntity.investment_id, Validators.required),
        name: new FormControl(this.selectedEntity.name, [Validators.required, Validators.minLength(2)]),
        status: new FormControl(this.selectedEntity.status)
    });

    entityUpdate = new FormGroup({
        id: new FormControl(this.selectedEntity.id),
        investment_id: new FormControl(this.selectedEntity.investment_id, Validators.required),
        name: new FormControl(this.selectedEntity.name, [Validators.required, Validators.minLength(2)]),
        status: new FormControl(this.selectedEntity.status)
    });

    /******************************************************************************************************ENTITY-USER*/
    selectedEntityUser: EntityUserProfileInterface = {
        id: null,
        user_id: null,
        entity_id: null,
        status: 1
    };

    entityUser = new FormGroup({
        id: new FormControl(this.selectedEntityUser.id),
        user_id: new FormControl(this.selectedEntityUser.user_id, Validators.required),
        entity_id: new FormControl(this.selectedEntityUser.entity_id, Validators.required),
        investment_id: new FormControl(null),
        status: new FormControl(this.selectedEntityUser.status)
    });

    entityUSerUpdate = new FormGroup({
        id: new FormControl(this.selectedEntityUser.id),
        user_id: new FormControl(this.selectedEntityUser.user_id, Validators.required),
        entity_id: new FormControl(this.selectedEntityUser.entity_id, Validators.required),
        investment_id: new FormControl(null),
        status: new FormControl(this.selectedEntityUser.status)
    });

    /******************************************************************************************************INVESTMENT*/
    selectedInvestment: InvestmentInterface = {
        id: null,
        user_id: null,
        name: '',
        description: '',
        status: 1
    };

    investment = new FormGroup({
        id: new FormControl(this.selectedInvestment.id),
        user_id: new FormControl(this.selectedInvestment.user_id, [Validators.required]),
        name: new FormControl(this.selectedInvestment.name, [Validators.required, Validators.minLength(5)]),
        description: new FormControl(this.selectedInvestment.description, [Validators.required, Validators.minLength(3)]),
        status: new FormControl(this.selectedInvestment.status)
    });

    investmentUpdate = new FormGroup({
        id: new FormControl(this.selectedInvestment.id),
        user_id: new FormControl(this.selectedInvestment.user_id, [Validators.required]),
        name: new FormControl(this.selectedInvestment.name, [Validators.required, Validators.minLength(5)]),
        description: new FormControl(this.selectedInvestment.description, [Validators.required, Validators.minLength(3)]),
        status: new FormControl(this.selectedInvestment.status)
    });

    /*********************************************************************************************INVESTMENT-DOCUMENT*/
    selectedInvestmentDocument: InvestmentDocumentInterface = {
        id: null,
        investment_id: null,
        investment_type_id: null,
        report_type_id: null,
        name: '',
        name_file: '',
        order: 0,
        status: 1
    };

    investmentDocument = new FormGroup({
        id: new FormControl(this.selectedInvestmentDocument.id),
        investment_id: new FormControl(this.selectedInvestmentDocument.investment_id, [Validators.required]),
        investment_type_id: new FormControl(this.selectedInvestmentDocument.investment_type_id, [Validators.required]),
        report_type_id: new FormControl(this.selectedInvestmentDocument.report_type_id),
        name: new FormControl(this.selectedInvestmentDocument.name, [Validators.required]),
        name_file: new FormControl(this.selectedInvestmentDocument.name_file, [Validators.required]),
        order: new FormControl(this.selectedInvestmentDocument.name_file, [Validators.required]),
        status: new FormControl(this.selectedInvestmentDocument.status)
    });

    investmentDocumentUpdate = new FormGroup({
        id: new FormControl(this.selectedInvestmentDocument.id),
        investment_id: new FormControl(this.selectedInvestmentDocument.investment_id),
        investment_type_id: new FormControl(this.selectedInvestmentDocument.investment_type_id, [Validators.required]),
        report_type_id: new FormControl(this.selectedInvestmentDocument.report_type_id),
        name: new FormControl(this.selectedInvestmentDocument.name, [Validators.required]),
        name_file: new FormControl(this.selectedInvestmentDocument.name_file),
        order: new FormControl(this.selectedInvestmentDocument.name_file, [Validators.required]),
        status: new FormControl(this.selectedInvestmentDocument.status)
    });

    /********************************************************************************************************PROPERTY*/
    selectedProperty: PropertyInterface = {
        id: null,
        investment_id: null,
        name: '',
        description: '',
        description_eng: '',
        address: '',
        lessee: '',
        m2: '',
        averege_term: '',
        averege_term_eng: '',
        market: '',
        year: '',
        name_file: '',
        latitude: '',
        longitude: '',
        sold: '',
        status: 1
    };

    property = new FormGroup({
        id: new FormControl(this.selectedProperty.id),
        investment_id: new FormControl(this.selectedProperty.investment_id, [Validators.required]),
        name: new FormControl(this.selectedProperty.name, [Validators.required]),
        description: new FormControl(this.selectedProperty.description, [Validators.required]),
        description_eng: new FormControl(this.selectedProperty.description, [Validators.required]),
        address: new FormControl(this.selectedProperty.address, [Validators.required]),
        lessee: new FormControl(this.selectedProperty.lessee, [Validators.required]),
        m2: new FormControl(this.selectedProperty.m2, [Validators.required]),
        averege_term: new FormControl(this.selectedProperty.averege_term, [Validators.required]),
        averege_term_eng: new FormControl(this.selectedProperty.averege_term_eng, [Validators.required]),
        market: new FormControl(this.selectedProperty.market, [Validators.required]),
        year: new FormControl(this.selectedProperty.year, [Validators.required]),
        name_file: new FormControl(this.selectedProperty.name_file, [Validators.required]),
        latitude: new FormControl(this.selectedProperty.latitude),
        longitude: new FormControl(this.selectedProperty.longitude),
        sold: new FormControl(this.selectedProperty.sold),
        status: new FormControl(this.selectedProperty.status)
    });

    propertyUpdate = new FormGroup({
        id: new FormControl(this.selectedProperty.id),
        investment_id: new FormControl(this.selectedProperty.investment_id, [Validators.required]),
        name: new FormControl(this.selectedProperty.name, [Validators.required]),
        description: new FormControl(this.selectedProperty.description, [Validators.required]),
        description_eng: new FormControl(this.selectedProperty.description, [Validators.required]),
        address: new FormControl(this.selectedProperty.address, [Validators.required]),
        lessee: new FormControl(this.selectedProperty.lessee, [Validators.required]),
        m2: new FormControl(this.selectedProperty.m2, [Validators.required]),
        averege_term: new FormControl(this.selectedProperty.averege_term, [Validators.required]),
        averege_term_eng: new FormControl(this.selectedProperty.averege_term_eng, [Validators.required]),
        market: new FormControl(this.selectedProperty.market, [Validators.required]),
        year: new FormControl(this.selectedProperty.year, [Validators.required]),
        name_file: new FormControl(this.selectedProperty.name_file),
        latitude: new FormControl(this.selectedProperty.latitude),
        longitude: new FormControl(this.selectedProperty.longitude),
        sold: new FormControl(this.selectedProperty.sold),
        status: new FormControl(this.selectedProperty.status)
    });

    /***********************************************************************************************PROPERTY-DOCUMENT*/
    selectedPropertyDocument: PropertyDocumentInterface = {
        id: null,
        property_id: null,
        property_type_id: null,
        property_section_id: null,
        name: '',
        name_file: '',
        order: 0,
        status: 1
    };

    propertyDocument = new FormGroup({
        id: new FormControl(this.selectedPropertyDocument.id),
        property_id: new FormControl(this.selectedPropertyDocument.property_id, [Validators.required]),
        property_type_id: new FormControl(this.selectedPropertyDocument.property_type_id, [Validators.required]),
        property_section_id: new FormControl(this.selectedPropertyDocument.property_section_id),
        name: new FormControl(this.selectedPropertyDocument.name, [Validators.required]),
        name_file: new FormControl(this.selectedPropertyDocument.name_file, [Validators.required]),
        order: new FormControl(this.selectedPropertyDocument.name_file, [Validators.required]),
        status: new FormControl(this.selectedPropertyDocument.status)
    });

    propertyDocumentUpdate = new FormGroup({
        id: new FormControl(this.selectedPropertyDocument.id),
        property_id: new FormControl(this.selectedPropertyDocument.property_id),
        property_type_id: new FormControl(this.selectedPropertyDocument.property_type_id, [Validators.required]),
        property_section_id: new FormControl(this.selectedPropertyDocument.property_section_id),
        name: new FormControl(this.selectedPropertyDocument.name, [Validators.required]),
        name_file: new FormControl(this.selectedPropertyDocument.name_file),
        order: new FormControl(this.selectedPropertyDocument.name_file, [Validators.required]),
        status: new FormControl(this.selectedPropertyDocument.status)
    });

    /************************************************************************************************PROPERTY-GALLERY*/
    selectedPropertyGallery: PropertyGalleryInterface = {
        id: null,
        property_id: null,
        name: 'test',
        name_file: '',
        type: 'Galeria_1',
        status: 1
    };

    propertyGallery = new FormGroup({
        id: new FormControl(this.selectedPropertyGallery.id),
        property_id: new FormControl(this.selectedPropertyGallery.property_id, [Validators.required]),
        name: new FormControl(this.selectedPropertyGallery.name, [Validators.required]),
        name_file: new FormControl(this.selectedPropertyGallery.name_file, [Validators.required]),
        type: new FormControl(this.selectedPropertyGallery.type, [Validators.required]),
        status: new FormControl(this.selectedPropertyGallery.status)
    });

    propertyGalleryUpdate = new FormGroup({
        id: new FormControl(this.selectedPropertyGallery.id),
        property_id: new FormControl(this.selectedPropertyGallery.property_id),
        name: new FormControl(this.selectedPropertyGallery.name, [Validators.required]),
        name_file: new FormControl(this.selectedPropertyGallery.name_file),
        type: new FormControl(this.selectedPropertyGallery.type, [Validators.required]),
        status: new FormControl(this.selectedPropertyGallery.status)
    });

    /*********************************************************************************************INVESTMENT-DOCUMENT*/
    selectedAccountStatus: AccountStatusInterface = {
        id: null,
        user_id: null,
        investment_id: null,
        entity_id: null,
        name: '',
        name_file: '',
        status: 1
    };

    accountStatus = new FormGroup({
        id: new FormControl(this.selectedAccountStatus.id),
        user_id: new FormControl(this.selectedAccountStatus.user_id, [Validators.required]),
        investment_id: new FormControl(this.selectedAccountStatus.investment_id, [Validators.required]),
        entity_id: new FormControl(this.selectedAccountStatus.entity_id, [Validators.required]),
        name: new FormControl(this.selectedAccountStatus.name, [Validators.required]),
        name_file: new FormControl(this.selectedAccountStatus.name_file, [Validators.required]),
        status: new FormControl(this.selectedAccountStatus.status)
    });

    accountStatusUpdate = new FormGroup({
        id: new FormControl(this.selectedAccountStatus.id),
        user_id: new FormControl(this.selectedAccountStatus.user_id),
        investment_id: new FormControl(this.selectedAccountStatus.investment_id),
        entity_id: new FormControl(this.selectedAccountStatus.entity_id),
        name: new FormControl(this.selectedAccountStatus.name, [Validators.required]),
        name_file: new FormControl(this.selectedAccountStatus.name_file),
        status: new FormControl(this.selectedAccountStatus.status)
    });

    /************************************************************************************************************NEW*/
    selectedNew: NewInterface = {
        id: null,
        title: '',
        description: '',
        source: '',
        name_file: '',
        date: null,
        status: 1
    };

    new = new FormGroup({
        id: new FormControl(this.selectedNew.id),
        title: new FormControl(this.selectedNew.title, [Validators.required]),
        description: new FormControl(this.selectedNew.description, [Validators.required]),
        source: new FormControl(this.selectedNew.source, [Validators.required]),
        name_file: new FormControl(this.selectedNew.name_file, [Validators.required]),
        date: new FormControl(this.selectedNew.date, [Validators.required]),
        status: new FormControl(this.selectedNew.status)
    });

    newUpdate = new FormGroup({
        id: new FormControl(this.selectedNew.id),
        title: new FormControl(this.selectedNew.title, [Validators.required]),
        description: new FormControl(this.selectedNew.description, [Validators.required]),
        source: new FormControl(this.selectedNew.source, [Validators.required]),
        name_file: new FormControl(this.selectedNew.name_file),
        date: new FormControl(this.selectedNew.date, [Validators.required]),
        status: new FormControl(this.selectedNew.status)
    });

    /**********************************************************************************************************SEARCH*/
    search = new FormGroup({
        id: new FormControl(''),
        name: new FormControl(''),
        description: new FormControl(''),
        title: new FormControl(''),
        email: new FormControl(''),
        user_id: new FormControl(''),
        role_id: new FormControl(''),
        entity_id: new FormControl(''),
        investment_id: new FormControl(''),
        investment_type_id: new FormControl(''),
        property_type_id: new FormControl(''),
        property_section_id: new FormControl(''),
        report_type_id: new FormControl(''),
        order: new FormControl(''),
        page: new FormControl(''),
        pageL: new FormControl(''),
        pageT: new FormControl(''),
        pageF: new FormControl(''),
        pageR: new FormControl(''),
        pageE: new FormControl(''),
        pageA: new FormControl('')
    });

    /******************************************************************************************************DATEFORMAT*/
    dateFormat(date, type) {
        if (type == 0) {
            date = date.split('-');
            date = date[2] + '-' + date[1] + '-' + date[0];
        }
        else {
            date = date.split('T');
            date = date[0].split('-');
            date = date[2] + '-' + date[1] + '-' + date[0];
        }

        return date;
    }

    constructor() {
    }
}
