import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { RoleInterface } from '@app/models/role.interface';
import { EntityInterface } from '@app/models/entity.interface';
import { DataApiService } from '@app/services/data-api.service';
import { UtilityService } from '@app/services/utility.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/services/auth.service';
import { InvestmentInterface } from '@app/models/investment.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class EntityListComponent implements OnInit {

    entities: EntityInterface;
    optionsSelectRoles: RoleInterface;
    optionsSelectInvestments: InvestmentInterface;
    search: FormGroup;
    page: number = environment.page;
    investment_id: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
    headElements = ['id', 'name', 'investment_id'];
    elements: any = [];
    previous: any = [];
    tableData: any = [];
    sorted = false;

    constructor(private route: Router, private translate: TranslateService, private auth: AuthService, private dataApiService: DataApiService, private utilityService: UtilityService, private messageService: MessageService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.search = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['name'].setValue('');
        this.search.controls['investment_id'].setValue('0');
        
        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getEntitiesExpress().subscribe(users => {
            this.setData(users);
            this.getSelectInvestment();
            this.spinnerService.hide(); 
        });
    }

    getSelectInvestment() {
        this.dataApiService.getInvestmentaExpressSelect(0, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments; });
    }

    onDelete(id: number): void {
        this.dataApiService.getEntity(id).subscribe(
            data => {
                this.entities = {
                    id: data['id'],
                    investment_id: data['investment_id'],
                    name: data['name'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteEntity(this.entities).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_id = this.formValue.investment_id.value;
        this.search.controls['investment_id'].setValue(this.investment_id);

        this.dataApiService.getEntityExpressFilter(event.target.id, event.target.value, this.investment_id).subscribe(entities => {
            this.setData(entities);
            if(event.target.id == 'investment_id') {
                this.search.controls['id'].setValue('');
                this.search.controls['name'].setValue('');
            }
        });
    }

    setData(identites: any) {
        this.tableData = identites;
        this.mdbTable.setDataSource(identites);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
