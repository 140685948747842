import { Injectable } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { UserInterface } from '@app/models/user.interface';
import { MessageService } from '@app/message/message.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    url_base: string;
    private _isLoggedIn = new BehaviorSubject<boolean>(false);
    private _roleId = new BehaviorSubject<string>('0');
    private _userId = new BehaviorSubject<string>('0');
    private _investmentId = new BehaviorSubject<string>('');

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private router: Router, private http: HttpClient, private messageService: MessageService) {
        this.url_base = environment.urlApiRest;
    }

    get isLoggedIn() {
        const token = this.getToken();
        if(token)
            this._isLoggedIn.next(true);

        return this._isLoggedIn;
    }

    get roleId() {
        const token = this.getToken();
        if(token)
            this._roleId.next(localStorage.getItem("role_id"));

        return this._roleId;
    }

    get userId() {
        const token = this.getToken();
        if(token)
            this._userId.next(localStorage.getItem("user_id"));

        return this._userId;
    }

    get investmentId() {
        const token = this.getToken();
        if(token)
            this._investmentId.next(localStorage.getItem("investment_id"));

        return this._investmentId;
    }

    loginUser(params: any): Observable<any> {
        const url_api = `${this.url_base}/UserProfiles/login?include=user`;
        return this.http.post<UserInterface>(url_api, { email: params.email.value, password: params.password.value }, { headers: this.headers })
            .pipe(
                tap(result => {
                    if (result) {
                        this._isLoggedIn.next(true);
                        this._roleId.next(result['user'].role_id);
                        this._userId.next(result['user'].id);
                        this._investmentId.next(result['user'].investment_id);

                        localStorage.setItem("role_id", result['user'].role_id);
                        localStorage.setItem("user_id", result['user'].id);
                        localStorage.setItem("investment_id", result['user'].investment_id);

                        this.setToken(result['id']);
                        this.setUser(result['user']);
                        this.setLanguage(environment.activeLang);
                        return result;
                    }
                }),
                catchError(this.handleError<any>(`Error en el inicio de sesión`))
            );
    }

    logoutUser() {
        let accesToken = localStorage.getItem("accessToken");
        const url_api = `${this.url_base}/UserProfiles/logout?access_token=${accesToken}`;
        return this.http.post<UserInterface>(url_api, { headers: this.headers })
            .pipe(
                tap(result => {
                    if (result) {
                        return result;
                    }
                }),
                catchError(this.handleError<any>(`Error al salir del sistema`))
            );
    }

    setUser(user: UserInterface): void {
        let user_string = JSON.stringify(user);
        localStorage.setItem("currentUser", user_string);
    }

    isNullOrUndefined<T>(obj?: T | null): boolean {
        // null == undefined so this is true if obj = null or obj = undefined
        return obj == null;
      }

    getCurrentUser(): UserInterface {
        let user_string = localStorage.getItem("currentUser");
        if (!this.isNullOrUndefined(user_string)) {
            let user: UserInterface = JSON.parse(user_string);
            return user;
        }
        else
            return null;
    }

    setLanguage(activeLang: string): void {
        localStorage.setItem("activeLang", activeLang);
    }

    getLanguage() {
        return localStorage.getItem("activeLang");
    }

    setToken(token): void {
        localStorage.setItem("accessToken", token);
    }

    getToken() {
        return localStorage.getItem("accessToken");
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            //console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            //console.log(`${operation} ::: ${error.message}`);

            this.log(error);

            // Let the app keep running by returning an empty result.
            return of(error);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(error) {
        //console.log(error);
        this.messageService.addError(error);
    }
}
