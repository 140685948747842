<app-navbar></app-navbar>

<div class="container-fluid">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Cargando...</p>
    </ngx-spinner>
    <router-outlet></router-outlet>
    <message-modal></message-modal>

    <div class="scroll-to-top fixed-action-btn" [ngClass]="{'show-scrollTop': windowScrolled}">
        <a mdbBtn floating="true" (click)="scrollToTop()" class="btn-floating btn-lg waves-effect waves-light colorBPP z-depth-2" mdbWavesEffect>
            <mdb-icon fas icon="angle-double-up white-text"></mdb-icon>
        </a>
    </div>
</div>

<footer class="footer mt-auto py-3" class="headerContainerF">
    <div *ngIf="auth.getLanguage()" class="container">
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12 pfooter">
                <span>© Copyright - Aztec Funds</span>
            </div>
            <div class="col-md-6 col-sm-12 text-right pfooter">
                <a class="linkF" [routerLink]="['/noticePrivacy']">{{'all.title.principal' | translate}}</a> <span class="separator"> | </span> <a class="linkF" [routerLink]="['/terms']">{{'allt.title.principal' | translate}}</a>
            </div>
        </div>
    </div>
</footer>
