import { PropertyTypeInterface } from '@app/models/propertyType.interface';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { PropertyGalleryInterface } from '@app/models/propertyGallery.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class PropertyGalleryUpdateComponent implements OnInit {

    propertyGallery: FormGroup;
    selectedPropertyGallery: PropertyGalleryInterface;
    optionsSelectTypeGallery = [];

    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    url_base: string;

    idp: number;
    id: number;
    property_id: number;
    name_file: string;

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.idp = this.activatedRoute.snapshot.params['idp'];
        this.id = this.activatedRoute.snapshot.params['id'];

        this.propertyGallery = this.utilityService.propertyGalleryUpdate;
        this.propertyGallery.controls['property_id'].setValue(this.id);
        this.optionsSelectTypeGallery = this.utilityService.optionsSelectTypeGallery;

        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = this.humanizeBytes;
        this.url_base = environment.urlApiRestExpress;

        this.getPropertyGallery(this.activatedRoute.snapshot.params['id']);
    }

    get formValue() {
        return this.propertyGallery.controls;
    }

    getPropertyGallery(id: number) {
        this.dataApiService.getPropertyGallery(id).subscribe(
            data => {
                this.propertyGallery.controls['id'].setValue(data['id']);
                this.property_id = data['property_id'];
                this.propertyGallery.controls['name'].setValue(data['name']);
                this.name_file = data['name_file'];
                this.propertyGallery.controls['type'].setValue(data['type']);
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    onUpdate(form: FormGroup) {

        if (form.valid) {
            if (this.formValue.name_file.value) {
                this.startUpload();
                this.name_file = this.formValue.name.value + '.jpg';
                this.name_file = this.name_file.replace(/ /gi, '_');
            }

            this.selectedPropertyGallery = {
                id: Number(this.id),
                property_id: this.property_id,
                name: this.formValue.name.value,
                name_file: this.name_file,
                type: this.formValue.type.value,
                status: 1
            };

            return this.dataApiService.updatePropertyGallery(this.selectedPropertyGallery).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message);
                                this.route.navigate(['/admin/propertyGallery/list', this.idp]);
                            });
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    startUpload(): void {
        let url = environment.urlUploadPropertyGalleries;
        url = url.replace(/\//gi, '_');

        let name_file = this.formValue.name.value + '.jpg';
        name_file = name_file.replace(/ /gi, '_');

        const event: UploadInput = {
            type: 'uploadAll',
            url: `${this.url_base}/uploadPropertyGalleries/${url}/${name_file}`,
            method: 'POST',
            data: { sampleFile: 'bar' },
        };
        this.files = [];
        this.uploadInput.emit(event);
    }

    showFiles() {
        let files = '';
        for (let i = 0; i < this.files.length; i ++) {
            files += this.files[i].name;
            if (!(this.files.length - 1 === i))
                files += ',';
        }

        files = files.replace(/ /gi, '_');
        this.propertyGallery.controls['name_file'].setValue(files);

        return files;
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    onUploadOutput(output: UploadOutput | any): void {
        if (output.type === 'allAddedToQueue') {
        }
        else if (output.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
        }
        else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => file.id === output.file.id);
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
        }
        else if (output.type === 'drop') {
          this.dragOver = false;
        }

        this.showFiles();
    }
}
