import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { RoleInterface } from '../../../models/role.interface';
import { InvestmentInterface } from 'src/app/models/investment.interface';
import { UserInterface } from '../../../models/user.interface';

import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../../message/message.service';
import { DataApiService } from '../../../services/data-api.service';
import { UtilityService } from '../../../services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    user: FormGroup;
    selectedUser: UserInterface;
    optionsSelectRoles: RoleInterface;
    optionsSelectInvestments: InvestmentInterface;
    passwordBackup = String;
    role_id: number;
    investment_id: number;
    url: String;

    title = "Perfil";
    action = "Actualizar";
    back  = "Regresar";

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        
        this.selectedUser = this.utilityService.selectedUser;
        this.auth.roleId.subscribe(role_id => {
            this.role_id = parseInt(role_id);

            if(this.role_id == 1) {
                this.user = this.utilityService.userUpdate;

                this.url = "/admin/home";
            }
            else {
                this.user = this.utilityService.userUpdateUser;

                if(this.role_id == 3)
                    this.url = "/user/accountStatusAll";
                else
                    this.url = "/user/accountStatus";
            }  
        });

        this.getUser(this.activatedRoute.snapshot.params['id']);
    }

    get formValue() {
        return this.user.controls;
    }

    getUser(id: string) {
        this.dataApiService.getUser(id).subscribe(
            data => {
                data = data[0];

                this.user.controls['id'].setValue(data['id']);
                this.user.controls['investment_id'].setValue(data['investment_id']);
                this.investment_id = data['investment_id'];
                this.user.controls['entity_id'].setValue(data['entity_id']);
                this.user.controls['role_id'].setValue(data['role_id']);
                this.role_id = data['role_id'];
                this.user.controls['name'].setValue(data['name']);
                this.user.controls['email'].setValue(data['email']);
                this.passwordBackup = data['password'];

                if(data['role_id'] != 1) {
                    this.user.controls['name'].disable();
                    this.user.controls['email'].disable();
                }

                this.getSelectRol();
                this.getSelectInvestment(0);
            },
            error => {
                this.messageService.addError(error.error.error.message);
            }
        );
    }

    onUpdate(form: FormGroup) {
        if (form.valid) {
            if(this.role_id == 1)
                this.selectedUser = {
                    id: this.formValue.id.value,
                    role_id: this.formValue.role_id.value,
                    investment_id: this.formValue.investment_id.value,
                    entity_id: this.formValue.entity_id.value,
                    name: this.formValue.name.value,
                    email: this.formValue.email.value,
                    username: this.formValue.email.value,
                    password: this.formValue.password.value ? this.formValue.password.value : this.passwordBackup,
                    status: 1
                };
            else
                this.selectedUser = {
                    id: this.formValue.id.value,
                    role_id: this.role_id,
                    investment_id: this.investment_id,
                    entity_id: this.formValue.entity_id.value,
                    name: this.formValue.name.value,
                    email: this.formValue.email.value,
                    username: this.formValue.email.value,
                    password: this.formValue.password.value ? this.formValue.password.value : this.passwordBackup,
                    status: 1
                };

            return this.dataApiService.updateUser(this.selectedUser).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.update_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });

                            if(data['role_id'] == 1)
                                this.route.navigate(['/admin/home']);

                            if(data['role_id'] == 2)
                                this.route.navigate(['/user/accountStatus']);

                            if(data['role_id'] == 3)
                                this.route.navigate(['/user/accountStatusAll']);
                        }
                    }
                },
                error => {
                    this.messageService.addError(error);
                }
            );
        }
    }

    getSelectRol() {
        this.dataApiService.getRolesExpressSelect().subscribe(roles => { this.optionsSelectRoles = roles });
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(id, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments });
    }
}

