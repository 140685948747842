import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NewInterface } from '@app/models/new.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    news: NewInterface;

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private translate: TranslateService, private spinnerService: NgxSpinnerService) { }

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getNews().subscribe(news => { this.news = news; this.spinnerService.hide(); });
    }

}
