import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    constructor(private translate: TranslateService, private auth: AuthService) { }

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
    }
}
