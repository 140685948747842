import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { InvestmentInterface } from '@app/models/investment.interface';

import { environment } from '@environments/environment';
import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    investments: InvestmentInterface;
    isLogged: boolean;
    role_id: string = '0';
    id: string = '0';
    active: boolean = true;
    classActive: string;
    url: string;
    space: boolean = false;
    logo: string;
    idLogo: string;
    navbar_color: string = 'navbar_home';
    brand: string = 'display:block';
    on: boolean = false;

    linkEntity: string; entityT: string;
    entityAct: boolean = true; entityCU: boolean = false;

    linkUser: string; userT: string;
    userAct: boolean = true; userCU: boolean = false;
    userFI: boolean = true; userP: boolean = true;

    linkInvestment: string; investmentT: string;
    investmentAct: boolean = true; investmentCU: boolean = false;

    linkInvestmentD: string; linkInvestmentDL: string; investmentDT: string;
    investmentDAct: boolean = true; investmentDCU: boolean = false;
    investmentDFI: boolean = true; investmentDL: boolean = true; investmentDD: boolean = true;

    linkProperty: string; propertyT: string;
    propertyAct: boolean = true; propertyCU: boolean = false;
    propertyFI: boolean = true; propertyP: boolean = true;

    linkPropertyD: string; linkPropertyDL: string; propertyDT: string;
    propertyDAct: boolean = true; propertyDFI: boolean = true; propertyDL: boolean = true; propertyDD: boolean = true;

    linkPropertyI: string; linkPropertyIL: string; propertyIT: string;
    propertyIAct: boolean = true; propertyICU: boolean = false;
    propertyIFI: boolean = true; propertyIL: boolean = true; propertyII: boolean = true;

    linkEntityU: string; linkEntityUL: string; entityUT: string;
    entityUAct: boolean = true; entityUFI: boolean = true; entityUL: boolean = true; entityUD: boolean = true;

    linkAccount: string; accountT: string;
    accountAct: boolean = true; accountCU: boolean = false;

    linkNew: string; newT: string;
    newAct: boolean = true; newU: boolean = false;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private translate: TranslateService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.url = event.url.match('/')['input'];
                this.classActive = event.url.match('/')['input'];
                this.active = true;

                if(this.classActive == '/home') {
                    this.space = false;
                    this.logo = 'logo_blanco.png';
                    this.navbar_color = 'navbar_home';
                    this.idLogo = 'navbarP';
                }
                else {
                    this.space = true;
                    this.logo = 'logo.png';
                    this.navbar_color = 'navbar_secundario';
                    this.idLogo = 'navbarS';
                }

                if(this.classActive.includes('/user/investment') || this.classActive.includes('/user/propertyDetail')) {
                    this.classActive = '/user/investment';
                    this.active = false;
                }

                if(this.classActive.includes('/investments') || this.classActive.includes('/propertyDetail')) {
                    this.classActive = '/investments';
                    this.active = false;
                }

                this.onChangeMenu();
            }
        });
    }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.onCheckUser();
    }

    setColor(color: string) {
        this.navbar_color = color;

        if(this.classActive == '/home') {
            if(color == 'navbar_home'){
                this.space = false;
                this.logo = 'logo_blanco.png';
            }
            else {
                this.space = true;
                this.logo = 'logo_negro.png';
            }
        }
        else {
            this.navbar_color = 'navbar_secundario';
            this.space = true;
            this.logo = 'logo.png';
        }
    }

    onCheckUser(): void {
        this.auth.isLoggedIn.subscribe(logged => { this.isLogged = logged; });
        this.auth.roleId.subscribe(role_id => { this.role_id = role_id; });
        this.auth.userId.subscribe(user_id => { this.id = user_id; });
        this.auth.investmentId.subscribe(investment_id => { this.getInvestments(1, investment_id); });
    }

    getInvestments(id: number, investment_id: string): void {
        if(!investment_id || investment_id == '0')
            this.dataApiService.getInvestmentaExpressSelect(1, this.auth.getLanguage()).subscribe(investments => { this.investments = investments; });
        else
            this.dataApiService.getInvestmentsExpress(investment_id).subscribe(investments => { this.investments = investments; });
    }

    onLogout() {
        this.auth.logoutUser();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("role_id");
        localStorage.removeItem("user_id");
        localStorage.removeItem("investment_id");
        this.auth.isLoggedIn.next(false);
        this.auth.roleId.next('0');
        this.router.navigate(['/home']);
    }

    onChangeLink(link: string, id: number = null): void {
        if(link == "/language" || link == "/user/investment" || link == "/investments") {
            this.classActive = link;
            this.active = false;
        }
        else {
            if(this.classActive == link)
                this.active = true;
        }
    }
    
    goInvestment(url, id) {
        this.classActive = "/user/investment";
        this.active = false;
        this.router.navigate([url, id]);
    }

    onChangeLanguage(language: string) {
        if(language) {
            this.auth.setLanguage(language);
            this.translate.setDefaultLang(language);
            environment.activeLang = language;

            this.dataApiService.changeDescription(language);
        }

        this.onChangeMenu();
    }

    onChangeMenu() {
        this.entityAct = true; this.entityCU = false;
        if(this.url.includes('/admin/entity') && !this.url.includes('/admin/entityUser')) {
            this.entityT = 'entity.create';
            if(this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.entityAct = false;
                this.entityCU = true;
                this.linkEntity = this.url;

                if(this.url.includes('update'))
                    this.entityT = 'entity.update';
            }
        }

        this.userAct = true; this.userCU = false; this.userFI = true; this.userP = true;
        if(this.url.includes('/admin/user') && !this.url.includes('/admin/entityUser')) {
            this.userT = 'user.create';
            if(this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.userAct = false;
                this.userCU = true;
                this.linkUser = this.url;

                if(this.url.includes('/admin/user/create/')) {
                    this.userFI = true;
                    this.userP = false;
                }
                else{
                    this.userFI = false;
                    this.userP = true;
                }

                if(this.url.includes('update'))
                    this.userT = 'user.update';
            }
        }

        this.entityUAct = true; this.entityUFI = false; this.entityUL = false; this.entityUD = false;
        if(this.url.includes('/admin/entityUser')) {
            this.entityUFI = false;

            this.entityUT = 'entityUser.list';
            if(this.url.includes('list') || this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.entityUAct = false;
                this.linkEntityU = this.url;
                this.linkEntityUL = this.url;
                this.entityUL = true;

                var res = this.url.split('/');

                if(this.url.includes('create')) {
                    this.entityUT = 'entityUser.create';
                    this.entityUD = true;
                    this.linkEntityUL = this.linkEntityUL.replace('create','list');
                }

                if(this.url.includes('update')) {
                    this.entityUT = 'entityUser.update';
                    this.entityUD = true;
                    this.linkEntityUL = res[0] + '/' + res[1] + '/' + res[2] + '/list/' + res[4];
                }
            }
        }

        this.investmentAct = true; this.investmentCU = false;
        if(this.url.includes('/admin/investment') && !this.url.includes('/admin/investmentDocument')) {
            this.investmentT = 'investment.create';
            if(this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.investmentAct = false;
                this.investmentCU = true;
                this.linkInvestment = this.url;

                if(this.url.includes('update'))
                    this.investmentT = 'investment.update';
            }
        }

        this.investmentDAct = true; this.investmentDCU = false;
        this.investmentDFI = false; this.investmentDL = false; this.investmentDD = false;
        if(this.url.includes('/admin/investmentDocument')) {
            this.investmentDFI = false;

            this.investmentDT = 'investmentDocument.list';
            if(this.url.includes('list') || this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.investmentDAct = false;
                this.investmentDCU = true;
                this.linkInvestmentD = this.url;
                this.linkInvestmentDL = this.url;
                this.investmentDL = true;

                var res = this.url.split('/');

                if(this.url.includes('create')) {
                    this.investmentDT = 'investmentDocument.create';
                    this.investmentDD = true;
                    this.linkInvestmentDL = this.linkInvestmentDL.replace('create','list');
                }

                if(this.url.includes('update')) {
                    this.investmentDT = 'investmentDocument.update';
                    this.investmentDD = true;
                    this.linkInvestmentDL = res[0] + '/' + res[1] + '/' + res[2] + '/list/' + res[4];
                }
            }
        }

        this.propertyAct = true; this.propertyCU = false; this.propertyFI = true; this.propertyP = true;
        if(this.url.includes('/admin/property') && !this.url.includes('/admin/propertyDocument') && !this.url.includes('/admin/propertyGallery')) {
            this.propertyT = 'property.create';
            if(this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.propertyAct = false;
                this.propertyCU = true;
                this.linkProperty = this.url;

                if(this.url.includes('/admin/property/create/')) {
                    this.propertyFI = true;
                    this.propertyP = false;
                }
                else{
                    this.propertyFI = false;
                    this.propertyP = true;
                }

                if(this.url.includes('update'))
                    this.propertyT = 'property.update';
            }
        }

        this.propertyDAct = true; this.propertyDFI = false; this.propertyDL = false; this.propertyDD = false;
        if(this.url.includes('/admin/propertyDocument')) {
            this.propertyDFI = false;

            this.propertyDT = 'propertyDocument.list';
            if(this.url.includes('list') || this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.propertyDAct = false;
                this.linkPropertyD = this.url;
                this.linkPropertyDL = this.url;
                this.propertyDL = true;

                var res = this.url.split('/');

                if(this.url.includes('create')) {
                    this.propertyDT = 'propertyDocument.create';
                    this.propertyDD = true;
                    this.linkPropertyDL = this.linkPropertyDL.replace('create','list');
                }

                if(this.url.includes('update')) {
                    this.propertyDT = 'propertyDocument.update';
                    this.propertyDD = true;
                    this.linkPropertyDL = res[0] + '/' + res[1] + '/' + res[2] + '/list/' + res[4];
                }
            }
        }

        this.propertyIAct = true; this.propertyICU = false;
        this.propertyIFI = false; this.propertyIL = false; this.propertyII = false;
        if(this.url.includes('/admin/propertyGallery')) {
            this.propertyIFI = false;

            this.propertyIT = 'propertyGallery.list';
            if(this.url.includes('list') || this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.propertyIAct = false;
                this.propertyICU = true;
                this.linkPropertyI = this.url;
                this.linkPropertyIL = this.url;
                this.propertyIL = true;

                var res = this.url.split('/');

                if(this.url.includes('create')) {
                    this.propertyIT = 'propertyGallery.create';
                    this.propertyII = true;
                    this.linkPropertyIL = this.linkPropertyIL.replace('create','list');
                }

                if(this.url.includes('update')) {
                    this.propertyIT = 'propertyGallery.update';
                    this.propertyII = true;
                    this.linkPropertyIL = res[0] + '/' + res[1] + '/' + res[2] + '/list/' + res[4];
                }
            }
        }

        this.accountAct = true; this.accountCU = false;
        if(this.url.includes('/admin/accountStatus')) {
            this.accountT = 'accountStatus.create';
            if(this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.accountAct = false;
                this.accountCU = true;
                this.linkAccount = this.url;

                if(this.url.includes('update'))
                    this.accountT = 'accountStatus.update';
            }
        }

        this.newAct = true; this.newU = false;
        if(this.url.includes('/admin/new')) {
            this.newT = 'new.create';
            if(this.url.includes('create') || this.url.includes('update')) {
                this.classActive = this.url;
                this.newAct = false;
                this.newU = true;
                this.linkNew = this.url;

                if(this.url.includes('update'))
                    this.newT = 'new.update';
            }
        }
    }
}
