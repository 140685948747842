import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rent-roll',
  templateUrl: './rent-roll.component.html',
  styleUrls: ['./rent-roll.component.scss']
})
export class RentRollComponent implements OnInit {

    property_type_id: number = 3;
    searchF: FormGroup;
    page: number = environment.page;
    url_base: string;
    id: number;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePaginationF: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

    headElements = ['id', 'name'];
    elements: any = [];
    previous: any = [];
    tableDataF: any = [];
    sorted = false;

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.url_base = environment.urlPropertyDocuments;
        this.page = environment.page;

        this.id = this.activatedRoute.snapshot.params['id'];
        this.searchF = this.utilityService.search;
        this.cleanFilter();
    }

    get formValue() {
        return this.searchF.controls;
    }

    getPropertyDocument() {
        this.dataApiService.getPropertyDocumentsExpressFilterPropertyType(this.id, this.property_type_id).subscribe(documents => { this.setData(documents) });
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.dataApiService.getPropertyDocumentsExpressFilterReport(this.id, this.property_type_id, '', event.target.id, event.target.value).subscribe(documents => { this.setData(documents) });
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlPropertyDocuments}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlPropertyDocuments}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    cleanFilter(): void {
        this.searchF.controls['pageF'].setValue(this.page);
        this.searchF.controls['name'].setValue('');
        this.getPropertyDocument();
    }

    setData(documents: any) {
        this.tableDataF = documents;
        this.mdbTable.setDataSource(documents);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
    }

    setPagination() {
        this.page = this.formValue.pageF.value;

        this.getPropertyDocument();
        this.ngAfterViewInit();

        this.searchF.controls['pageF'].setValue(this.page);
    }

    sortBy(by: string | any): void {
        this.tableDataF.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePaginationF.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePaginationF.calculateFirstItemIndex();
        this.mdbTablePaginationF.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
