import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notice-privacy',
  templateUrl: './notice-privacy.component.html',
  styleUrls: ['./notice-privacy.component.scss']
})
export class NoticePrivacyComponent implements AfterViewInit {

    constructor(private cd: ChangeDetectorRef, private route: Router, private auth: AuthService, private translate: TranslateService) { }

    ngAfterViewInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());

        this.cd.detectChanges();
    }
}
