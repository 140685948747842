import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { NewInterface } from '@app/models/new.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class NewListComponent implements OnInit {

    news: NewInterface;
    search: FormGroup;
    page: number = environment.page;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
    elements: any = [];
    previous: any = [];
    headElements = ['id', 'title'];
    tableData: any = [];
    sorted = false;

    title = "Noticias";
    action = "Crear Noticia";
    back  = "Inicio";

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.search = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['title'].setValue('');
        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getNews().subscribe(news => { this.setData(news, 0); this.spinnerService.hide(); });
    }

    onDelete(id: number): void {
        this.dataApiService.getNew(id).subscribe(
            data => {
                this.news = {
                    id: data[0]['id'],
                    title: data[0]['title'],
                    description: data[0]['description'],
                    source: data[0]['source'],
                    name_file: data[0]['name_file'],
                    date: data[0]['date'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteNew(this.news).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.dataApiService.getNewsFilter(event.target.id, event.target.value).subscribe(news => { this.setData(news, 0) });
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlNew}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlNew}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    setData(news: any, option: number) {
        if(option == 0) //0: Todos los usuarios - 1: Filtra por usario
            this.tableData = news;
        else
            this.tableData = [news];

        this.mdbTable.setDataSource(news);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
