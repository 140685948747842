import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';

import { environment } from '@environments/environment';
import { EntityInterface } from '@app/models/entity.interface';
import { RoleInterface } from '@app/models/role.interface';
import { EntityUserProfileInterface } from '@app/models/entityUserProfile.interface';

import { DataApiService } from '@app/services/data-api.service';
import { UtilityService } from '@app/services/utility.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/services/auth.service';
import { InvestmentInterface } from '@app/models/investment.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class EntityUserListComponent implements OnInit {

    entityUserProfiles: EntityUserProfileInterface;
    optionsSelectRoles: RoleInterface;
    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    search: FormGroup;
    page: number = environment.page;
    id: string;
    investment_id: string;
    entity_id: string;

    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
    headElements = ['id', 'investment_id', 'entity_id'];
    elements: any = [];
    previous: any = [];
    tableData: any = [];
    sorted = false;

    constructor(private route: Router, private activatedRoute: ActivatedRoute, private translate: TranslateService, private auth: AuthService, private dataApiService: DataApiService, private utilityService: UtilityService, private messageService: MessageService, private location: Location, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.id = this.activatedRoute.snapshot.params['id'];
        this.search = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.search.controls;
    }

    cleanFilter(): void {
        this.search.controls['id'].setValue('');
        this.search.controls['user_id'].setValue('');
        this.search.controls['entity_id'].setValue('');

        this.getList();
    }

    getList(): void {
        this.spinnerService.show();
        this.dataApiService.getEntityUserProfilesExpress(this.id).subscribe(entityUserProfiles => {
            this.setData(entityUserProfiles);
            this.getSelectInvestment();
            this.getSelectEntity();
            this.spinnerService.hide(); 
        });
    }

    getSelectInvestment() {
        this.dataApiService.getInvestmentaExpressSelectEntity(this.id).subscribe(investments => { this.optionsSelectInvestments = investments; });
    }

    getSelectEntity() {
        this.dataApiService.getEntityUserProfilesExpressSelect(this.id).subscribe(entities => { this.optionsSelectEntities = entities });
    }

    onDelete(id: string): void {
        this.dataApiService.getEntityUserProfile(id).subscribe(
            data => {
                this.entityUserProfiles = {
                    id: data['id'],
                    user_id: data['user_id'],
                    entity_id: data['entity_id'],
                    status: 0
                };

                this.translate.get('all.delete_confirm').subscribe((text:string) => {
                    if (confirm(text)) {
                        this.dataApiService.deleteEntityUserProfiles(this.entityUserProfiles).subscribe( data => {
                            this.getList();
                            this.translate.get('all.delete_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                        });
                    }
                });
            }
        );
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_id = this.formValue.investment_id.value;
        this.search.controls['investment_id'].setValue(this.investment_id);

        this.entity_id = this.formValue.entity_id.value;
        this.search.controls['entity_id'].setValue(this.entity_id);

        this.dataApiService.getEntityUsersProfileExpressFilter(this.id, this.investment_id, this.entity_id).subscribe(users => {
            this.setData(users);
        });
    }

    setData(users: any) {
        this.tableData = users;
        this.mdbTable.setDataSource(users);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();

        this.search.controls['page'].setValue(this.page);
    }

    setPagination() {
        environment.page = this.formValue.page.value;
        this.page = this.formValue.page.value;

        this.getList();
        this.ngAfterViewInit();
    }

    sortBy(by: string | any): void {
        this.tableData.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
