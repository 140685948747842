<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><i class="fas fa-briefcase"></i> {{'accountStatus.update' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="accountStatus" (ngSubmit)="onUpdate(accountStatus)" class="text-center" encType="multipart/form-data">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <input id="name" formControlName="name" type="hidden">

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <mdb-select id="user_id" formControlName="user_id" [options]="optionsSelectUsers" placeholder="{{'accountStatus.select_user' | translate}}" (selected)="viewInvestment()" required autocomplete="off"></mdb-select>
                                <label for="user_id">* {{'accountStatus.user_id' | translate}}</label>
                                <div *ngIf="formValue.user_id.invalid && (formValue.user_id.dirty || formValue.user_id.touched)" class="error">
                                    <div *ngIf="formValue.user_id.errors.required">
                                        {{'accountStatus.user_id' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.user_id.invalid && formValue.user_id.errors.pattern && (formValue.user_id.dirty || formValue.user_id.touched)">
                                        T{{'accountStatus.user_id' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="investment && optionsSelectInvestments">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="investment_id" formControlName="investment_id" [options]="optionsSelectInvestments" placeholder="{{'accountStatus.select_investment' | translate}}" (selected)="viewEntity($event)" required autocomplete="off"></mdb-select>
                                <label for="investment_id">* {{'accountStatus.investment_id' | translate}}</label>
                                <div *ngIf="formValue.investment_id.invalid && (formValue.investment_id.dirty || formValue.investment_id.touched)" class="error">
                                    <div *ngIf="formValue.investment_id.errors.required">
                                        {{'accountStatus.investment_id' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.investment_id.invalid && formValue.investment_id.errors.pattern && (formValue.investment_id.dirty || formValue.investment_id.touched)">
                                        {{'accountStatus.investment_id' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="entity && optionsSelectEntities">
                        <div class="col-xs-6 col-sm-6 col-md-12">
                            <div class="md-form">
                                <mdb-select id="entity_id" formControlName="entity_id" [options]="optionsSelectEntities" placeholder="{{'user.select_entity' | translate}}" (selected)="disabledButton()" required autocomplete="off"></mdb-select>
                                <label for="entity_id">* {{'user.entity' | translate}}</label>
                                <div *ngIf="formValue.entity_id.invalid && (formValue.entity_id.dirty || formValue.entity_id.touched)" class="error">
                                    <div *ngIf="formValue.entity_id.errors.required">
                                        {{'user.entity' | translate}} {{'all.it_is_required' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.entity_id.invalid && formValue.entity_id.errors.pattern && (formValue.entity_id.dirty || formValue.entity_id.touched)">
                                        {{'user.entity' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!entity">
                        <input id="entity_id" formControlName="entity_id" type="hidden">
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="file-field md-form md-form-margin">
                                <div mdbBtn floating="true" size="md" color="amber" mdbWavesEffect>
                                    <mdb-icon fas icon="cloud-upload-alt" aria-hidden="true"></mdb-icon>
                                    <input name="sampleFile" type="file" [disabled]="disabled" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                                </div>
                                <div class="file-path-wrapper mt-3">
                                    <input id="name_file" formControlName="name_file" class="file-path" type="text" placeholder="{{'all.upload' | translate}}" [value]="showFiles()" readonly="readonly">
                                    <div *ngIf="formValue.name_file.invalid && (formValue.name_file.dirty || formValue.name_file.touched)" class="error">
                                        <div *ngIf="formValue.name_file.errors.required">
                                            PDF {{'all.required_fields' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button [routerLink]="['/admin/accountStatus/list']" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!accountStatus.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.update' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
