<section id="profile" class="spaceComponent">
    <mdb-card cascade="true" narrower="true">
        <form autocomplete="off" [formGroup]="searchA">
            <div class="view view-cascade gradient-card-header colorBPP narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <button mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
                        <mdb-icon fas icon="briefcase" class="mt-0"></mdb-icon>
                    </button>
                </div>

                <a href="" class="white-text mx-3">{{'user.accountStatus.title' | translate}}</a>

                <div></div>
            </div>

            <div class="px-4">
                <div class="table-responsive">
                    <table class="mb-0" mdbTable #tableEl="mdbTable" class="z-depth-1" hover="true">
                        <thead>
                            <tr>
                                <th class="th-lg" style="width:40%">
                                    <a (click)="sortBy('name')">{{'accountStatus.namePDF' | translate}}<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th class="th-lg" style="width:20%">
                                    <a (click)="sortBy('investment_id')">{{'accountStatus.investment_id' | translate}}<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th class="th-lg" style="width:20%">
                                    <a (click)="sortBy('entity_id')">{{'accountStatus.entity_id' | translate}}<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th style="width:20%"></th>
                            </tr>
                        </thead>

                        <tbody *ngIf="tableDataA.length && optionsSelectInvestments && optionsSelectEntities">
                            <tr>
                                <td>
                                    <input type='search' id="name" formControlName="name" class="form-control form-control-sm" (keyup)="searchFilter($event)" (click)="onClick($event)">
                                </td>
                                <td>
                                    <select id="investment_id" formControlName="investment_id" aria-controls="dtBasicExample" class="form-control form-control-sm" (change)="onClick($event)">
                                        <option *ngFor="let obj of optionsSelectInvestments" value="{{obj.value}}">
                                            {{obj.label}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select id="entity_id" formControlName="entity_id" aria-controls="dtBasicExample" class="form-control form-control-sm" (change)="onClick($event)">
                                        <option value="">{{'user.investmentAllE' | translate}}</option>
                                        <option *ngFor="let obj of optionsSelectEntities" value="{{obj.value}}">
                                            {{obj.label}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <div class="dataTables_length bs-select" id="dtBasicExample_length">
                                        <select id="pageA" formControlName="pageA" aria-controls="dtBasicExample" class="form-control form-control-sm" (change)="setPagination()">
                                            <option value="20">{{'all.Showing_20' | translate}}</option>
                                            <option value="50">{{'all.Showing_50' | translate}}</option>
                                            <option value="100">{{'all.Showing_100' | translate}}</option>
                                            <option value="9999999999">{{'all.Showing_all' | translate}}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngFor="let row of tableDataA; let i = index">
                                <td *ngIf="i+1 >= mdbTablePaginationA.firstItemIndex && i < mdbTablePaginationA.lastItemIndex">{{row.name}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationA.firstItemIndex && i < mdbTablePaginationA.lastItemIndex">{{row.nameI}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationA.firstItemIndex && i < mdbTablePaginationA.lastItemIndex">{{row.nameE}}</td>
                                <td *ngIf="i+1 >= mdbTablePaginationA.firstItemIndex && i < mdbTablePaginationA.lastItemIndex">
                                    <button (click)="openDoc(row.name_file)" mdbBtn outline="true" rounded="true" size="sm" type="button" class="colorCPP px-2" mdbWavesEffect>
                                        <mdb-icon fas icon="file-pdf" class="mt-0"></mdb-icon> {{'all.view' | translate}}
                                    </button>
                                    <button (click)="onDownload(row.name_file)" mdbBtn outline="true" rounded="true" size="sm" type="button" class="colorCPP px-2" mdbWavesEffect>
                                        <mdb-icon fas icon="file-download" class="mt-0"></mdb-icon> {{'all.download' | translate}}
                                    </button>
                                </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="!tableDataA.length">
                            <tr>
                                <td colspan="4">
                                    {{'all.no_results_found' | translate}}
                                    <button (click)='cleanFilter()' mdbBtn class="colorCPP" rounded="true" size="sm" mdbWavesEffect>{{'all.clean' | translate}}</button>
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td colspan="4">
                                    <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="tableDataA"></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </form>
    </mdb-card>
</section>
