<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><i class="fas fa-briefcase"></i> {{'property.create' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="property" (ngSubmit)="onCreate(property)" class="text-center">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <input id="id" formControlName="id" type="hidden">

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="name"  formControlName="name" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="name">* {{'property.name' | translate}}</label>
                                <div *ngIf="formValue.name.invalid && (formValue.name.dirty || formValue.name.touched)" class="error">
                                    <div *ngIf="formValue.name.errors.required">
                                        {{'property.name' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.name.invalid && formValue.name.errors.pattern && (formValue.name.dirty || formValue.name.touched)">
                                        {{'property.name' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="address"  formControlName="address" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="address">* {{'property.address' | translate}}</label>
                                <div *ngIf="formValue.address.invalid && (formValue.address.dirty || formValue.address.touched)" class="error">
                                    <div *ngIf="formValue.address.errors.required">
                                        {{'property.address' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.address.invalid && formValue.address.errors.pattern && (formValue.address.dirty || formValue.address.touched)">
                                        {{'property.address' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="md-form">
                                <input id="lessee"  formControlName="lessee" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="lessee">* {{'property.lessee' | translate}}</label>
                                <div *ngIf="formValue.lessee.invalid && (formValue.lessee.dirty || formValue.lessee.touched)" class="error">
                                    <div *ngIf="formValue.lessee.errors.required">
                                        {{'property.lessee' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.lessee.invalid && formValue.lessee.errors.pattern && (formValue.lessee.dirty || formValue.lessee.touched)">
                                        {{'property.lessee' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="md-form">
                                <input id="m2"  formControlName="m2" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="m2">* {{'property.m2' | translate}}</label>
                                <div *ngIf="formValue.m2.invalid && (formValue.m2.dirty || formValue.m2.touched)" class="error">
                                    <div *ngIf="formValue.m2.errors.required">
                                        {{'property.m2' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.m2.invalid && formValue.m2.errors.pattern && (formValue.m2.dirty || formValue.m2.touched)">
                                        {{'property.m2' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="averege_term"  formControlName="averege_term" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="averege_term">* {{'property.averege_term' | translate}}</label>
                                <div *ngIf="formValue.averege_term.invalid && (formValue.averege_term.dirty || formValue.averege_term.touched)" class="error">
                                    <div *ngIf="formValue.averege_term.errors.required">
                                        {{'property.averege_term' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.averege_term.invalid && formValue.averege_term.errors.pattern && (formValue.averege_term.dirty || formValue.averege_term.touched)">
                                        {{'property.averege_term' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="averege_term_eng"  formControlName="averege_term_eng" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="averege_term_eng">* {{'property.averege_term' | translate}}</label>
                                <div *ngIf="formValue.averege_term_eng.invalid && (formValue.averege_term_eng.dirty || formValue.averege_term_eng.touched)" class="error">
                                    <div *ngIf="formValue.averege_term_eng.errors.required">
                                        {{'property.averege_term_eng' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.averege_term_eng.invalid && formValue.averege_term_eng.errors.pattern && (formValue.averege_term_eng.dirty || formValue.averege_term_eng.touched)">
                                        {{'property.averege_term_eng' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="md-form">
                                <input id="market"  formControlName="market" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="market">* {{'property.market' | translate}}</label>
                                <div *ngIf="formValue.market.invalid && (formValue.market.dirty || formValue.market.touched)" class="error">
                                    <div *ngIf="formValue.market.errors.required">
                                        {{'property.market' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.market.invalid && formValue.market.errors.pattern && (formValue.market.dirty || formValue.market.touched)">
                                        {{'property.market' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="md-form">
                                <input id="year"  formControlName="year" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="year">* {{'property.year' | translate}}</label>
                                <div *ngIf="formValue.year.invalid && (formValue.year.dirty || formValue.year.touched)" class="error">
                                    <div *ngIf="formValue.year.errors.required">
                                        {{'property.year' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.year.invalid && formValue.year.errors.pattern && (formValue.year.dirty || formValue.year.touched)">
                                        {{'property.year' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="md-form">
                                <input id="latitude" formControlName="latitude" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="latitude"> {{'property.latitude' | translate}}</label>
                                <div *ngIf="formValue.latitude.invalid && (formValue.latitude.dirty || formValue.latitude.touched)" class="error">
                                    <div *ngIf="formValue.latitude.errors.required">
                                        {{'property.latitude' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.latitude.invalid && formValue.latitude.errors.pattern && (formValue.latitude.dirty || formValue.latitude.touched)">
                                        {{'property.latitude' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="md-form">
                                <input id="longitude" formControlName="longitude" type="text" class="form-control" mdbInput autofocus autocomplete="off">
                                <label for="longitude"> {{'property.longitude' | translate}}</label>
                                <div *ngIf="formValue.longitude.invalid && (formValue.longitude.dirty || formValue.longitude.touched)" class="error">
                                    <div *ngIf="formValue.longitude.errors.required">
                                        {{'property.longitude' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.longitude.invalid && formValue.longitude.errors.pattern && (formValue.longitude.dirty || formValue.longitude.touched)">
                                        {{'property.longitude' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="description" formControlName="description" type="text" class="form-control" mdbInput autofocus autocomplete="off">
                                <label for="description">* {{'property.description' | translate}}</label>
                                <div *ngIf="formValue.description.invalid && (formValue.description.dirty || formValue.description.touched)" class="error">
                                    <div *ngIf="formValue.description.errors.required">
                                        {{'property.description' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.description.invalid && formValue.description.errors.pattern && (formValue.description.dirty || formValue.description.touched)">
                                        {{'property.description' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="description_eng" formControlName="description_eng" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="description_eng">* {{'property.description_eng' | translate}}</label>
                                <div *ngIf="formValue.description_eng.invalid && (formValue.description_eng.dirty || formValue.description_eng.touched)" class="error">
                                    <div *ngIf="formValue.description_eng.errors.required">
                                        {{'property.description_eng' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.description_eng.invalid && formValue.description_eng.errors.pattern && (formValue.description_eng.dirty || formValue.description_eng.touched)">
                                        {{'property.description_eng' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-5">
                        <div class="col-12">
                            <div style="float: left;">
                                <mdb-checkbox [checked]="true" [default]="true" formControlName="sold">{{'property.sold' | translate}}</mdb-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="!id" class="md-form">
                                <mdb-select id="investment_id" formControlName="investment_id" [options]="optionsSelectInvestments" placeholder="{{'property.select_investment' | translate}}" required autocomplete="off"></mdb-select>
                                <label for="investment_id">* {{'property.investment' | translate}}</label>
                                <div *ngIf="formValue.investment_id.invalid && (formValue.investment_id.dirty || formValue.investment_id.touched)" class="error">
                                    <div *ngIf="formValue.investment_id.errors.required">
                                        {{'property.investment' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.investment_id.invalid && formValue.investment_id.errors.pattern && (formValue.investment_id.dirty || formValue.investment_id.touched)">
                                        {{'property.investment' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="id" class="md-form">
                                <input id="investment_id"  formControlName="investment_id" type="hidden" >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="file-field md-form md-form-margin">
                                <div mdbBtn floating="true" size="md" color="amber" mdbWavesEffect>
                                    <mdb-icon fas icon="cloud-upload-alt" aria-hidden="true"></mdb-icon>
                                    <input name="sampleFile" type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                                </div>
                                <div class="file-path-wrapper mt-3">
                                    <input id="name_file" formControlName="name_file" class="file-path" type="text" placeholder="{{'all.uploadImage' | translate}}" [value]="showFiles()" readonly="readonly">
                                    <div *ngIf="formValue.name_file.invalid && (formValue.name_file.dirty || formValue.name_file.touched)" class="error">
                                        <div *ngIf="formValue.name_file.errors.required">
                                            {{'property.image' | translate}} {{'all.required_fields' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button [routerLink]="['/admin/property/list']" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!property.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.create' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
