<div class="headerContainerP">
    <h2 class="headerTitle" *ngIf="investment">{{investment[0].name}}</h2><br>
</div>

<section id="properties">
    <div class="container" *ngIf="properties">
        <mdb-tabset #staticTabs [buttonClass]="'nav md-pills mb-2 mt-5'" [justified]="true" [contentClass]="'card'">
            <mdb-tab heading="{{'user.investment.porperties' | translate}}">
                <div class="row">
                    <div class="col-12">
                        <section class="my-2" *ngIf="properties.length != 0">
                            <div *ngFor="let item of properties; let i = index">
                                <div *ngIf="i % 2 == 0">
                                    <div class="row" data-aos="fade-right">
                                        <div class="col-lg-5">
                                            <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                                                <a mdbBtn mdbWavesEffect style="padding: 0px">
                                                    <div *ngIf="!item.sold">
                                                        <img class="rounded img-fluid" style="min-height:300px" src="../../../../assets/uploads/property/principal/{{item.name_file}}">
                                                        <div class="mask flex-center text-center waves-effect waves-light">
                                                            <p class="white-text" style="font-size:20px;font-weight:600">{{item.name}}<br>{{item.address}}</p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.sold">
                                                        <img class="rounded img-fluid z-depth-1" style="min-height:300px" src="../../../../assets/uploads/property/principal/{{item.name_file}}">
                                                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                                                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                                                            <p class="white-text col-md-12 textTitleI">{{item.name}}<br>{{item.address}}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="col-lg-7">
                                            <h3 class="font-weight-bold mb-3"><strong>{{item.name}}, {{item.market}}</strong></h3>

                                            <p style="text-align:justify">
                                                {{item.description}}
                                            </p>

                                            <div class="row">
                                                <div class="col-5">
                                                    <p>
                                                        <span class="colorP"><mdb-icon fas icon="home" class="animated pulse infinite"></mdb-icon>&nbsp;Propiedad</span><br>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{item.name}}</span>
                                                    </p>
                                                </div>

                                                <div class="col-7">
                                                    <p>
                                                        <span class="colorP"><mdb-icon fas icon="map-marker-alt" class="animated pulse infinite"></mdb-icon>&nbsp;Dirección</span><br>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{item.address}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <a mdbBtn class="colorBPP text-white" [routerLink]="['/user/propertyDetail', id, item.id]" mdbWavesEffect>Ver ficha</a>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="i % 2 != 0">
                                    <div class="row" data-aos="fade-left">
                                        <div class="col-lg-7">
                                            <h3 class="font-weight-bold mb-3"><strong>{{item.name}}, {{item.market}}</strong></h3>

                                            <p style="text-align:justify">
                                                {{item.description}}
                                            </p>

                                            <div class="row" style="text-align: right;">
                                                <div class="col-5">
                                                    <p>
                                                        <span class="colorP"><mdb-icon fas icon="home" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.property' | translate}}</span><br>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{item.name}}</span>
                                                    </p>
                                                </div>

                                                <div class="col-7">
                                                    <p>
                                                        <span class="colorP"><mdb-icon fas icon="map-marker-alt" class="animated pulse infinite"></mdb-icon>&nbsp;{{'propertyDetail.address' | translate}}</span><br>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:300">{{item.address}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <p style="text-align:right">
                                                <a mdbBtn class="colorBPP2 text-white" [routerLink]="['/user/propertyDetail', id, item.id]" mdbWavesEffect>{{'propertyDetail.view' | translate}}</a>
                                            </p>
                                        </div>

                                        <div class="col-lg-5">
                                            <div class="view rounded zoom z-depth-2 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                                                <a mdbBtn mdbWavesEffect style="padding: 0px">
                                                    <div *ngIf="!item.sold">
                                                        <img class="rounded img-fluid" style="min-height:300px" src="../../../../assets/uploads/property/principal/{{item.name_file}}">
                                                        <div class="mask flex-center text-center waves-effect waves-light">
                                                            <p class="white-text" style="font-size:20px;font-weight:600">{{item.name}}<br>{{item.address}}</p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.sold">
                                                        <img class="rounded img-fluid z-depth-1" style="min-height:300px" src="../../../../assets/uploads/property/principal/{{item.name_file}}">
                                                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                                                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                                                            <p class="white-text col-md-12 textTitleI">{{item.name}}<br>{{item.address}}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr class="my-5">
                            </div>
                        </section>
                        <section class="my-2" *ngIf="properties.length === 0">
                            <p>
                                <mdb-icon fas icon="exclamation-triangle" size="3x" class="red-text pr-1"></mdb-icon>
                                No se encontraron resultados
                            </p>
                        </section>
                    </div>
                </div>
            </mdb-tab>

            <mdb-tab heading="{{'user.investment.technical' | translate}}">
                <app-investment-technical></app-investment-technical>
            </mdb-tab>

            <mdb-tab heading="{{'user.investment.reports' | translate}}">
                <app-investment-reports></app-investment-reports>
            </mdb-tab>

            <mdb-tab heading="{{'user.investment.escrow' | translate}}">
                <app-escrow></app-escrow>
            </mdb-tab>

            <mdb-tab heading="{{'user.investment.assembly' | translate}}">
                <app-assembly></app-assembly>
            </mdb-tab>
        </mdb-tabset>
    </div>
</section>
