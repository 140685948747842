<div class="headerContainer">
    <h2 class="headerTitle">{{'investment.title' | translate}}</h2>
</div>

<section id="descripcion">
    <div class="container">
        <br><br>

        <p class="text-justify" *ngIf="id == 1">
            {{'property.description_1' | translate}}
        </p>

        <p class="text-justify" *ngIf="id == 2">
            {{'property.description_2' | translate}}
        </p>

        <h3 class="text-center dark-grey-text font-weight-bold mb-5 pt-5 wow fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
            <strong>{{'property.title' | translate}}</strong>
        </h3>
    </div>
</section>

<section id="galeria" *ngIf="id == 1">
    <div class="container" *ngIf="properties.length != 0">
        <div class="row mb-4" data-aos="zoom-left" data-aos-delay="100">
            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[0].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[0].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[0].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[0].name}}<br>{{properties[0].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[0].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[0].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[0].name}}<br>{{properties[0].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[1].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[1].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[1].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[1].name}}<br>{{properties[1].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[1].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[1].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[1].name}}<br>{{properties[1].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[2].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[2].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[2].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[2].name}}<br>{{properties[2].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[2].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[2].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[2].name}}<br>{{properties[2].address}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="row mb-4" data-aos="fade-right">
            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[3].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[3].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[3].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[3].name}}<br>{{properties[3].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[3].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[3].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[3].name}}<br>{{properties[3].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[4].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[4].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[4].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[4].name}}<br>{{properties[4].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[4].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[4].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[4].name}}<br>{{properties[4].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[5].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[5].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[5].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[5].name}}<br>{{properties[5].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[5].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[5].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[5].name}}<br>{{properties[5].address}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="row mb-4" data-aos="fade-left">
            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[6].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[6].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[6].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[6].name}}<br>{{properties[6].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[6].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[6].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[6].name}}<br>{{properties[6].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[7].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[7].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[7].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[7].name}}<br>{{properties[7].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[7].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[7].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[7].name}}<br>{{properties[7].address}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="galeria" *ngIf="id == 2">
    <div class="container" *ngIf="properties.length != 0">
        <div class="row mb-4" data-aos="zoom-left" data-aos-delay="100">
            <div class="col-md-6">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[0].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[0].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[0].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[0].name}}<br>{{properties[0].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[0].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[0].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[0].name}}<br>{{properties[0].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-6">
                <div class="view rounded zoom z-depth-1 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[1].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[1].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[1].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[1].name}}<br>{{properties[1].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[1].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[1].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[1].name}}<br>{{properties[1].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-6">
                <div class="view rounded zoom z-depth-1 mt-4 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[2].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[2].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[2].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[2].name}}<br>{{properties[2].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[2].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[2].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[2].name}}<br>{{properties[2].address}}</p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-6">
                <div class="view rounded zoom z-depth-1 mt-4 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
                    <a *ngIf="!properties[3].sold" mdbBtn [routerLink]="['/propertyDetail', id, properties[3].id]" mdbWavesEffect>
                        <img class="rounded img-fluid" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[3].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light">
                            <p class="white-text" style="font-size:20px;font-weight:600">{{properties[3].name}}<br>{{properties[3].address}}</p>
                        </div>
                    </a>
                    <a *ngIf="properties[3].sold" mdbBtn mdbWavesEffect>
                        <img class="rounded img-fluid z-depth-1" style="min-height:250px; padding:0px" src="../../../../assets/uploads/property/principal/{{properties[3].name_file}}">
                        <div class="mask flex-center text-center waves-effect waves-light rowSM">
                            <div class="textSold"><p class="animated pulse">{{'property.sold.title' | translate}}</p></div>
                            <p class="white-text col-md-12 textTitleI">{{properties[3].name}}<br>{{properties[3].address}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="historial" *ngIf="id == 1">
    <div class="container-fluid rgba-grey-slight my-5">
        <h3 class="text-center dark-grey-text font-weight-bold mb-4 pt-5 wow fadeIn">
            <strong>{{'investment.history' | translate}}</strong>
        </h3>

        <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="timeline-main">
                    <ul class="stepper stepper-vertical timeline timeline-simple pl-0">
                        <li class="timeline-normal">
                            <a href="#"><span class="circle grey" data-aos="flip-left" data-aos-duration="10000"></span></a>

                            <div class="timelineD step-content z-depth-1 ml-3 p-4 hoverable" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="10000">
                                <h4 class="text-white font-weight-bold">2015</h4>
                                <p class="text-muted mt-3">
                                    <strong class="dark-grey-text font-weight-bold">Pinnacle Park - AT&T</strong><span class="text-white"> 6.75 yrs</span><br>
                                    <strong class="dark-grey-text font-weight-bold">Royal Tech - Michaels</strong><span class="text-white"> 9.5 yrs</span>
                                </p>
                            </div>
                        </li>

                        <li class="timeline-inverted">
                            <a href="#"><span class="circle grey" data-aos="flip-left" data-aos-duration="10000"></span></a>

                            <div class="timelineDI step-content z-depth-1 mr-xl-3 p-4 hoverable" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="10000">
                                <h4 class="font-weight-bold">2016</h4>
                                <p class="text-muted mt-3">
                                    <strong class="dark-grey-text font-weight-bold">Intellicenter - Morgan Stanley</strong> 5.21 yrs<br>
                                    <strong class="dark-grey-text font-weight-bold">Dominion - Home Depot</strong> 10 yrs<br>
                                    <strong class="dark-grey-text font-weight-bold">Lakeside II - TIAA</strong> 7.6 yrs
                                </p>
                            </div>
                        </li>

                        <li class="timeline-normal">
                            <a href="#"><span class="circle grey" data-aos="flip-left" data-aos-duration="10000"></span></a>

                            <div class="timelineD step-content z-depth-1 ml-3 p-4 hoverable" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="10000">
                                <h4 class="text-white font-weight-bold">2017</h4>
                                <p class="text-muted mt-3">
                                    <strong class="dark-grey-text font-weight-bold">Portfolio Refi BOT</strong><span class="text-white"> $2.8 MD cash out</span><br>
                                    <strong class="dark-grey-text font-weight-bold">Campbell Commons - Qorvo</strong><span class="text-white"> 6.8 yrs</span>
                                </p>
                            </div>
                        </li>

                        <li class="timeline-inverted">
                            <a href="#"><span class="circle grey" data-aos="flip-left" data-aos-duration="10000"></span></a>

                            <div class="timelineDI step-content z-depth-1 mr-xl-3 p-4 hoverable" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="10000">
                                <h4 class="font-weight-bold">2018</h4>
                                <p class="text-muted mt-3">
                                    <strong class="dark-grey-text font-weight-bold">Portfolio Refi BOFA</strong> $123.7 MD - $3.9 MD cash out<br>
                                    <strong class="dark-grey-text font-weight-bold">501 Tech Dr. - Primentals</strong> 5.5 yrs<br>
                                    <strong class="dark-grey-text font-weight-bold">5775 DTC - First Data</strong> 5.5 yrs<br>
                                    <strong class="dark-grey-text font-weight-bold">501 Tech Dr. - Primentals</strong> 5.6 yrs<br>
                                    <strong class="dark-grey-text font-weight-bold">Campbell Commons (SOLD)</strong> 26.3 MD (10-31 Ex)<br>
                                    <strong class="dark-grey-text font-weight-bold">Westway Plaza - GE Power</strong> 8.2 yrs - 2.4 MD amort. debt
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>

<section id="historial" *ngIf="id == 2">
    <div class="container-fluid rgba-grey-slight my-5">
        <h3 class="text-center dark-grey-text font-weight-bold mb-4 pt-5 wow fadeIn">
            <strong>{{'investment.history' | translate}}</strong>
        </h3>

        <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="timeline-main">
                    <ul class="stepper stepper-vertical timeline timeline-simple pl-0">
                        <li class="timeline-normal">
                            <a href="#"><span class="circle grey" data-aos="flip-left" data-aos-duration="10000"></span></a>

                            <div class="timelineD step-content z-depth-1 ml-3 p-4 hoverable" data-aos="fade-left" data-aos-duration="10000">
                                <h4 class="text-white font-weight-bold">2019</h4>
                                <p class="text-muted mt-3">
                                    <strong class="dark-grey-text font-weight-bold">Windward Oaks I & II.</strong><span class="text-white"> Adquisición</span><br>
                                    <strong class="dark-grey-text font-weight-bold">Lake Vista III & IV</strong><span class="text-white"> Adquisición</span>
                                </p>
                            </div>
                        </li>

                        <li class="timeline-inverted">
                            <a href="#"><span class="circle grey" data-aos="flip-left" data-aos-duration="10000"></span></a>

                            <div class="timelineDI step-content z-depth-1 mr-xl-3 p-4 hoverable" data-aos="fade-right" data-aos-duration="10000">
                                <h4 class="font-weight-bold">2021</h4>
                                <p class="text-muted mt-3">
                                    <strong class="dark-grey-text font-weight-bold">OME 2001 Mark Center</strong> 20 de Enero. Adquisición<br>
                                    <strong class="dark-grey-text font-weight-bold">OME Bowie Corporate Center</strong> 28 de Enero. Adquisición
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>

<section id="ubicaciones" *ngIf="id == 1">
    <div class="container">
        <h3 class="text-center dark-grey-text font-weight-bold mb-5 pt-4 wow fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
            <strong>{{'investment.ubication' | translate}}</strong>
        </h3>

        <div class="row mb-5">
            <div class="col-md-6">
                <div class="card card-body rgba-white-light hoverable p-4 mb-5" data-aos="zoom-in-left">
                    <agm-map class="z-depth-1-half map-container" style="height:405px" [latitude]="map.latitude" [longitude]="map.longitude" [zoom]="map.zoom" [disableDefaultUI]="false" [zoomControl]="false">
                        <agm-marker *ngFor="let marker of properties" [latitude]="marker.latitude" [longitude]="marker.longitude" [title]="marker.name" [markerDraggable]="true" (mouseOver)="window.open()" (mouseOut)="window.close()" (markerClick)="selectMarker($event)">
                            <agm-info-window #window>
                                <h6>{{marker.name}}</h6>
                            </agm-info-window>
                        </agm-marker>
                    </agm-map>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-body rgba-white-light hoverable p-4 mb-5" data-aos="zoom-in-right">
                    <div class="row" *ngFor="let marker of properties">
                        <div class="col-md-12 mb-4">
                            <span class="colorP"><mdb-icon fas icon="map-marker-alt"></mdb-icon></span>
                            <span class="black-text-2 spacing"><strong> {{ marker.name }}.</strong></span>
                            <span class="font-small dark-grey-text"> {{ marker.address }} </span>
                            <a (click)="selectMarker(marker)" class="colorP"><mdb-icon fas icon="search-location" class="animated pulse infinite"></mdb-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="ubicaciones" *ngIf="id == 2">
    <div class="container">
        <h3 class="text-center dark-grey-text font-weight-bold mb-5 pt-4 wow fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-name: fadeIn; animation-delay: 0.2s;">
            <strong>{{'investment.ubication' | translate}}</strong>
        </h3>

        <div class="row mb-5">
            <div class="col-md-6">
                <div class="card card-body rgba-white-light hoverable p-4 mb-5" data-aos="zoom-in-left">
                    <agm-map class="z-depth-1-half map-container" style="height:405px" [latitude]="map.latitude" [longitude]="map.longitude" [zoom]="map.zoom" [disableDefaultUI]="false" [zoomControl]="false">
                        <agm-marker *ngFor="let marker of properties" [latitude]="marker.latitude" [longitude]="marker.longitude" [title]="marker.name" [markerDraggable]="true" (mouseOver)="window.open()" (mouseOut)="window.close()" (markerClick)="selectMarker($event)">
                            <agm-info-window #window>
                                <h6>{{marker.name}}</h6>
                            </agm-info-window>
                        </agm-marker>
                    </agm-map>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-body rgba-white-light hoverable p-4 mb-5" data-aos="zoom-in-right">
                    <div class="row" *ngFor="let marker of properties">
                        <div class="col-md-12 mb-4">
                            <span class="colorP"><mdb-icon fas icon="map-marker-alt"></mdb-icon></span>
                            <span class="black-text-2 spacing"><strong> {{ marker.name }}.</strong></span>
                            <span class="font-small dark-grey-text"> {{ marker.address }} </span>
                            <a (click)="selectMarker(marker)" class="colorP"><mdb-icon fas icon="search-location" class="animated pulse infinite"></mdb-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>