<section class="spaceContainerAdmin">
    <div class="col-xs-12 col-sm-9 col-md-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="form-header colorBPP">
                    <h3><i class="fas fa-briefcase"></i> {{'new.update' | translate}}</h3>
                </div>

                <form autocomplete="off" [formGroup]="new" (ngSubmit)="onUpdate(new)" class="text-center">
                    <p>* {{'all.required_fields' | translate}}</p>

                    <input id="id" formControlName="id" type="hidden">

                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input id="title"  formControlName="title" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="title">* {{'new.name' | translate}}</label>
                                <div *ngIf="formValue.title.invalid && (formValue.title.dirty || formValue.title.touched)" class="error">
                                    <div *ngIf="formValue.title.errors.required">
                                        {{'new.name' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.title.invalid && formValue.title.errors.pattern && (formValue.title.dirty || formValue.title.touched)">
                                        {{'new.name' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea id="description" formControlName="description" type="text" class="md-textarea md-textarea-auto form-control" mdbInput required autofocus autocomplete="off"></textarea>
                                <label for="description">* {{'new.description' | translate}}</label>
                                <div *ngIf="formValue.description.invalid && (formValue.description.dirty || formValue.description.touched)" class="error">
                                    <div *ngIf="formValue.description.errors.required">
                                        {{'new.description' | translate}} {{'all.not_valid' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.description.invalid && formValue.description.errors.pattern && (formValue.description.dirty || formValue.description.touched)">
                                        {{'new.description' | translate}} {{'all.not_valid' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="md-form">
                                <input id="source" formControlName="source" type="text" class="form-control" mdbInput required autofocus autocomplete="off">
                                <label for="source">* {{'new.source' | translate}}</label>
                                <div *ngIf="formValue.source.invalid && (formValue.source.dirty || formValue.source.touched)" class="error">
                                    <div *ngIf="formValue.source.errors.required">
                                        {{'new.source' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.source.invalid && formValue.source.errors.pattern && (formValue.source.dirty || formValue.source.touched)">
                                        {{'new.source' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="md-form">
                                <mdb-date-picker id="date" formControlName="date" label="* {{'new.date' | translate}}" [options]="myDatePickerOptions" required></mdb-date-picker>
                                <div *ngIf="formValue.date.invalid && (formValue.date.dirty || formValue.date.touched)" class="error">
                                    <div *ngIf="formValue.date.errors.required">
                                        {{'new.date' | translate}} {{'all.required_fields' | translate}}
                                    </div>
                                    <div
                                        *ngIf="formValue.date.invalid && formValue.date.errors.pattern && (formValue.date.dirty || formValue.date.touched)">
                                        {{'new.date' | translate}} {{'all.not_valid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="file-field md-form md-form-margin">
                                <div mdbBtn floating="true" size="md" color="amber" mdbWavesEffect>
                                    <mdb-icon fas icon="cloud-upload-alt" aria-hidden="true"></mdb-icon>
                                    <input name="sampleFile" type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                                </div>
                                <div class="file-path-wrapper mt-3">
                                    <input id="name_file" formControlName="name_file" class="file-path" type="text" placeholder="{{'all.uploadImage' | translate}}" [value]="showFiles()" readonly="readonly">
                                    <div *ngIf="formValue.name_file.invalid && (formValue.name_file.dirty || formValue.name_file.touched)" class="error">
                                        <div *ngIf="formValue.name_file.errors.required">
                                            {{'new.image' | translate}} {{'all.required_fields' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p id="pPassword">{{'new.upload_text' | translate}}</p><br>
                        </div>
                    </div>

                    <button [routerLink]="['/admin/new/list']" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect><mdb-icon fas icon="angle-double-left"></mdb-icon> {{'all.back' | translate}}</button>
                    <button [disabled]="!new.valid" type="submit" mdbBtn class="text-white colorBPP" mdbWavesEffect>{{'all.update' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>
