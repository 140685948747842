import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';

import { environment } from '@environments/environment';
import { InvestmentInterface } from '@app/models/investment.interface';
import { EntityInterface } from '@app/models/entity.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-account-status',
    templateUrl: './account-status.component.html',
    styleUrls: ['./account-status.component.scss']
})
export class AccountStatusComponent implements OnInit {

    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    searchA: FormGroup;
    page: number = environment.page;
    user_id: string;
    investment_id: string;
    entity_id: string = '';
    url_base: string;
    
    @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePaginationA: MdbTablePaginationComponent;
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

    headElements = ['id', 'name', 'investment_id', 'entity_id'];
    elements: any = [];
    previous: any = [];
    tableDataA: any = [];
    sorted = false;

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {}

    ngOnInit(): void {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.url_base = environment.urlPropertyDocuments;
        this.page = environment.page;

        this.investment_id = String(this.auth.getCurrentUser().investment_id);
        this.user_id = String(this.auth.getCurrentUser().id);
        this.searchA = this.utilityService.search;

        this.cleanFilter();
    }

    get formValue() {
        return this.searchA.controls;
    }

    cleanFilter(): void {
        this.investment_id = String(this.auth.getCurrentUser().investment_id);
        this.user_id = String(this.auth.getCurrentUser().id);

        this.searchA.controls['pageA'].setValue(this.page);
        this.searchA.controls['name'].setValue('');
        this.searchA.controls['investment_id'].setValue(this.investment_id);
        this.searchA.controls['entity_id'].setValue(this.entity_id);
        
        this.getAccountStatusDocument();
    }

    getAccountStatusDocument() {
        this.spinnerService.show();
        this.dataApiService.getAccountStatusExpress(this.user_id, this.investment_id).subscribe(documents => { 
            this.setData(documents);
            this.getSelectInvestment(this.investment_id);
            this.getSelectEntity();
            this.spinnerService.hide();
        });
    }

    getSelectInvestment(investment_id: string) {
        if(investment_id == '0')
            this.dataApiService.getInvestmentaExpressSelect(0, this.auth.getLanguage()).subscribe(investments => { this.optionsSelectInvestments = investments; });
        else
            this.dataApiService.getInvestmentsExpress(investment_id).subscribe(investments => { this.optionsSelectInvestments = investments; });
    }

    getSelectEntity() {
        this.dataApiService.getEntitiesExpressSelectFiltersUser(this.user_id).subscribe(entities => { this.optionsSelectEntities = entities });
    }

    openDoc(pdfName: string) {
        const url_download = `${environment.urlAccountStatus}/${pdfName}`;
        window.open(url_download, '_blank', '', true);
    }

    onDownload(pdfName: string) {
        const url_download = `${environment.urlAccountStatus}/${pdfName}`;
        saveAs(url_download, pdfName);
    }

    onClick(event: any): void {
        event.target.value = '';
        this.searchFilter(event);
    }

    searchFilter(event: any): void {
        this.investment_id = this.formValue.investment_id.value;
        this.searchA.controls['investment_id'].setValue(this.investment_id);

        this.entity_id = this.formValue.entity_id.value;
        this.searchA.controls['entity_id'].setValue(this.entity_id);

        this.dataApiService.getAccountStatusExpressFilter(this.user_id, this.investment_id, this.entity_id, event.target.id, event.target.value).subscribe(accountStatus => {
            this.setData(accountStatus);
            if(event.target.id == 'investment_id' || event.target.id == 'entity_id') {
                this.searchA.controls['id'].setValue('');
                this.searchA.controls['user_id'].setValue('');
                this.searchA.controls['name'].setValue('');
            }
        });
    }

    setData(documents: any) {
        this.tableDataA = documents;
        this.mdbTable.setDataSource(documents);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
    }

    setPagination() {
        this.page = this.formValue.pageA.value;

        this.getAccountStatusDocument();
        this.ngAfterViewInit();

        this.searchA.controls['pageA'].setValue(this.page);
    }

    sortBy(by: string | any): void {
        this.tableDataA.sort((a: any, b: any) => {
            if (a[by] < b[by])
                return this.sorted ? 1 : -1;

            if (a[by] > b[by])
                return this.sorted ? -1 : 1;

            return 0;
        });

        this.sorted = !this.sorted;
    }

    ngAfterViewInit() {
        this.mdbTablePaginationA.setMaxVisibleItemsNumberTo(this.page);
        this.mdbTablePaginationA.calculateFirstItemIndex();
        this.mdbTablePaginationA.calculateLastItemIndex();
        this.cdRef.detectChanges();
    }
}
