import { Injectable } from '@angular/core';
import { ModalComponent } from './modal/modal.component';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {

    private _isMessage = new BehaviorSubject<string>('');
    public _isError: boolean = false;

    constructor() { }

    get isMessage() {
        return this._isMessage;
    }

	add(message: string): void {
        this._isError = false;
        this._isMessage.next(message);
	}

	addError(message: string): void  {
        if (message['status'] != '200') {
            if (message['status'] == '401' && message['statusText'] == 'Unauthorized')
                message = 'Las credenciales son incorrectas!';

            if (message['status'] == '0')
                message = 'No se esta ejecutando el servidor NodeJS!';

            if (message['status'] == '422')
                message = 'La contarseña es obligatoria!';
        }

        this._isError = true;
        this._isMessage.next(message);
	}
}
