import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from '@environments/environment';
import { PropertyInterface } from '@app/models/property.interface';
import { PropertyGalleryInterface } from '@app/models/propertyGallery.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { UtilityService } from '@app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss']
})
export class PropertyDetailComponent implements OnInit {

    property: PropertyInterface;
    gallery: PropertyGalleryInterface;
    imagesI: any = [];
    images_1: any = [];
    images_2: any = [];
    idp: number;
    id: number;
    description: string;
    description_eng: string;
    averege_term: string;
    averege_term_eng: string;

    @HostBinding('class.lngI') lngI = '';

    constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService, private spinnerService: NgxSpinnerService) {}

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());

        this.idp = this.activatedRoute.snapshot.params['idp'];
        this.id = this.activatedRoute.snapshot.params['id'];
        this.getProperty(this.id);

        this.dataApiService.change.subscribe(lngI => {
            this.lngI = lngI;
        });
    }

    getProperty(id: number) {
        this.spinnerService.show();
        this.dataApiService.getProperty(id).subscribe(property => {
            this.property = property[0];

            this.lngI = this.auth.getLanguage();
            this.description = this.property.description;
            this.description_eng = this.property.description_eng;
            this.averege_term = this.property.averege_term;
            this.averege_term_eng = this.property.averege_term_eng;

            this.getPropertyGallery(id);
            this.spinnerService.hide();
        });
    }

    getPropertyGallery(id: number) {
        this.dataApiService.getPropertyGalleriesExpressFilter(id, 'Galeria_1').subscribe(galleries => {
            let gal: any = galleries;
            let i = 0;
            let url = environment.urlPropertyGalleries + '/';
            gal.forEach(element => {
                this.imagesI[i] = {img: element.img, thumb: element.thumb, description: element.description};
                this.images_1[i] = {img: url + element.img, thumb: url + element.thumb, description: element.description};
                i++;
            });
        });

        this.dataApiService.getPropertyGalleriesExpressFilter(id, 'Galeria_2').subscribe(galleries => {
            let gal: any = galleries;
            let i = 0;
            let url = environment.urlPropertyGalleries + '/';
            gal.forEach(element => {
                this.images_2[i] = {img: url + element.img, thumb: url + element.thumb, description: element.description};
                i++;
            });
        });
    }
}
