import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { EntityInterface } from '@app/models/entity.interface';
import { InvestmentInterface } from 'src/app/models/investment.interface';
import { RoleInterface } from './../../../../models/role.interface';
import { UserInterface } from './../../../../models/user.interface';

import { AuthService } from '@app/services/auth.service';
import { DataApiService } from '@app/services/data-api.service';
import { MessageService } from '@app/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@app/services/utility.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class UserCreateComponent implements OnInit {

    user: FormGroup;
    users: UserInterface;
    optionsSelectRoles: RoleInterface;
    optionsSelectInvestments: InvestmentInterface;
    optionsSelectEntities: EntityInterface;
    entity: Boolean = false;

    constructor(private route: Router, private auth: AuthService, private dataApiService: DataApiService, private messageService: MessageService, private translate: TranslateService, private utilityService: UtilityService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
        this.getSelectInvestment(0);
        
        this.users = this.utilityService.selectedUser;
        this.user = this.utilityService.user;
        this.user.reset();
    }

    get formValue() {
        return this.user.controls;
    }

    onCreate(form: FormGroup) {
        if (form.valid) {
            this.users = {
                id: null,
                role_id: this.formValue.role_id.value,
                investment_id: this.formValue.investment_id.value,
                entity_id: this.formValue.entity_id.value,
                name: this.formValue.name.value,
                email: this.formValue.email.value,
                username: this.formValue.email.value,
                password: this.formValue.password.value,
                status: 1
            };

            return this.dataApiService.createUser(this.users).subscribe(
                data => {
                    if (!data)
                        console.log('The MYSQL API-REST is not enabled.');
                    else
                    {
                        if (data['id']) {
                            this.translate.get('all.create_message').subscribe((message:string) => {
                                this.messageService.add(message) ;
                            });
                            this.route.navigate(['/admin/user/list']);
                        }
                    }
                },
                error => {
                    this.messageService.addError(error.error.error.message);
                }
            );
        }
    }

    getSelectRol() {
        this.dataApiService.getRolesExpressSelect().subscribe(roles => { this.optionsSelectRoles = roles });
    }

    getSelectInvestment(id: number) {
        this.dataApiService.getInvestmentaExpressSelect(id, this.auth.getLanguage()).subscribe(investments => {
            this.optionsSelectInvestments = investments;
            this.getSelectRol();
        });
    }

    getSelectEntity(id: string) {
        this.dataApiService.getEntitiesExpressSelect(id).subscribe(entities => { this.optionsSelectEntities = entities });
    }

    getEntity(data: any) {
        if(data['role_id'] == 2) {
            this.entity = true;
            this.getSelectEntity(data['investment_id']);

            this.user.controls['entity_id'].setValue(data['entity_id']);
        }
        else {
            this.entity = false;
            this.getSelectEntity('0');

            this.user.controls['investment_id'].setValue('0');
            this.user.controls['entity_id'].setValue('0');
        }
    }

    viewEntity(event: any) {
        if(this.formValue.role_id.value == 2) {
            this.entity = true;
            this.getSelectEntity(event.value);
        }
        else {
            this.entity = false;
            this.getSelectEntity('0');

            this.user.controls['entity_id'].setValue('0');
        }
    }

    viewInvestment() {
        this.getSelectInvestment(0);

        var data = []; 
        data['role_id'] = 0;
        this.getEntity(data);
    }
}
