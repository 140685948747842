import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeAdminComponent implements OnInit {

    constructor(private translate: TranslateService, private auth: AuthService) { }

    ngOnInit() {
        this.translate.setDefaultLang(this.auth.getLanguage());
    }
}
