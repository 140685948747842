import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { environment } from '@environments/environment';

import { NavbarComponent } from './component/navbar/navbar.component';

import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild(NavbarComponent) nb: NavbarComponent;

    title = 'Aztec - Capitalizing real estate experience across border';
    windowScrolled: boolean;
    classActive: string;
    url: string;

    constructor(private router: Router, public auth: AuthService, private translate: TranslateService, private spinnerService: NgxSpinnerService, @Inject(DOCUMENT) private document: Document) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.url = event.url.match('/')['input'];
                this.classActive = event.url.match('/')['input'];

                this.translate.setDefaultLang('es');

                if(environment.activeLang)
                    this.auth.setLanguage(environment.activeLang);
                else
                    this.auth.setLanguage('es');

                this.nb.setColor('navbar_home');
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit() {
        this.spinner();
    }

    spinner(): void {
        this.spinnerService.show();
        setTimeout(() => { this.spinnerService.hide(); }, 1000);
    }

    @HostListener("window:scroll", ['$event']) onWindowScroll(e) {
        if(this.classActive.includes('/home')) {
            if (window.pageYOffset == 0 && document.documentElement.scrollTop == 0)
                this.nb.setColor('navbar_home');
            else
                this.nb.setColor('navbar_secundario');
        }
        else
            this.nb.setColor('navbar_secundario');

        if (window.pageYOffset == 0 && document.documentElement.scrollTop == 0)
            this.windowScrolled = false;
        else
            this.windowScrolled = true;
    }

	scrollToTop() {
		(function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - (currentScroll / 8));
			}
		})();
	}
}
