<section id="profile" class="spaceComponent">
    <mdb-card cascade="true" narrower="true">
        <div class="view view-cascade gradient-card-header colorBPP narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
            <div>
                <button mdbBtn color="white" outline="true" rounded="true" size="sm" type="button" class="px-2" mdbWavesEffect>
                    <mdb-icon fas icon="briefcase" class="mt-0"></mdb-icon>
                </button>
            </div>

            <a href="" class="white-text mx-3">{{'investmentDocument.title' | translate}}</a>

            <div>
                <button [routerLink]="['/admin/investment/list']" type="button" mdbBtn color="white" outline="true" rounded="true" size="sm" class="px-2" mdbWavesEffect>
                    <mdb-icon fas icon="angle-double-left" class="mt-0"></mdb-icon> {{'all.back' | translate}}
                </button>
                <button [routerLink]="['/admin/investmentDocument/create', id]" type="button" mdbBtn color="white" outline="true" rounded="true" size="sm" class="px-2" mdbWavesEffect>
                    <mdb-icon fas icon="plus-circle" class="mt-0"></mdb-icon> {{'investmentDocument.create' | translate}}
                </button>
            </div>
        </div>

        <div class="px-4">
            <form autocomplete="off" [formGroup]="search">
                <div class="table-responsive">
                    <table class="mb-0" mdbTable #tableEl="mdbTable" class="z-depth-1" hover="true">
                        <thead>
                            <tr>
                                <th class="th-lg" style="width:7%">
                                    <a (click)="sortBy('id')">ID<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th class="th-lg" style="width:28%">
                                    <a (click)="sortBy('name')">{{'investmentDocument.name' | translate}}<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th class="th-lg" style="width:20%">
                                    <a (click)="sortBy('investment_type_id')">{{'investmentDocument.investment_type_id' | translate}}<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th class="th-lg" style="width:15%">
                                    <a (click)="sortBy('order')">{{'investmentDocument.order' | translate}}<mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                                </th>
                                <th style="width:25%"></th>
                            </tr>
                        </thead>

                        <tbody *ngIf="tableData.length && optionsSelectInvestmentTypes">
                            <tr>
                                <td>
                                    <input type='search' id="id" formControlName="id" class="form-control form-control-sm" (keyup)="searchFilter($event)" (click)="onClick($event)">
                                </td>
                                <td>
                                    <input type='search' id="name" formControlName="name" class="form-control form-control-sm" (keyup)="searchFilter($event)" (click)="onClick($event)">
                                </td>
                                <td>
                                    <select id="investment_type_id" formControlName="investment_type_id" aria-controls="dtBasicExample" class="form-control form-control-sm" (change)="onClick($event)">
                                        <option value="">{{'investmentDocument.all' | translate}}</option>
                                        <option *ngFor="let obj of optionsSelectInvestmentTypes" value="{{obj.value}}">
                                            {{obj.label}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input type='search' id="order" formControlName="order" class="form-control form-control-sm" (keyup)="searchFilter($event)" (click)="onClick($event)">
                                </td>
                                <td>
                                    <div class="dataTables_length bs-select" id="dtBasicExample_length">
                                        <select id="page" formControlName="page" aria-controls="dtBasicExample" class="form-control form-control-sm" (change)="setPagination()">
                                            <option value="20">{{'all.Showing_20' | translate}}</option>
                                            <option value="50">{{'all.Showing_50' | translate}}</option>
                                            <option value="100">{{'all.Showing_100' | translate}}</option>
                                            <option value="9999999999">{{'all.Showing_all' | translate}}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let row of tableData; let i = index">
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{row.id}}</td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{row.name}}</td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{row.nameIT}}</td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{row.order}}</td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                                    <button [routerLink]="['/admin/investmentDocument/update', id, row.id]" mdbBtn outline="true" rounded="true" size="sm" type="button" class="colorCPP px-2" data-toggle="tooltip" placement="right" data-html="true" mdbTooltip="{{'all.update' | translate}}" mdbWavesEffect>
                                        <mdb-icon fas icon="edit" class="mt-0"></mdb-icon>
                                    </button>
                                    <button (click)="onDelete(row.id)" mdbBtn outline="true" rounded="true" size="sm" type="button" class="colorCPP px-2" data-toggle="tooltip" placement="right" data-html="true" mdbTooltip="{{'all.delete' | translate}}" mdbWavesEffect>
                                        <mdb-icon fas icon="trash-alt" class="mt-0"></mdb-icon>
                                    </button>
                                    <button (click)="openDoc(row.name_file)" mdbBtn outline="true" rounded="true" size="sm" type="button" class="colorCPP px-2" mdbWavesEffect>
                                        <mdb-icon fas icon="file-pdf" class="mt-0"></mdb-icon> {{'all.view' | translate}}
                                    </button>
                                    <button (click)="onDownload(row.name_file)" mdbBtn outline="true" rounded="true" size="sm" type="button" class="colorCPP px-2" mdbWavesEffect>
                                        <mdb-icon fas icon="file-download" class="mt-0"></mdb-icon> {{'all.download' | translate}}
                                    </button>
                                </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="!tableData.length">
                            <tr>
                                <td colspan="4">
                                    {{'all.no_results_found' | translate}}
                                    <button (click)='cleanFilter()' mdbBtn class="colorCPP" rounded="true" size="sm" mdbWavesEffect>{{'all.clean' | translate}}</button>
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td colspan="4">
                                    <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="tableData"></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </form>
        </div>
    </mdb-card>
</section>
